<app-navbar></app-navbar>

<body class="body_container">
<p>Amplify connects the ceo360 Research Database to powerful tools designed to efficiently clean and grow our internal data set.</p>
<div *ngIf="auth.user$ | async as user" style="display: none ">
    <div class="col-md text-center text-md-left">
        <h2>{{ user.name }}</h2>
        <p class="lead text-muted">{{ user.email }}</p>
        <p class="lead text-muted">{{ profileJson  }}</p>
        <p class="lead text-muted">{{ profileJson["https://schemas.quickstarts.com/roles"]}}</p>
    </div>
</div>
<br>
<br>
<h1>
    <a class="black-box">Getting started</a>
        <a class="production">PRODUCTION</a>
<!--    <a class="development">DEVELOPMENT</a>-->
</h1>

<table>
    <tr>
        <td class="blue-box">
            <h2>Search</h2>
            <p class="table-cell">
                Search for existing company and person records
            </p>
        </td>
        <td class="blue-box">
            <h2>Match</h2>
            <p class="table-cell">
                Find and correct potential database duplicates
            </p>
        </td>
        <td class="blue-box">
            <h2>Import</h2>
            <p class="table-cell">
                Upload data files and create mapping templates
            </p>
        </td>
        <td class="blue-box">
            <h2>Stage</h2>
            <p class="table-cell">
                Review imported results to create and clean database records
            </p>
        </td>
        <td class="blue-box">
            <h2>Research</h2>
            <p class="table-cell">
                Reconcile records that require additional investigation
            </p>
        </td>
    </tr>
</table>

<h1>
    <a class="black-box">FAQ</a>
</h1>

<div class="transparent-blue-box">
    <p class="bold">Can I import a single file of companies and executives?</p>
    <p>No. Records are updated in a two-step process. First create and import a file pertaining to primary company data only and resolve all groups. Then, create and import a file of executives to match to the database.</p>

    <p class="bold">Can more than one file be loaded at a time?</p>
    <p>No. Not at this time. Work completely through a file before you upload an additional one.</p>

    <p class="bold">Can I adjust the Stage settings after import?</p>
    <p>No, you need to finalize the Stage settings that you wish to use to match imported records to the ceo360 research database before you import the file.</p>

    <p class="bold">How long does it take to import a file into Amplify?</p>
    <p>The larger the file, the longer it will take. Be sure to wait until the import job has fully processed before you attempt to review the results. You can view the job status by clicking on "Check running jobs" located on the top righthand side of the screen.</p>
</div>
</body>

