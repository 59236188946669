import {Component, OnInit, ViewChild} from '@angular/core';
import {Group} from '../shared/Group';
import {ConfigService} from '../config/config.service';
import {DataType} from '../shared/DataType';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {Company} from '../shared/Company';
import {Person} from '../shared/Person';
import {Engagement} from '../shared/Engagement';
import {Dictionary} from '../shared/Dictionary';
import {Groups} from '../shared/Groups';
import {Fields} from '../shared/Fields';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {AuthService} from '@auth0/auth0-angular';
import {AppComponent} from '../app.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  constructor(private route: Router, private api: ConfigService, private snackBar: MatSnackBar, private appcomponent: AppComponent)
  {
    this.appcomponent.jobChecking();
    route.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }
  token: string;
  public showOverlay = false;
  checked = false;
  // tslint:disable-next-line:variable-name
  is_Exact_match = false;
  // tslint:disable-next-line:ban-types
  datatypes: DataType[];
  companies: Company[];
  people: Person[];
  engagements: Engagement[];
  dictionary: Dictionary;
  fields: Fields[];
  // tslint:disable-next-line:variable-name
  check_list: string[];
  ColumnName: string;
  Value: string;
  TableName: string;
  ColumnDataType: string;
  GroupId: string;
  // tslint:disable-next-line:variable-name
  Level: number;
  // tslint:disable-next-line:variable-name
  DictionaryType: string;
  Weight: string;
  MatchingType: string;
  IgnoreNullValues: string;
  GroupLevel: string;
  FuzzyAlgorithm: string;
  SearchDeep: string;
  SearchValue: string;
// tslint:disable-next-line:variable-name
  search_completed = false;
  // tslint:disable-next-line:variable-name
  is_CompanySearch = false;
  // tslint:disable-next-line:variable-name
  is_PersonSearch = false;
  // tslint:disable-next-line:variable-name
  is_EngagementSearch = false;
  // tslint:disable-next-line:variable-name
  is_simple_search = false;
  // tslint:disable-next-line:variable-name
  is_advanced_search = false;
  // tslint:disable-next-line:variable-name
  new_groups: Groups[] = [{
    Conditions: [
      {
        SearchValue: '',
        SearchField: {
          TableName: 'Table1',
          ColumnName: '',
          ColumnDataType: '',
        },
        Level: 1.0,
        Weight: 1.0,
        DictionaryType: 'Not Required',
        MatchingType: 0,
        IgnoreNullValues: true,
      }
    ],
    GroupId: 0,
    GroupLevel: 0,
  }];
  // tslint:disable-next-line:variable-name
  value_for_search: string;
  // tslint:disable-next-line
  // tslint:disable-next-line:variable-name
  is_show_searchSettings = false;
  selectedDataType;
  selectedField;
  // tslint:disable-next-line:variable-name
  path_for_search: string;
  // tslint:disable-next-line:variable-name
  is_Group_ready = false;
  // tslint:disable-next-line:variable-name
  is_dataType_ready = false;
  // tslint:disable-next-line:variable-name
  waiting_for_search = false;
  // tslint:disable-next-line:variable-name
  selected_group: number;
  // tslint:disable-next-line:variable-name
  all_boxes_checked = false;
  // tslint:disable-next-line:variable-name
  is_totalScore_clicked = false;
  href = 'https://ceo360.pro/#/Company/';
  dataSource;
  displayedColumns: string[];
  length: number;
  pageSize = 0;
  pageSizeOptions: number[] = [5, 10, 20];
  pageEvent: PageEvent;
  // tslint:disable-next-line:variable-name
  total_searches: number;
  private paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  exportToExcel(fileName: string, datatype: string): void{
    const element = document.getElementById('excel-table-' + datatype);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, fileName);
  }
  // tslint:disable-next-line:typedef
  snackbar_SucceededPost() {
    const sb = this.snackBar.open('Your settings have successfully been saved!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedPost() {
    const sb = this.snackBar.open('Your settings are NOT saved!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  setDataSourceAttributes() {
    if (this.paginator && this.dataSource){
      this.dataSource.paginator = this.paginator;
    }
  }
  // tslint:disable-next-line:typedef
  LinkTo(companyId: number, str: string) {
    // tslint:disable-next-line:variable-name
    let new_href = '';
    for (const i of this.companies) {
      if (i.companyId === companyId) {
        new_href = this.href + companyId;
        const win = window.open(new_href, '_blank');
        win.focus();
      }
    }
  }
  // tslint:disable-next-line:typedef
  Search() {
    console.log('search');
    this.waiting_for_search = false;
    this.waiting_for_search = true;
    console.log('true');
    this.showOverlay = true;
    this.WhichDataTypeIsSelected();
    this.SpecificSearch();
  }
  // tslint:disable-next-line:typedef
  SpecificSearch() {
    console.log('spec search');
    if (this.is_CompanySearch) {
      this.path_for_search = this.ContructSearchPath(this.selectedDataType, this.selectedField, this.value_for_search);
      // tslint:disable-next-line:variable-name
      this.api.getSimpleSearch(this.path_for_search).subscribe(res => {
        // @ts-ignore

        this.companies = res;
        this.companies.sort((a, b) =>
                a.companyName.localeCompare(b.companyName));
        this.total_searches = this.companies.length;
        console.log('res spec search', this.companies);
        this.dataSource = new MatTableDataSource<Company>(this.companies);
        this.dataSource.paginator = this.paginator;
        this.showOverlay = false;
      },
        err => {
          console.log(err);
          this.showOverlay = false;
        });
    }
    if (this.is_PersonSearch) {
      // tslint:disable-next-line:max-line-length
      if (this.selectedField === 'Industry BPA' || this.selectedField === 'Employees BPA' || this.selectedField === 'Revenue BPA' || this.selectedField === 'Ownership BPA'){
         this.selectedField = this.selectedField.split(' ')[0];
         console.log('bpa data ', this.selectedField);
      }
      this.path_for_search = this.ContructSearchPath(this.selectedDataType, this.selectedField, this.value_for_search);
      // tslint:disable-next-line:variable-name
      console.log('path', this.path_for_search);
      // tslint:disable-next-line:variable-name
      this.api.getSimpleSearch(this.path_for_search).subscribe(search_result => {
        console.log('search_result', search_result);
        // @ts-ignore
        this.people = search_result;
        this.people.sort((a, b) =>
                a.lastName.localeCompare(b.lastName));
        console.log('res spec search people', this.people);
        this.total_searches = this.people.length;
        this.dataSource = new MatTableDataSource<Person>(this.people);
        this.dataSource.paginator = this.paginator;
        this.showOverlay = false;
      },
        err => {
          console.log(err);
          this.showOverlay = false;
        });
    }
    if (this.is_EngagementSearch) {
      this.path_for_search = this.ContructSearchPath(this.selectedDataType, this.selectedField, this.value_for_search);
      // tslint:disable-next-line:variable-name
      this.api.getSimpleSearch(this.path_for_search).subscribe(search_result => {
        // @ts-ignore
        this.engagements = search_result;
        this.engagements.sort((a, b) =>
                a.engagementName.localeCompare(b.engagementName));
        this.total_searches = this.engagements.length;
        this.dataSource = new MatTableDataSource<Engagement>(this.engagements);
        this.dataSource.paginator = this.paginator;
        this.showOverlay = false;
      },
        err => {
          console.log(err);
          this.showOverlay = false;
        });
    }
    this.search_completed = true;
  }

  // tslint:disable-next-line:typedef
  WhichDataTypeIsSelected() {
    console.log('which datatype');
    this.dataSource = [];
    this.new_groups = [];
    this.search_completed = false;
    this.waiting_for_search = false;
    if (this.selectedDataType === 'Company'){
      this.is_CompanySearch = true;
      this.is_PersonSearch = false;
      this.is_EngagementSearch = false;
      this.displayedColumns = ['CompanyId', 'SourceName', 'CompanyName', 'Adress1', 'Adress2', 'City', 'State', 'Zip', 'Country', 'Phone', 'Website', 'Ticker', 'Verified Date', 'Active Status', 'AddressSource'];
    }
    else if (this.selectedDataType === 'Person'){
      this.is_PersonSearch = true;
      this.is_CompanySearch = false;
      this.is_CompanySearch = false;
      // tslint:disable-next-line:max-line-length
      this.displayedColumns = ['Person Id', 'SourceName', 'First Name', 'Middle Name', 'Last Name', 'Suffix', 'Title', 'Email', 'AltEmail', 'Direct Phone',
        'Mobile Phone', 'Primary Company', 'Board Company Associations', 'Ticker', 'Shipping City', 'Shipping State', 'Verified Date', 'Active Status'];
    }
    else if (this.selectedDataType === 'Engagement'){

      this.is_EngagementSearch = true;
      this.is_CompanySearch = false;
      this.is_PersonSearch = false;
      this.displayedColumns = ['SourceName', 'TotalScore', 'EngagementName', 'AmountPaid', 'EngagementCatDesc', 'EngagementDate', 'EngagementTypeDesc', 'GroupId', 'PersonId', 'LastUpdated'];
    }
  }

  // tslint:disable-next-line:typedef
  SimpleSearch() {
    console.log('simple s');
  }

  // tslint:disable-next-line:typedef
  Down(category: number) {
    console.log('Down');
    if (category === 1) {
      this.is_simple_search = !(this.is_simple_search);
      if (this.is_simple_search){
        const img = document.getElementById('simple-down-arrow');
        img.style.transform = 'rotate(180deg)';
      }
      else{
        const img = document.getElementById('simple-down-arrow');
        img.style.transform = 'rotate(0deg)';
      }
      this.is_advanced_search = false;
      this.search_completed = false;
      this.is_CompanySearch = false;
      this.is_PersonSearch = false;
      this.is_EngagementSearch = false;
      this.is_Exact_match = false;
      this.checked = false;
      this.Level = null;
      this.waiting_for_search = false;
      this.fields = [];
      this.selectedDataType = null;
      this.value_for_search = '';
      this.is_dataType_ready = false;
      this.new_groups = [];

    } else {
      this.is_advanced_search = !(this.is_advanced_search);
      if (this.is_advanced_search){
        const img = document.getElementById('advanced-down-arrow');
        img.style.transform = 'rotate(180deg)';
      }
      else{
        const img = document.getElementById('advanced-down-arrow');
        img.style.transform = 'rotate(0deg)';
      }
      this.is_simple_search = false;
      this.search_completed = false;
      this.is_CompanySearch = false;
      this.is_PersonSearch = false;
      this.is_EngagementSearch = false;
      this.is_Exact_match = false;
      this.checked = false;
      this.Level = null;
      this.waiting_for_search = false;
      this.fields = [];
      this.selectedDataType = null;
      this.value_for_search = '';
      this.is_dataType_ready = false;
      this.new_groups = [];
    }
  }

  // tslint:disable-next-line:typedef
  AddGroup() {
    console.log('add group');
    this.new_groups.push({
      Conditions: [
        {
          SearchValue: '',
          SearchField: {
            TableName: 'Table1',
            ColumnName: '',
            ColumnDataType: '',
          },
          Level: 1.0,
          Weight: 1.0,
          DictionaryType: 'Not Required',
          MatchingType: 0,
          IgnoreNullValues: true,
        }
      ],
      GroupId: this.new_groups.length + 1,
      GroupLevel: 0,
    });
    this.is_Group_ready = true;
  }

  // tslint:disable-next-line:typedef
  showSearchSettings() {
    console.log('showsearchsettings');
    this.is_show_searchSettings = !this.is_show_searchSettings;
    if (this.is_show_searchSettings){
      const img = document.getElementById('settings-down-arrow');
      img.style.transform = 'rotate(180deg)';
    }
    else{
      const img = document.getElementById('settings-down-arrow');
      img.style.transform = 'rotate(0deg)';
    }
  }

  // tslint:disable-next-line:typedef
  SaveSettings() {
    console.log('save settings');
    // this.api.postSimpleSearchSettings(this.Level);
    const postData = {
      name: 'DefaultSearchParameters', value: this.Level
    };
    console.log(postData);
    // tslint:disable-next-line:variable-name
    const backend_address = this.api.url_postSimpleSearchSettings();
    const url = backend_address + '/api/v1/search/set-search-template';

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('accept', '*/*');
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        console.log('status', xhr.status);
        console.log('response', xhr.responseText);
        if (xhr.status === 200){
          document.getElementById('succeeded_snack').click();
        }
        else{
          document.getElementById('failed_snack').click();
        }
      }
    };
    let data = '{"name":"DefaultSearchParameters","configurationJson":"0"}';
    data = data.replace('0', postData.value.toString());
    console.log('data:' + data);
    xhr.send(data);

    // ----

    const url1 = backend_address + '/api/v1/search/get-search-template?templateName=DefaultSearchParameters';

    const xhr1 = new XMLHttpRequest();
    xhr1.open('GET', url1);

    xhr1.setRequestHeader('accept', 'text/plain');

    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    // tslint:disable-next-line:only-arrow-functions typedef
    xhr1.onreadystatechange = function() {
      if (xhr1.readyState === 4) {
        console.log('status ' + xhr1.status);
        console.log('response' + xhr1.responseText);
      }
    };
    xhr1.send();
  }

  // tslint:disable-next-line:typedef
  ContructSearchPath(datatype: string, searchfield: string, searchvalue: string) {
   // searchvalue = searchvalue.replace(' ', '%20');
    return 'dataType=' + datatype + '&searchField=' + searchfield + '&searchValue=' + searchvalue;
  }

  // tslint:disable-next-line:typedef
  ValueConstruct(event: any, ColumnName: string) {
    console.log('Value Construct');
    const val = event.target.value;
    this.Value = val;
    for (const i of this.new_groups) {
      if (i.GroupId === this.selected_group) {
        // tslint:disable-next-line:prefer-for-of
        for (const j of i.Conditions) {
          if (j.SearchField.ColumnName === ColumnName) {
            j.SearchValue = this.Value;
          }
        }
      }
    }
  }

  // tslint:disable-next-line:typedef
  selected() {
    console.log('selected');
    this.search_completed = false;
    this.check_list = [];
    // tslint:disable-next-line:variable-name
    this.api.getFields_SimpleSearch(this.selectedDataType).subscribe(field_result => {
        // @ts-ignore
        this.fields = field_result;
        for (const field of this.fields){
          if (field.name === 'Industry' || field.name === 'Employees' || field.name === 'Revenue' || field.name === 'Ownership')
          {

            const modifiedName = field.name.concat(' BPA');
            field.name = modifiedName;
          }
        }
        console.log('fields selected ', this.fields);
      },
      err => {
        console.log(err);
      });
    console.log('fields ', this.fields);
    // tslint:disable-next-line:prefer-const
    const elements = document.getElementsByTagName('checkbox');
    // @ts-ignore
    for (const inp of elements) {
      if (inp.type === 'checkbox') {
        inp.checked = false;
      }
    }
  }

  // tslint:disable-next-line:typedef
  construct_ExactMatch(ColumnName: string) {
    console.log('construct_ExactMatch');
    if (ColumnName !== '') {
      this.checked = !this.checked;
      this.is_Exact_match = this.checked;
      let value;
      if (this.is_Exact_match) {
        value = 1;
      } else {
        value = 0;
      }
      for (const i of this.new_groups) {
        if (i.GroupId === this.selected_group) {
          // tslint:disable-next-line:prefer-for-of
          for (const j of i.Conditions) {
            if (j.SearchField.ColumnName === ColumnName) {
              // @ts-ignore
              // tslint:disable-next-line:radix
              j.Level = parseInt(value);
              j.MatchingType = 0;
            }
          }
        }
      }
    } else {
      this.Level = 1;
    }
    console.log(this.new_groups);
  }

  // tslint:disable-next-line:typedef
  construct_Fuzzy(event: any, ColumnName: string) {
    console.log('construct fuzzy');
    // tslint:disable-next-line:variable-name
    let number: number;
    const value = event.target.value;
    number = +value;
    if (number < 75){
      number = 75;
    }
    if (number > 100){
      number = 100;
    }
    number = number / 100;
    console.log(number, typeof number);
    if (ColumnName === ''){
      if (!this.is_Exact_match) {
        this.Level = number;
      }
      else {
        // @ts-ignore
        document.getElementById(exact_match_simple).checked = false;
        console.log('yas');
        this.checked = false;
      }
    }
    else{
      // tslint:disable-next-line:variable-name
      console.log(typeof number);
      if (!this.is_Exact_match) {
        for (const i of this.new_groups) {
          if (i.GroupId === this.selected_group) {
            // tslint:disable-next-line:prefer-for-of
            for (const j of i.Conditions) {
              if (j.SearchField.ColumnName === ColumnName) {
                // @ts-ignore
                // tslint:disable-next-line:radix
                j.Level = number;
                // @ts-ignore
                j.MatchingType = 1;
              }
            }
          }
        }
      } else {
        // @ts-ignore
        document.getElementById('checked-' + ColumnName).checked = false;
        this.checked = false;
        for (const i of this.new_groups) {
          if (i.GroupId === this.selected_group) {
            // tslint:disable-next-line:prefer-for-of
            for (const j of i.Conditions) {
              if (j.SearchField.ColumnName === ColumnName) {
                // @ts-ignore
                // tslint:disable-next-line:radix
                j.Level = number;
                // @ts-ignore
                j.MatchingType = 1;
              }
            }
          }
        }
      }
      console.log(this.new_groups);
    }
  }

  // tslint:disable-next-line:typedef
  select_advanced(event: any) {
    console.log('select_advanced');
    this.selectedDataType = event.target.value;
    console.log('this.selectedDataType ', this.selectedDataType);
    this.WhichDataTypeIsSelected();
    this.is_dataType_ready = true;
    this.selected();
  }

  // tslint:disable-next-line:typedef
  checked_values(event: any) {
    let already = event.target.value;
    if (already === 'Industry BPA' || already === 'Employees BPA' || already === 'Revenue BPA' || already === 'Ownership BPA'){
      const split = already.split(' ')[0];
      already = split;
      console.log('bpa data  checked_values', already);
    }
    console.log('already', already);
    //  assign local checklist to group
    for (const i of this.new_groups) {
      if (i.GroupId === this.selected_group) {
        let found = 0;
        let ct = 0;
        for (const k of i.Conditions){
          if (already === k.SearchField.ColumnName){
            found++;
            ct = i.Conditions.indexOf(k);
          }
        }
        if (found === 0){
          i.Conditions.push({
            SearchValue: '',
            SearchField: {
              TableName: 'Table1',
              ColumnName: already,
              ColumnDataType: this.fields[0].dataType,
            },
            Level: 1.0,
            Weight: 1.0,
            DictionaryType: 'Not Required',
            MatchingType: 0,
            IgnoreNullValues: true,
          });
        }
        else{
          i.Conditions.splice(i.Conditions.indexOf(i.Conditions[ct]), 1);
          ct = 0;
          found = 0;
        }
      }
    }
    // eliminate first (empty) element
    for (const i of this.new_groups) {
      for (const j of i.Conditions) {
        if (j.SearchField.ColumnName === '') {
          i.Conditions.splice(0, 1);
        }
      }
    }
    // reset fields
    const checklistx = (document.querySelectorAll('.checklist input'));
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < checklistx.length; i++) {
      // @ts-ignore
      checklistx[i].checked = false;
    }
    for (const i of this.new_groups) {
      if (i.GroupId === this.selected_group) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < checklistx.length; j++) {
          // tslint:disable-next-line:prefer-for-of
          for (let c = 0; c < i.Conditions.length; c++) {
            // check only the inputs that were previously checked
            // @ts-ignore
            if (i.Conditions[c].SearchField.ColumnName === checklistx[j].value) {
              // @ts-ignore
              checklistx[j].checked = !(checklistx[j].checked);
              // @ts-ignore
              if (checklistx[j].checked){
                i.Conditions[c].SearchField.ColumnDataType = this.fields[j].dataType;
                console.log('name: ', i.Conditions[c].SearchField.ColumnName, 'datatype: ', i.Conditions[c].SearchField.ColumnDataType);
              }
              else{
                console.log('should remove', i.Conditions.indexOf(i.Conditions[c]));
                console.log('c', c);
              }
            }
          }
        }
      }
    }
    console.log(this.new_groups);
  }

  // tslint:disable-next-line:typedef variable-name
  Advanced_Search() {
    console.log('advanced search');
    this.showOverlay = true;
    this.value_for_search = this.Speficic_AdvSearch();
    console.log('val for search', this.value_for_search);
    // tslint:disable-next-line:variable-name
    this.api.getAdvancedSearch(this.value_for_search).subscribe(search_result => {
      console.log(search_result);
      if (this.is_CompanySearch) {
        // @ts-ignore
        this.companies = search_result;
        this.total_searches = this.companies.length;
        this.dataSource = new MatTableDataSource<Company>(this.companies);
        this.dataSource.paginator = this.paginator;
        this.showOverlay = false;
        for (const i of this.companies) {
          if (!i.activeStatus){
            i.activeStatus = undefined;
          }
        }
      }
      if (this.is_PersonSearch) {
        // @ts-ignore
        this.people = search_result;
        this.dataSource = new MatTableDataSource<Person>(this.people);
        this.total_searches = this.people.length;
        this.dataSource.paginator = this.paginator;
        this.showOverlay = false;
        for (const i of this.people) {
          if (!i.activeStatus){
            i.activeStatus = undefined;
          }
        }
      }
      if (this.is_EngagementSearch) {
        // @ts-ignore
        this.engagements = search_result;
        this.total_searches = this.engagements.length;
      }
      this.search_completed = true;
    },
      err => {
        console.log(err);
        this.showOverlay = false;
      });
  }

  // tslint:disable-next-line:typedef
  nullVal(event: any, ColumnName: string) {
    const value = event.target.value;
    for (const i of this.new_groups) {
      if (i.GroupId === this.selected_group) {
        // tslint:disable-next-line:prefer-for-of
        for (const j of i.Conditions) {
          if (j.SearchField.ColumnName === ColumnName) {
            if (value === 'false'){
              j.IgnoreNullValues = false;
            }
           else{
              j.IgnoreNullValues = true;
            }
          }
        }
      }
    }
  }

  // tslint:disable-next-line:typedef
  construct_DictionaryType(ev: any, ColumnName: string) {
    const value = ev.target.value;
    for (const i of this.new_groups) {
      if (i.GroupId === this.selected_group) {
        // tslint:disable-next-line:prefer-for-of
        for (const j of i.Conditions) {
          if (j.SearchField.ColumnName === ColumnName) {
            j.DictionaryType = value;
          }
        }
      }
    }
  }

  // tslint:disable-next-line:typedef
  construct_GroupLevel(event: any) {
    // tslint:disable-next-line:variable-name
    let number: number;
    const value = event.target.value;
    number = +value;
    if (number < 0){
      number = 0;
    }
    if (number > 100){
      number = 100;
    }
    number = number / 100;
    console.log(number, typeof number);
    for (const i of this.new_groups) {
      if (i.GroupId === this.selected_group) {
        // tslint:disable-next-line:prefer-for-of
        for (const j of i.Conditions) {
          i.GroupLevel = number;
          // @ts-ignore
          // tslint:disable-next-line:radix
          this.GroupLevel = number;
        }
      }
    }
    console.log(this.new_groups);
  }

  // tslint:disable-next-line:typedef
  construct_WeightInGroup(event: any, ColumnName: string) {
    // tslint:disable-next-line:variable-name
    let number: number;
    const value = event.target.value;
    number = +value;
    if (number < 0){
      number = 0;
    }
    if (number > 100){
      number = 100;
    }
    number = number / 100;
    console.log(number, typeof number);
    for (const i of this.new_groups) {
      if (i.GroupId === this.selected_group) {
        // tslint:disable-next-line:prefer-for-of
        for (const j of i.Conditions) {
          if (j.SearchField.ColumnName === ColumnName) {
            // @ts-ignore
            // tslint:disable-next-line:radix
            j.Weight = number;
          }
        }
      }
    }
    console.log(this.new_groups);
  }

  // tslint:disable-next-line:typedef
  Speficic_AdvSearch() {
    // tslint:disable-next-line:variable-name
    let search_path = 'dataType=' + this.selectedDataType + '&parameterJson=';
    const parameterJson = {
      Groups: this.new_groups,
      FuzzyAlgorithm: 1,
      SearchDeep: 10
    };
    search_path += encodeURI(JSON.stringify(parameterJson));
    search_path = typeof search_path !== 'undefined' ? search_path : '';
    console.log('searc_path: ', search_path);
    return (search_path);
  }

  // tslint:disable-next-line:typedef
  Select_group(event: any) {
    // tslint:disable-next-line:variable-name
    let selected_group = event.target.value;
    selected_group = (typeof selected_group === 'undefined') ? 0 : selected_group;
    // tslint:disable-next-line:radix
    selected_group = (typeof selected_group === 'string') ? parseInt(selected_group) : selected_group;
    selected_group = (isNaN(selected_group)) ? 0 : selected_group;
    this.selected_group = selected_group; // assign value

    // reset fields
    const checklistx = (document.querySelectorAll('.checklist input'));
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < checklistx.length; i++) {
      // @ts-ignore
      checklistx[i].checked = false;
    }

    for (const i of this.new_groups) {
      if (i.GroupId === this.selected_group) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < checklistx.length; j++) {
          // tslint:disable-next-line:prefer-for-of
          for (let c = 0; c < i.Conditions.length; c++) {
            // check only the inputs that were previously checked
            // @ts-ignore
            if (i.Conditions[c].SearchField.ColumnName === checklistx[j].value) {
              // @ts-ignore
              checklistx[j].checked = true;
              i.Conditions[c].SearchField.ColumnDataType = this.fields[j].dataType;
            }
          }
        }
      }
    }

  }
  // tslint:disable-next-line:typedef
  show_totalscore(){
    this.is_totalScore_clicked = !this.is_totalScore_clicked;
    console.log(this.is_totalScore_clicked );
  }
  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }
  ngOnInit(): void {
    this.api.getAuthToken().subscribe(auth => {
      this.token = auth;
    });
    this.GroupLevel = '0';
    // tslint:disable-next-line:variable-name
    this.api.getSourceData_SimpleSearch().subscribe(res => {
        // @ts-ignore
        this.datatypes = res;
      },
      err => {
        console.log(err);
      });

    this.api.getDictionaries_AdvancedSearch().subscribe(res => {
      // @ts-ignore
      this.dictionary = res;
    });
  }
}
