/* tslint:disable:no-inferrable-types */
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CompanySelection, CompanyToReview} from '../shared/CompanyToReview';
import {PersonToReview} from '../shared/PersonToReview';
import {ReviewedCompany} from '../shared/ReviewedCompany';
import {ReviewedPerson} from '../shared/ReviewedPerson';
import {ConfigService} from '../config/config.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Inject} from '@angular/core';
import {MergedConnectionService} from '../service/mergedConnection.service';
import {delay} from 'rxjs/operators';
import {DOCUMENT, formatDate} from '@angular/common';
import {UsersToView} from '../shared/UsersToView';
import {AuthService} from '@auth0/auth0-angular';
import {Country} from '../shared/Country';
import {State} from '../shared/State';
import _ from 'lodash';
// tslint:disable-next-line:class-name
interface Value {
  value: string;
}
interface StateCountry {
  stateCode: string;
  countryCode: string;
}
// tslint:disable-next-line:class-name
interface dateToVerify {
  value: string;
  importedData: boolean;
}
@Component({
  selector: 'app-merge-options',
  templateUrl: './merge-options.component.html',
  styleUrls: ['./merge-options.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MergeOptionsComponent implements OnInit {
// tslint:disable-next-line:variable-name
  public selectedDataType: string;
  token: string;
  teamMemberId: number;
  user: string;
  userEmail: string;
  profileJson: string = null;
  users: UsersToView[] = [];
  countries: Country[];
  states: State[];
  public date: Date;
  companyControl: FormGroup;
  companySelection = new CompanySelection();
  constructor(private api: ConfigService, public auth: AuthService, private snackBar: MatSnackBar, public dialog: MatDialog,
              // tslint:disable-next-line:variable-name
              private _mergeservice: MergedConnectionService,  @Inject(DOCUMENT) private _document: Document) {

    this.companyControl = new FormGroup({
      companyName: new FormControl(''),
      address1: new FormControl(''),
      address2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      country: new FormControl(''),
      phone: new FormControl(''),
      website: new FormControl(''),
      addressSource: new FormControl(''),
      ticker: new FormControl(''),
      cikId: new FormControl(''),
      sicCode: new FormControl(''),
      exchange: new FormControl(''),
      numberOfEmployees: new FormControl(''),
      businessIndustry: new FormControl(''),
      ownershipTypeDesc: new FormControl(''),
      ownershipSubType: new FormControl(''),
      annualRevenue: new FormControl(''),
      howVerified: new FormControl(''),
      verifiedDate: new FormControl(''),
      ipoDate: new FormControl(''),
      activeStatus: new FormControl('true'),
    });
  }
  // tslint:disable-next-line:variable-name
  selectPerson_firstName: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_middleName: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_lastName: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_suffix: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_title: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_email: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_altEmail: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_directPhone: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_mobilePhone: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_primaryAddress1: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_primaryAddress2: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_primaryCity: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_primaryState: StateCountry[] = [
    {stateCode: '', countryCode: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_primaryZip: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_alternateAddressPreferred: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_alternateAddressSource: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_alternateAddressType: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_primaryCountry: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_Ticker: Value[] = [
    {value: ''},
  ];
  // // tslint:disable-next-line:variable-name
  // selectPerson_CEXSubscriberCode: Value[] = [
  //   {value: ''},
  // ];
  // tslint:disable-next-line:variable-name
  selectPerson_cexSubStatus: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_cbmSubStatus: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_verifiedDate: dateToVerify[] = [
    {value: '', importedData: false},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_activeStatus: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_companyId: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_WordpressId: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_ceoDigitalAndPrint: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_ceoDigitalOnly: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_cbmDigitalAndPrint: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_cbmDigitalOnly: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_revenue: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_ownership: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_ownershipSubType: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_employees: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_industry: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_otherIndustry: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_bpaJobTitle: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_bpaQualSource: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_bpaQualDate: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_cen: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_sen: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_ceo100: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_cfo100: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_nll: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_cebi: Value[] = [
    {value: ''},
  ];
  // tslint:disable-next-line:variable-name
  selectPerson_memberClicksId: Value[] = [
    {value: ''},
  ];

  firstNameControl = new FormControl(this.selectPerson_firstName[0].value);
  middleNameControl = new FormControl(this.selectPerson_middleName[0].value);
  lastNameControl = new FormControl(this.selectPerson_lastName[0].value);
  suffixControl = new FormControl(this.selectPerson_suffix[0].value);
  titleControl = new FormControl(this.selectPerson_title[0].value);
  emailControl = new FormControl(this.selectPerson_email[0].value);
  altEmailControl = new FormControl(this.selectPerson_altEmail[0].value);
  directPhoneControl = new FormControl(this.selectPerson_directPhone[0].value);
  mobilePhoneControl = new FormControl(this.selectPerson_mobilePhone[0].value);
  primaryAddress1Control = new FormControl(this.selectPerson_primaryAddress1[0].value);
  primaryAddress2Control = new FormControl(this.selectPerson_primaryAddress2[0].value);
  primaryCityControl = new FormControl(this.selectPerson_primaryCity[0].value);
  primaryStateControl = new FormControl(this.selectPerson_primaryState[0].stateCode);
  primaryZipControl = new FormControl(this.selectPerson_primaryZip[0].value);
  alternateAddressPreferredControl = new FormControl(this.selectPerson_alternateAddressPreferred[0]);
  alternateAddressSourceControl = new FormControl(this.selectPerson_alternateAddressSource[0]);
  alternateAddressTypeControl = new FormControl(this.selectPerson_alternateAddressType[0]);
  // CEXSubscriberCodeControl = new FormControl(this.selectPerson_CEXSubscriberCode[0]);
  cexSubStatusControl = new FormControl(this.selectPerson_cexSubStatus[0]);
  cbmSubStatusControl = new FormControl(this.selectPerson_cbmSubStatus[0]);
  ceoDigitalAndPrintControl = new FormControl(this.selectPerson_ceoDigitalAndPrint[0]);
  ceoDigitalOnlyControl = new FormControl(this.selectPerson_ceoDigitalOnly[0]);
  cbmDigitalAndPrintControl = new FormControl(this.selectPerson_cbmDigitalAndPrint[0]);
  cbmDigitalOnlyControl = new FormControl(this.selectPerson_cbmDigitalOnly[0]);
  cenControl = new FormControl(this.selectPerson_cen[0]);
  senControl = new FormControl(this.selectPerson_sen[0]);
  ceo100Control = new FormControl(this.selectPerson_ceo100[0]);
  cfo100Control = new FormControl(this.selectPerson_cfo100[0]);
  nllControl = new FormControl(this.selectPerson_nll[0]);
  cebiControl = new FormControl(this.selectPerson_cebi[0]);
  revenueControl = new FormControl(this.selectPerson_revenue[0]);
  ownershipControl = new FormControl(this.selectPerson_ownership[0]);
  ownershipSubTypeControl = new FormControl(this.selectPerson_ownershipSubType[0]);
  employeesControl = new FormControl(this.selectPerson_employees[0]);
  industryControl = new FormControl(this.selectPerson_industry[0]);
  otherIndustryControl = new FormControl(this.selectPerson_otherIndustry[0]);
  primaryCountryControl = new FormControl(this.selectPerson_primaryCountry[0].value);
  personTickerControl = new FormControl(this.selectPerson_Ticker[0].value);
  personVerifiedDateControl = new FormControl(this.selectPerson_verifiedDate[0].value);
  personActiveStatusControl = new FormControl('true');
  personCompanyIdControl = new FormControl(this.selectPerson_companyId[0].value);
  personWordpressIdControl = new FormControl(this.selectPerson_WordpressId[0].value);
  personMemberClicksIdControl = new FormControl(this.selectPerson_memberClicksId[0].value);
  personBPAControl = new FormControl(this.selectPerson_bpaQualDate[0].value);
  personBPAJobTitleControl = new FormControl(this.selectPerson_bpaJobTitle[0].value);
  personBPAQualSourceControl = new FormControl(this.selectPerson_bpaQualSource[0].value);
  panelColor = new FormControl('white');
  selectedColor = '';
  companiesToReview: CompanyToReview[] = [];
  companiesToMerge: CompanyToReview[] = [{
    id: 0,
    importId: '',
    fileName: '',
    fileRowId: 0,
    companyId: 0,
    companyName: '',
    address1: '',
    address2: '',
    city: '',
    state: null,
    zip: '',
    country: '',
    phone: '',
    website: '',
    addressSource: '',
    companyType: '',
    lastUpdated: '',
    ticker: '',
    exchange: '',
    sicCode: 0,
    naiscCode: 0,
    numberOfEmployees: 0,
    annualRevenue: 0,
    ownershipTypeDesc: '',
    ownershipSubType: '',
    businessIndustry: '',
// tslint:disable-next-line:variable-name
    h_D_U_N_S_Number: '',
// tslint:disable-next-line:variable-name
    h_Tradestyle: '',
// tslint:disable-next-line:variable-name
    h_EntityType: '',
// tslint:disable-next-line:variable-name
    h_IsHeadquarters: true,
// tslint:disable-next-line:variable-name
    h_BusinessDescription: '',
// tslint:disable-next-line:variable-name
    h_ParentCompany: '',
// tslint:disable-next-line:variable-name
    h_ParentCountry_Region: '',
// tslint:disable-next-line:variable-name
    h_UltimateParentCompany: '',
// tslint:disable-next-line:variable-name
    h_UltimateParentCountryRegion: '',
// tslint:disable-next-line:variable-name
    h_ImportDate: '',
    actionType: 'noAction',
    winPurePrimK: -1,
    groupId: 0,
    activeStatus: false,
    verifiedDate: '',
    howVerified: '',
    'group ID': 0,
    cikId: null,
    ipoDate: ''
  }];
  finalCompaniesToMerge: CompanyToReview[] = [{
    id: 0,
    importId: '',
    fileName: '',
    fileRowId: 0,
    companyId: 0,
    companyName: '',
    address1: '',
    address2: '',
    city: '',
    state: null,
    zip: '',
    country: '',
    phone: '',
    website: '',
    addressSource: '',
    companyType: '',
    lastUpdated: '',
    ticker: '',
    exchange: '',
    sicCode: 0,
    naiscCode: 0,
    numberOfEmployees: 0,
    annualRevenue: 0,
    ownershipTypeDesc: '',
    ownershipSubType: '',
    businessIndustry: '',
// tslint:disable-next-line:variable-name
    h_D_U_N_S_Number: '',
// tslint:disable-next-line:variable-name
    h_Tradestyle: '',
// tslint:disable-next-line:variable-name
    h_EntityType: '',
// tslint:disable-next-line:variable-name
    h_IsHeadquarters: true,
// tslint:disable-next-line:variable-name
    h_BusinessDescription: '',
// tslint:disable-next-line:variable-name
    h_ParentCompany: '',
// tslint:disable-next-line:variable-name
    h_ParentCountry_Region: '',
// tslint:disable-next-line:variable-name
    h_UltimateParentCompany: '',
// tslint:disable-next-line:variable-name
    h_UltimateParentCountryRegion: '',
// tslint:disable-next-line:variable-name
    h_ImportDate: '',
    actionType: 'noAction',
    winPurePrimK: -1,
    groupId: 0,
    activeStatus: false,
    verifiedDate: '',
    howVerified: '',
    'group ID': 0,
    cikId: null,
    ipoDate: '',
  }];
  mergedCompanies: ReviewedCompany[] = [{
    id: 0,
    importId: '',
    fileName: '',
    fileRowId: 0,
    companyId: 0,
    companyName: '',
    address1: '',
    address2: '',
    city: '',
    state: null,
    zip: '',
    country: '',
    phone: '',
    website: '',
    addressSource: '',
    companyType: '',
    lastUpdated: '',
    ticker: '',
    exchange: '',
    sicCode: 0,
    naiscCode: 0,
    numberOfEmployees: 0,
    annualRevenue: 0,
    ownershipTypeDesc: '',
    ownershipSubType: '',
    businessIndustry: '',
// tslint:disable-next-line:variable-name
    h_D_U_N_S_Number: '',
// tslint:disable-next-line:variable-name
    h_Tradestyle: '',
// tslint:disable-next-line:variable-name
    h_EntityType: '',
// tslint:disable-next-line:variable-name
    h_IsHeadquarters: true,
// tslint:disable-next-line:variable-name
    h_BusinessDescription: '',
// tslint:disable-next-line:variable-name
    h_ParentCompany: '',
// tslint:disable-next-line:variable-name
    h_ParentCountry_Region: '',
// tslint:disable-next-line:variable-name
    h_UltimateParentCompany: '',
// tslint:disable-next-line:variable-name
    h_UltimateParentCountryRegion: '',
// tslint:disable-next-line:variable-name
    h_ImportDate: '',
    actionType: 'noAction',
    winPurePrimK: -1,
    groupId: 0,
    activeStatus: false,
    verifiedDate: '',
    howVerified: '',
    'group ID': 0,
    cikId: null,
    ipoDate: ''
  }];
  peopleToReview: PersonToReview[];
  peopleToMerge: PersonToReview[] = [{
    'group ID': 0,
    'source name': '',
    'total score': 0,
    'group 1 score': 0,
    'group 1 FirstName score': 0,
    'group 1 LastName score': 0,
    personId: 0,
    prefix: '',
    firstName: '',
    middleName: '',
    lastName: '',
    companyId: 0,
    title: '',
    primaryAddress1: '',
    primaryAddress2: '',
    primaryCity: '',
    primaryState: '',
    primaryZip: '',
    primaryCountry: '',
    alternateAddressSource: '',
    alternateAddressPreferred: 0,
    wordpressId: null,
    cexSubscriberCode: '',
    cexSubStatus: '',
    cbmSubStatus: '',
    ceoDigitalAndPrint: false,
    cbmDigitalAndPrint: false,
    cbmDigitalOnly: false,
    ceoDigitalOnly: false,
    bpaQualDate: '',
    ownership: '',
    ownershipSubType: '',
    revenue: '',
    industry: '',
    otherIndustry: '',
    employees: '',
    alternateAddressType: '',
    lastUpdated: '',
    email: '',
    actionType: 'None',
    winPurePrimK: 0,
    ticker: '',
    primaryCompany: '',
    suffix: '',
    directPhone: '',
    altEmail: '',
    activeStatus: false,
    mobilePhone: '',
    boardCompanyName: '',
    verifiedDate: '',
    importId: '',
    fileName: '',
    id: 0,
    groupId: 0,
    fileRowId: 0,
    memberClicksId: 0,
    qualificationSource: '',
    bpaJobTitle: '',
    memberStatus: '',
    cen: false,
    sen: false,
    ceO100: false,
    cfO100: false,
    nextLevelLeaders: false,
    cebi: false,
  }];
  finalPeopleToMerge: PersonToReview[] = [{
    'group ID': 0,
    'source name': '',
    'total score': 0,
    'group 1 score': 0,
    'group 1 FirstName score': 0,
    'group 1 LastName score': 0,
    personId: 0,
    prefix: '',
    firstName: '',
    middleName: '',
    lastName: '',
    companyId: 0,
    title: '',
    primaryAddress1: '',
    primaryAddress2: '',
    primaryCity: '',
    primaryState: '',
    primaryZip: '',
    primaryCountry: '',
    alternateAddressSource: '',
    alternateAddressPreferred: 0,
    wordpressId: null,
    cexSubscriberCode: '',
    cexSubStatus: '',
    cbmSubStatus: '',
    ceoDigitalOnly: false,
    cbmDigitalOnly: false,
    cbmDigitalAndPrint: false,
    ceoDigitalAndPrint: false,
    bpaQualDate: '',
    ownership: '',
    ownershipSubType: '',
    revenue: '',
    industry: '',
    otherIndustry: '',
    employees: '',
    alternateAddressType: '',
    lastUpdated: '',
    email: '',
    actionType: 'None',
    winPurePrimK: -1,
    ticker: '',
    primaryCompany: '',
    suffix: '',
    directPhone: '',
    altEmail: '',
    activeStatus: false,
    mobilePhone: '',
    boardCompanyName: '',
    verifiedDate: '',
    fileName: '',
    importId: '',
    id: 0,
    groupId: 0,
    fileRowId: 0,
    memberClicksId: 0,
    qualificationSource: '',
    bpaJobTitle: '',
    memberStatus: '',
    cen: false,
    sen: false,
    ceO100: false,
    cfO100: false,
    nextLevelLeaders: false,
    cebi: false,
  }];
  mergedPeople: ReviewedPerson[] = [{
    'source name': '',
    id: 0,
    importId: '',
    fileName: '',
    fileRowId: 0,
    personId: 0,
    prefix: '',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    companyId: 0,
    title: '',
    primaryAddress1: '',
    primaryAddress2: '',
    primaryCity: '',
    primaryState: '',
    primaryZip: '',
    primaryCountry: '',
    alternateAddressSource: '',
    alternateAddressPreferred: 0,
    alternateAddressType: '',
    wordpressId: null,
    cexSubscriberCode: '',
    cexSubStatus: '',
    cbmSubStatus: '',
    ceoDigitalAndPrint: false,
    cbmDigitalAndPrint: false,
    cbmDigitalOnly: false,
    ceoDigitalOnly: false,
    bpaQualDate: '',
    ownership: '',
    ownershipSubType: '',
    revenue: '',
    industry: '',
    otherIndustry: '',
    employees: '',
    mobilePhone: '',
    lastUpdated: '',
    email: '',
    actionType: 'Edit',
    winPurePrimK: 0,
    groupId: 0,
    ticker: '',
    primaryCompany: '',
    directPhone: '',
    altEmail: '',
    activeStatus: false,
    boardCompanyName: '',
    verifiedDate: '',
    memberClicksId: 0,
    qualificationSource: '',
    bpaJobTitle: '',
    memberStatus: '',
    cen: false,
    sen: false,
    ceO100: false,
    cfO100: false,
    nextLevelLeaders: false,
    cebi: false,
  }];
  isPublicDataCompanyMatches = false;
  urlType = 0; // 1 = review; 2 = quarantine; 3 =research
  isPersonApiData: boolean = false; // show another labels
  // tslint:disable-next-line:variable-name
  initial_totalScore: string;
  // tslint:disable-next-line:variable-name
  intial_companyId;
  // tslint:disable-next-line:variable-name
  companiesToMerge_length = 0;
  // tslint:disable-next-line:variable-name
  peopleToMerge_length = 0;
  // tslint:disable-next-line:variable-name
  selected_group = 0;
  // tslint:disable-next-line:variable-name
  parent_page = 'nothing';
  // tslint:disable-next-line:typedef
  snackbar_SucceededPost() {
    document.getElementById('merge_failed_snack').click();
    const sb = this.snackBar.open('Your modifications have been successfully proceeded!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  modificationProceeded(message: string): void{
    this.snackBar.open(message, '', {
      duration: 2000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedPost() {
    const sb = this.snackBar.open('Your modifications were NOT proceeded!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_Error(errorMessage: string) {
    const sb = this.snackBar.open(errorMessage, '', {
      duration: 1500,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  constructCompaniesToMerge() {
    for (const i of this.companiesToReview){
      if (i.actionType === 'Merge' || i.actionType === 'AddToMainDatabase') {
        this.companiesToMerge.push(i);
      }
    }
    this.companiesToMerge.splice(0, 1);
    this.companiesToMerge_length = this.companiesToMerge.length;
    this.constructSelectOptions();
  }
  // tslint:disable-next-line:typedef
  constructPeopleToMerge() {
    for (const i of this.peopleToReview){
      if (i.actionType === 'Merge' || i.actionType === 'AddToMainDatabase') {
        this.peopleToMerge.push(i);
      }
    }
    this.peopleToMerge.splice(0, 1);
    this.peopleToMerge_length = this.peopleToMerge.length;
    this.constructSelectOptions();
  }
  constructSelectOptions(): void{
    this.resetSelectOptionsFields();
    this.populateSelectOptionsFields();
    this.eliminateDuplicates();
  }
  handleVerifiedDateCompany(company: CompanyToReview): void{
    if (this.isPublicDataCompanyMatches === false){
        if (company.verifiedDate && company['source name'] === 'ImportedCompany'){
          this.companySelection.verifiedDate.push({value: company.verifiedDate, importedData: true});
        }
        else{
          this.companySelection.verifiedDate.push({value: company.verifiedDate, importedData: false});
        }
    }
    else{
        if (company.verifiedDate && company['source name'] === 'Company'){
          this.companySelection.verifiedDate.push({value: company.verifiedDate, importedData: true});
        }
        else{
          this.companySelection.verifiedDate.push({value: company.verifiedDate, importedData: false});
        }
    }
    if (company.lastUpdated) {
      this.companySelection.verifiedDate.push({value: company.lastUpdated, importedData: false});
    }

  }
  handleVerifiedDatePerson(person: any): void{
    if (person.verifiedDate){
      if (person['source name'] === 'ImportedPerson'){
        this.selectPerson_verifiedDate.push({value: person.verifiedDate, importedData: true});
      }
      else{
        this.selectPerson_verifiedDate.push({value: person.verifiedDate, importedData: false});
      }
    }
    if (person.lastUpdated) {
      this.selectPerson_verifiedDate.push({value: person.lastUpdated, importedData: false});
    }
  }
  // handleCompanyAddress(company: CompanyToReview): void{
  //   console.log('company', company);
  //   console.log('here',this.companySelection.addressCompany);
  //   let addr = new AddressToReview();
  //   addr.address1 =company.address1;
  //   addr.address2 =company.address2;
  //   if(this.companySelection.addressCompany === null || this.companySelection.addressCompany === undefined){
  //     this.companySelection.addressCompany = [];
  //   }
  //   if(!this.companySelection.addressCompany.some(e => e.address1.toLowerCase() == company.address1.toLowerCase())){
  //     this.companySelection.addressCompany.push(addr)
  //   }
  // }
  checkList(property: string, value: any): void{
    if (!this.companySelection[property].includes(value)){
      this.companySelection[property].push(value);
    }
  }
  checkStates(state: any, country: any): void{
    if (!this.companySelection.state.some(s => s.stateCode === state)){
      this.companySelection.state.push({stateCode: state, countryCode: country});
    }
  }
  populateSelectOptionsCompany(): void{
    for (const i of this.companiesToMerge){
      i.companyName ? this.checkList('companyName' , i.companyName) : this.checkList('companyName' , '');
      i.address1 ? this.checkList('address1' , i.address1) : this.checkList('address1' , '');
      i.address2 ? this.checkList('address2' , i.address2) : this.checkList('address2' , '');
      i.city ? this.checkList('city' , i.city) : this.checkList('city' , '');
      // tslint:disable-next-line:max-line-length
      i.state ? this.checkStates(i.state , i.country) : this.checkStates('' , '');
      i.zip ? this.checkList('zip' , i.zip) : this.checkList('zip' , '');
      i.country ? this.checkList('country' , i.country) : this.checkList('country' , '');
      i.phone ? this.checkList('phone' , i.phone) : this.checkList('phone' , '');
      i.website ? this.checkList('website' , i.website) : this.checkList('website' , '');
      i.addressSource ? this.checkList('addressSource' , i.addressSource) : this.checkList('addressSource' , '');
      i.ticker ? this.checkList('ticker' , i.ticker) : this.checkList('ticker' , '');
      i.cikId ? this.checkList('cikId' , i.cikId.toString()) : this.checkList('cikId' , '');
      i.sicCode ? this.checkList('sicCode' , i.sicCode.toString()) : this.checkList('sicCode' , '');
      i.exchange ? this.checkList('exchange' , i.exchange.toUpperCase()) : this.checkList('exchange' , '');
      i.annualRevenue >= 0 ?  this.checkList('annualRevenue' , i.annualRevenue.toString()) : this.checkList('annualRevenue' , '');
      i.numberOfEmployees >= 0 ? this.checkList('numberOfEmployees' , i.numberOfEmployees.toString()) : this.checkList('numberOfEmployees' , '');
      i.ownershipTypeDesc ? this.checkList('ownershipTypeDesc' , i.ownershipTypeDesc) : this.checkList('ownershipTypeDesc' , '');
      i.ownershipSubType ? this.checkList('ownershipSubType' , i.ownershipSubType) : this.checkList('ownershipSubType' , '');
      i.businessIndustry ? this.checkList('businessIndustry' , i.businessIndustry) : this.checkList('businessIndustry' , '');
      this.handleVerifiedDateCompany(i);
      i.howVerified ? this.checkList('howVerified' , i.howVerified) : this.checkList('howVerified' , '');
      i.ipoDate ? this.checkList('ipoDate' , i.ipoDate) : this.checkList('ipoDate' , '');
    }
    console.log('company', this.companySelection);
  }
  populateSelectOptionsFields(): void{
    const date = formatDate( new Date(), 'MM/dd/yyyy hh:mm:ss', 'en-US', 'GMT -05:00');
    if (this.selectedDataType === 'Company'){
      // this.selectCompany_VerifiedDate.push({value: date.toString(), importedData: false});
      // this.selectCompany_VerifiedDate.push({value: '', importedData: false});
      this.populateSelectOptionsCompany();
    }else if (this.selectedDataType === 'Person'){
      this.selectPerson_verifiedDate.push({value: date.toString(), importedData: false});
      this.selectPerson_verifiedDate.push({value: '', importedData: false});
      for (const i of this.peopleToMerge){
        i.firstName ? this.selectPerson_firstName.push({value: i.firstName}) : this.selectPerson_firstName.push({value: ''});
        i.middleName ? this.selectPerson_middleName.push({value: i.middleName}) : this.selectPerson_middleName.push({value: ''});
        i.lastName ? this.selectPerson_lastName.push({value: i.lastName}) : this.selectPerson_lastName.push({value: ''});
        i.suffix ? this.selectPerson_suffix.push({value: i.suffix}) : this.selectPerson_suffix.push({value: ''});
        i.title ? this.selectPerson_title.push({value: i.title}) : this.selectPerson_title.push({value: ''});
        i.email ? this.selectPerson_email.push({value: i.email.toLowerCase()}) : this.selectPerson_email.push({value: ''});
        i.altEmail ? this.selectPerson_altEmail.push({value: i.altEmail.toLowerCase()}) : this.selectPerson_altEmail.push({value: ''});
        i.directPhone ? this.selectPerson_directPhone.push({value: i.directPhone}) : this.selectPerson_directPhone.push({value: ''});
        i.mobilePhone ? this.selectPerson_mobilePhone.push({value: i.mobilePhone}) : this.selectPerson_mobilePhone.push({value: ''});
        // tslint:disable-next-line:max-line-length
        i.primaryAddress1 ? this.selectPerson_primaryAddress1.push({value: i.primaryAddress1}) : this.selectPerson_primaryAddress1.push({value: ''});
        // tslint:disable-next-line:max-line-length
        i.primaryAddress2 ? this.selectPerson_primaryAddress2.push({value: i.primaryAddress2}) : this.selectPerson_primaryAddress2.push({value: ''});
        i.primaryCity ? this.selectPerson_primaryCity.push({value: i.primaryCity}) : this.selectPerson_primaryCity.push({value: ''});
        i.primaryCountry ? this.selectPerson_primaryCountry.push({value: i.primaryCountry}) : this.selectPerson_primaryCountry.push({value: ''});
        // tslint:disable-next-line:max-line-length
        i.primaryState ? this.selectPerson_primaryState.push({stateCode: i.primaryState, countryCode: i.primaryCountry}) : this.selectPerson_primaryState.push({stateCode: '', countryCode: ''});
        i.primaryZip ? this.selectPerson_primaryZip.push({value: i.primaryZip}) : this.selectPerson_primaryZip.push({value: ''});
        // tslint:disable-next-line:max-line-length
        (i.alternateAddressPreferred === 0 || i.alternateAddressPreferred === 1) ? this.selectPerson_alternateAddressPreferred.push({value: i.alternateAddressPreferred.toString()}) : this.selectPerson_alternateAddressPreferred.push({value: ''});
        // tslint:disable-next-line:max-line-length
        i.alternateAddressSource ? this.selectPerson_alternateAddressSource.push({value: i.alternateAddressSource}) : this.selectPerson_alternateAddressSource.push({value: ''});
        // tslint:disable-next-line:max-line-length
        i.alternateAddressType ? this.selectPerson_alternateAddressType.push({value: i.alternateAddressType}) : this.selectPerson_alternateAddressType.push({value: ''});
        i.ticker ? this.selectPerson_Ticker.push({value: i.ticker}) : this.selectPerson_Ticker.push({value: ''});
        i.bpaQualDate ? this.selectPerson_bpaQualDate.push({value: i.bpaQualDate}) : this.selectPerson_bpaQualDate.push({value: ''});
        this.handleVerifiedDatePerson(i);
        (i.activeStatus === true || i.activeStatus === false) ? this.selectPerson_activeStatus.push({value: i.activeStatus.toString()}) :
            this.selectPerson_activeStatus.push({value: ''});
        i.companyId ? this.selectPerson_companyId.push({value: i.companyId.toString()}) : this.selectPerson_companyId.push({value: ''});
        i.wordpressId ? this.selectPerson_WordpressId.push({value: i.wordpressId.toString()}) : this.selectPerson_WordpressId.push({value: ''});
        i.memberClicksId ?
            this.selectPerson_memberClicksId.push({value: i.memberClicksId.toString()}) :
            this.selectPerson_memberClicksId.push({value: ''});
        i.cexSubStatus ? this.selectPerson_cexSubStatus.push({value: i.cexSubStatus}) : this.selectPerson_cexSubStatus.push({value: ''});
        i.cbmSubStatus ? this.selectPerson_cbmSubStatus.push({value: i.cbmSubStatus}) : this.selectPerson_cbmSubStatus.push({value: ''});
        // tslint:disable-next-line:max-line-length
        (i.ceoDigitalAndPrint === true || i.ceoDigitalAndPrint === false) ? this.selectPerson_ceoDigitalAndPrint.push({value: i.ceoDigitalAndPrint.toString()}) : this.selectPerson_ceoDigitalAndPrint.push({value: ''});
        // tslint:disable-next-line:max-line-length
        (i.ceoDigitalOnly === true || i.ceoDigitalOnly === false) ? this.selectPerson_ceoDigitalOnly.push({value: i.ceoDigitalOnly.toString()}) : this.selectPerson_ceoDigitalOnly.push({value: ''});
        // tslint:disable-next-line:max-line-length
        (i.cbmDigitalOnly === true || i.cbmDigitalOnly === false) ? this.selectPerson_cbmDigitalOnly.push({value: i.cbmDigitalOnly.toString()}) : this.selectPerson_cbmDigitalOnly.push({value: ''});
        // tslint:disable-next-line:max-line-length
        (i.cbmDigitalAndPrint === true || i.cbmDigitalAndPrint === false) ? this.selectPerson_cbmDigitalAndPrint.push({value: i.cbmDigitalAndPrint.toString()}) : this.selectPerson_cbmDigitalAndPrint.push({value: ''});
        (i.cen === true || i.cen === false) ?
            this.selectPerson_cen.push({value: i.cen.toString()}) : this.selectPerson_cen.push({value: ''});
        (i.sen === true || i.sen === false) ?
            this.selectPerson_sen.push({value: i.sen.toString()}) : this.selectPerson_sen.push({value: ''});
        (i.ceO100 === true || i.ceO100 === false) ?
            this.selectPerson_ceo100.push({value: i.ceO100.toString()}) : this.selectPerson_ceo100.push({value: ''});
        (i.cfO100 === true || i.cfO100 === false) ?
            this.selectPerson_cfo100.push({value: i.cfO100.toString()}) : this.selectPerson_cfo100.push({value: ''});
        (i.nextLevelLeaders === true || i.nextLevelLeaders === false) ?
            this.selectPerson_nll.push({value: i.nextLevelLeaders.toString()}) : this.selectPerson_nll.push({value: ''});
        (i.cebi === true || i.cebi === false) ?
            this.selectPerson_cebi.push({value: i.cebi.toString()}) : this.selectPerson_cebi.push({value: ''});
        i.revenue ? this.selectPerson_revenue.push({value: i.revenue.toString()}) : this.selectPerson_revenue.push({value: ''});
        i.employees ? this.selectPerson_employees.push({value: i.employees.toString()}) : this.selectPerson_employees.push({value: ''});
        i.industry ? this.selectPerson_industry.push({value: i.industry.toString()}) : this.selectPerson_industry.push({value: ''});
        // tslint:disable-next-line:max-line-length
        i.otherIndustry ? this.selectPerson_otherIndustry.push({value: i.otherIndustry.toString()}) : this.selectPerson_otherIndustry.push({value: ''});
        i.ownership ? this.selectPerson_ownership.push({value: i.ownership.toString()}) : this.selectPerson_ownership.push({value: ''});
        // tslint:disable-next-line:max-line-length
        i.ownershipSubType ? this.selectPerson_ownershipSubType.push({value: i.ownershipSubType.toString()}) : this.selectPerson_ownershipSubType.push({value: ''});
        // tslint:disable-next-line:max-line-length
        i.bpaJobTitle ? this.selectPerson_bpaJobTitle.push({value: i.bpaJobTitle.toString()}) : this.selectPerson_bpaJobTitle.push({value: ''});
        // tslint:disable-next-line:max-line-length
        i.qualificationSource ? this.selectPerson_bpaQualSource.push({value: i.qualificationSource.toString()}) : this.selectPerson_bpaQualSource.push({value: ''});
      }
    }
  }
  resetSelectOptionsFields(): void{
    if (this.selectedDataType === 'Company'){
        this.companySelection = new CompanySelection();
    }else if (this.selectedDataType === 'Person'){
      this.selectPerson_firstName = [];
      this.selectPerson_middleName = [];
      this.selectPerson_lastName = [];
      this.selectPerson_suffix = [];
      this.selectPerson_title = [];
      this.selectPerson_email = [];
      this.selectPerson_altEmail = [];
      this.selectPerson_directPhone = [];
      this.selectPerson_primaryAddress1 = [];
      this.selectPerson_primaryAddress2 = [];
      this.selectPerson_primaryCity = [];
      this.selectPerson_primaryCountry = [];
      this.selectPerson_primaryState = [];
      this.selectPerson_primaryZip = [];
      this.selectPerson_alternateAddressPreferred = [];
      this.selectPerson_alternateAddressSource = [];
      this.selectPerson_alternateAddressType = [];
     // this.selectPerson_CEXSubscriberCode = [] ;
      this.selectPerson_cexSubStatus = [] ;
      this.selectPerson_cbmSubStatus = [] ;
      this.selectPerson_mobilePhone = [];
      this.selectPerson_Ticker = [];
      this.selectPerson_verifiedDate = [];
      this.selectPerson_activeStatus = [];
      this.selectPerson_companyId = [];
      this.selectPerson_WordpressId = [];
      this.selectPerson_memberClicksId = [];
      this.selectPerson_ceoDigitalAndPrint = [];
      this.selectPerson_ceoDigitalOnly = [];
      this.selectPerson_cbmDigitalAndPrint = [];
      this.selectPerson_cbmDigitalOnly = [];
      this.selectPerson_cen = [];
      this.selectPerson_sen = [];
      this.selectPerson_ceo100 = [];
      this.selectPerson_cfo100 = [];
      this.selectPerson_nll = [];
      this.selectPerson_cebi = [];
      this.selectPerson_industry = [];
      this.selectPerson_otherIndustry = [];
      this.selectPerson_revenue = [];
      this.selectPerson_employees = [];
      this.selectPerson_ownership = [];
      this.selectPerson_ownershipSubType = [];
      this.selectPerson_bpaQualDate = [];
      this.selectPerson_bpaQualSource = [];
      this.selectPerson_bpaJobTitle = [];
    }
  }
  async eliminateDuplicates(): Promise<void> {
    if (this.selectedDataType === 'Company') {
      this.processCompanySelection();
      await delay(1000);
      this.colorSelectedCompany();
    } else if (this.selectedDataType === 'Person') {
      this.eliminateDuplicatesPerson();
      await delay(1500);
      this.colorSelectedPerson();
    }
  }
  processCompanySelection(): void {
    for ( const key of Object.keys(this.companySelection)){
      switch (key){
        case 'state':
          this.populateStateCompany();
          break;
        case 'country':
          this.populateCountryCompany();
          break;
        case 'verifiedDate':
          this.populateCompanyVerifiedDate();
          break;
        default:
          this.populateCompanyFields(key);
          break;
      }
    }
  }
  populateCompanyVerifiedDate(): void{
    if (this.companySelection.verifiedDate.length === 1){
      this.companyControl.controls.verifiedDate.setValue(this.companySelection.verifiedDate[0].value)
      this.finalCompaniesToMerge[0].verifiedDate = this.companySelection.verifiedDate[0].value
    }
    else if (this.companySelection.verifiedDate.length > 1 && this.companySelection.verifiedDate.some( e => e.importedData === true)){
      const verifiedDate = this.companySelection.verifiedDate.find( e => e.importedData === true).value;
      this.companyControl.controls.verifiedDate.setValue(verifiedDate);
      this.finalCompaniesToMerge[0].verifiedDate = verifiedDate;
    }
  }
  populateCompanyFields(key: string): void{
    if (this.companySelection[key].length === 1) {
      this.companyControl.controls[key].setValue(this.companySelection[key][0]);
      this.finalCompaniesToMerge[0][key] = this.companySelection[key][0];
    }
    else if (this.companySelection[key].length > 1){
      if (this.companySelection[key].some(item => item === '')){
        const value = this.companySelection[key].find(item => item !== '');
        this.companyControl.controls[key].setValue(value);
        this.finalCompaniesToMerge[0][key] = value;
      }
      if(this.isPublicDataCompanyMatches === true && key == 'addressSource'){
        const value = this.companiesToReview.find(item=> item.fileName==='PublicCoApi').addressSource;
        this.companyControl.controls[key].setValue(value);
        this.finalCompaniesToMerge[0][key] = value;
      }
    }
  }
  colorSelectedCompany(): void{
    for ( const key of Object.keys(this.companySelection)){
      if (this.companySelection[key].length > 1){
        switch (key){
          case 'addressCompany':
            break;
          default:
            document.getElementById('company-select-' + key).style.backgroundColor = '#98e698';
            break;
        }
      }
    }
  }
  populateCountryCompany(): void {
    let array = [];
    for (const item of this.companySelection.country){
      const countryCodeId = this.countries.find(element => {
        return element.countryCode === item || element.countryName === item;
      });
      if (countryCodeId !== undefined){
        array.push(countryCodeId);
      }
      else{
        array.push({countryName: ''});
      }
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = _.uniqWith(array, (arrVal, othVal) => arrVal.countryName === othVal.countryName);
    this.companySelection.country = [];
    array.forEach(country => {
      this.companySelection.country .push(country.countryName);
    });
    if ( this.companySelection.country.length === 1){
      this.companyControl.controls.country.setValue(this.companySelection.country[0]);
      this.finalCompaniesToMerge[0].country = this.companySelection.country[0];
    }
    else if ( this.companySelection.country.length > 1 ){
      if (this.companySelection.country.some( e => e === '')){
        const country = this.companySelection.country.find( e => e !== '');
        this.companyControl.controls.country.setValue(country);
        this.finalCompaniesToMerge[0].country = country;
      }
    }
  }
  colorSelectedPerson(): void {
    console.log('person');
    if (this.selectPerson_firstName.length > 1){
      document.getElementById('person-select-firstName').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_middleName.length > 1){
      document.getElementById('person-select-middleName').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_lastName.length > 1){
      document.getElementById('person-select-lastName').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_title.length > 1){
      document.getElementById('person-select-title').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_email.length > 1){
      document.getElementById('person-select-email').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_altEmail.length > 1){
      document.getElementById('person-select-altEmail').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_directPhone.length > 1){
      document.getElementById('person-select-directPhone').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_primaryAddress1.length > 1){
      document.getElementById('person-select-primaryAddress1').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_primaryAddress2.length > 1){
      document.getElementById('person-select-primaryAddress2').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_primaryCity.length > 1){
      document.getElementById('person-select-primaryCity').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_primaryState.length > 1){
      document.getElementById('person-select-primaryState').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_primaryCountry.length > 1){
      document.getElementById('person-select-primaryCountry').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_primaryZip.length > 1){
      document.getElementById('person-select-primaryZip').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_alternateAddressSource.length > 1){
      document.getElementById('person-select-alternateAddressSource').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_alternateAddressPreferred.length > 1){
      document.getElementById('person-select-alternateAddressPreferred').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_alternateAddressType.length > 1){
      document.getElementById('person-select-alternateAddressType').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_bpaQualDate.length > 1){
      document.getElementById('person-select-bpa').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_bpaQualSource.length > 1){
      document.getElementById('person-select-bpaQualSource').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_bpaJobTitle.length > 1){
      document.getElementById('person-select-bpaJobTitle').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_verifiedDate.length > 1){
      document.getElementById('person-select-verifiedDate').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_activeStatus.length > 1){
      document.getElementById('person-select-activeStatus').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_companyId.length > 1){
      document.getElementById('person-select-companyId').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_ceoDigitalAndPrint.length > 1){
      document.getElementById('person-select-ceoDigitalAndPrint').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_ceoDigitalOnly.length > 1){
      document.getElementById('person-select-ceoDigitalOnly').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_cbmDigitalAndPrint.length > 1){
      document.getElementById('person-select-cbmDigitalAndPrint').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_cbmDigitalOnly.length > 1){
      document.getElementById('person-select-cbmDigitalOnly').style.backgroundColor = '#98e698';
    }
    if (this.selectPerson_cen.length > 1){
      this.colorsForMultipleValues('cen');
    }
    if (this.selectPerson_sen.length > 1){
      this.colorsForMultipleValues('sen');
    }
    if (this.selectPerson_ceo100.length > 1){
      this.colorsForMultipleValues('ceo100');
    }
    if (this.selectPerson_cfo100.length > 1){
      this.colorsForMultipleValues('cfo100');
    }
    if (this.selectPerson_nll.length > 1){
      this.colorsForMultipleValues('nll');
    }
    if (this.selectPerson_cebi.length > 1){
      this.colorsForMultipleValues('cebi');
    }
    if (this.isPersonApiData === true) {
      if (this.selectPerson_WordpressId.length > 1){
        document.getElementById('person-select-wordpressId').style.backgroundColor = '#e63825';
      }
      if (this.selectPerson_memberClicksId.length > 1){
        document.getElementById('person-select-memberClicksId').style.backgroundColor = '#e63825';
      }
      if (this.selectPerson_cbmSubStatus.length > 1){
        document.getElementById('person-select-CBMSubStatus').style.backgroundColor = '#98e698';
      }
      if (this.selectPerson_cexSubStatus.length > 1){
        document.getElementById('person-select-CEXSubStatus').style.backgroundColor = '#98e698';
      }
      if (this.selectPerson_revenue.length > 1){
        this.colorsForMultipleValues('revenue');
      }
      if (this.selectPerson_ownership.length > 1){
        document.getElementById('person-select-ownership').style.backgroundColor = '#98e698';
      }
      if (this.selectPerson_ownershipSubType.length > 1){
        document.getElementById('person-select-ownershipSubType').style.backgroundColor = '#98e698';
      }
      if (this.selectPerson_employees.length > 1){
        document.getElementById('person-select-employees').style.backgroundColor = '#98e698';
      }
      if (this.selectPerson_industry.length > 1){
        document.getElementById('person-select-industry').style.backgroundColor = '#98e698';
      }
      if (this.selectPerson_otherIndustry.length > 1){
        document.getElementById('person-select-otherIndustry').style.backgroundColor = '#98e698';
      }
      if (this.selectPerson_bpaQualDate.length > 1){
        document.getElementById('person-select-bpa').style.backgroundColor = '#98e698';
      }
      if (this.selectPerson_bpaQualSource.length > 1){
        document.getElementById('person-select-bpaQualSource').style.backgroundColor = '#98e698';
      }
      if (this.selectPerson_bpaJobTitle.length > 1){
        document.getElementById('person-select-bpaJobTitle').style.backgroundColor = '#98e698';
      }
    }
  }
  colorsForMultipleValues(field: string): void{
    document.getElementById('person-select-' + field).style.backgroundColor = '#98e698';
  }

  eliminateDuplicatesPerson(): void {
    let array = [];

    // firstName
    array = [];
    for (const j of this.selectPerson_firstName) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_firstName = [];
    for (const k of array) {
      this.selectPerson_firstName.push({value: k});
    }
    if (this.selectPerson_firstName.length === 1) {
      this.firstNameControl = new FormControl(this.selectPerson_firstName[0].value);
      this.finalPeopleToMerge[0].firstName = this.selectPerson_firstName[0].value;
    }
    else if ( this.selectPerson_firstName.length > 1 && this.selectPerson_firstName.some( e => e.value === '')){
      const firstName = this.selectPerson_firstName.find( e => e.value !== '').value;
      this.firstNameControl = new FormControl(firstName);
      this.finalPeopleToMerge[0].firstName = firstName;
    }
    // middleName
    array = [];
    for (const j of this.selectPerson_middleName) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_middleName = [];
    for (const k of array) {
      this.selectPerson_middleName.push({value: k});
    }
    if (this.selectPerson_middleName.length === 1) {
      this.middleNameControl = new FormControl(this.selectPerson_middleName[0].value);
      this.finalPeopleToMerge[0].middleName = this.selectPerson_middleName[0].value;
    }
    else if ( this.selectPerson_middleName.length > 1 && this.selectPerson_middleName.some( e => e.value === '')){
      const middleName = this.selectPerson_middleName.find( e => e.value !== '').value;
      this.middleNameControl = new FormControl(middleName);
      this.finalPeopleToMerge[0].middleName = middleName;
    }
    // lastName
    array = [];
    for (const j of this.selectPerson_lastName) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_lastName = [];
    for (const k of array) {
      this.selectPerson_lastName.push({value: k});
    }
    if (this.selectPerson_lastName.length === 1) {
      this.lastNameControl = new FormControl(this.selectPerson_lastName[0].value);
      this.finalPeopleToMerge[0].lastName = this.selectPerson_lastName[0].value;
    }
    else if ( this.selectPerson_lastName.length > 1 && this.selectPerson_lastName.some( e => e.value === '')){
      const lastName = this.selectPerson_lastName.find( e => e.value !== '').value;
      this.lastNameControl = new FormControl(lastName);
      this.finalPeopleToMerge[0].lastName = lastName;
    }
    // suffix
    array = [];
    for (const j of this.selectPerson_suffix) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_suffix = [];
    for (const k of array) {
      this.selectPerson_suffix.push({value: k});
    }
    if (this.selectPerson_suffix.length === 1) {
      this.suffixControl = new FormControl(this.selectPerson_suffix[0].value);
      this.finalPeopleToMerge[0].suffix = this.selectPerson_suffix[0].value;
    }
    else if ( this.selectPerson_suffix.length > 1 && this.selectPerson_suffix.some( e => e.value === '')){
      const suffix = this.selectPerson_suffix.find( e => e.value !== '').value;
      this.suffixControl = new FormControl(suffix);
      this.finalPeopleToMerge[0].suffix = suffix;
    }
    // title
    array = [];
    for (const j of this.selectPerson_title) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_title = [];
    for (const k of array) {
      this.selectPerson_title.push({value: k});
    }
    if (this.selectPerson_title.length === 1) {
      this.titleControl = new FormControl(this.selectPerson_title[0].value);
      this.finalPeopleToMerge[0].title = this.selectPerson_title[0].value;
    }
    else if ( this.selectPerson_title.length > 1 && this.selectPerson_title.some( e => e.value === '')){
      const title = this.selectPerson_title.find( e => e.value !== '').value;
      this.titleControl = new FormControl(title);
      this.finalPeopleToMerge[0].title = title;
    }
    // email
    array = [];
    for (const j of this.selectPerson_email) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_email = [];
    for (const k of array) {
      this.selectPerson_email.push({value: k});
    }
    if (this.selectPerson_email.length === 1) {
      this.emailControl = new FormControl(this.selectPerson_email[0].value);
      this.finalPeopleToMerge[0].email = this.selectPerson_email[0].value;
    }
    else if ( this.selectPerson_email.length > 1 && this.selectPerson_email.some( e => e.value === '')){
      const email = this.selectPerson_email.find( e => e.value !== '').value;
      this.emailControl = new FormControl(email);
      this.finalPeopleToMerge[0].email = email;
    }
    // altEmail
    array = [];
    for (const j of this.selectPerson_altEmail) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_altEmail = [];
    for (const k of array) {
      this.selectPerson_altEmail.push({value: k});
    }
    if (this.selectPerson_altEmail.length === 1) {
      this.altEmailControl = new FormControl(this.selectPerson_altEmail[0].value);
      this.finalPeopleToMerge[0].altEmail = this.selectPerson_altEmail[0].value;
    }
    else if ( this.selectPerson_altEmail.length > 1 && this.selectPerson_altEmail.some( e => e.value === '')){
      const altEmail = this.selectPerson_altEmail.find( e => e.value !== '').value;
      this.altEmailControl = new FormControl(altEmail);
      this.finalPeopleToMerge[0].altEmail = altEmail;
    }
    // directPhone
    array = [];
    for (const j of this.selectPerson_directPhone) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_directPhone = [];
    for (const k of array) {
      this.selectPerson_directPhone.push({value: k});
    }
    if (this.selectPerson_directPhone.length === 1) {
      this.directPhoneControl = new FormControl(this.selectPerson_directPhone[0].value);
      this.finalPeopleToMerge[0].directPhone = this.selectPerson_directPhone[0].value;
    }
    else if ( this.selectPerson_directPhone.length > 1 && this.selectPerson_directPhone.some( e => e.value === '')){
      const directPhone = this.selectPerson_directPhone.find( e => e.value !== '').value;
      this.directPhoneControl = new FormControl(directPhone);
      this.finalPeopleToMerge[0].directPhone = directPhone;
    }
    // mobilePhone
    array = [];
    for (const j of this.selectPerson_mobilePhone) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_mobilePhone = [];
    for (const k of array) {
      this.selectPerson_mobilePhone.push({value: k});
    }
    if (this.selectPerson_mobilePhone.length === 1) {
      this.mobilePhoneControl = new FormControl(this.selectPerson_mobilePhone[0].value);
      this.finalPeopleToMerge[0].mobilePhone = this.selectPerson_mobilePhone[0].value;
    }
    else if ( this.selectPerson_mobilePhone.length > 1 && this.selectPerson_mobilePhone.some( e => e.value === '')){
      const mobilePhone = this.selectPerson_mobilePhone.find( e => e.value !== '').value;
      this.mobilePhoneControl = new FormControl(mobilePhone);
      this.finalPeopleToMerge[0].mobilePhone = mobilePhone;
    }
    // shippingaddr1
    array = [];
    for (const j of this.selectPerson_primaryAddress1) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_primaryAddress1 = [];
    for (const k of array) {
      this.selectPerson_primaryAddress1.push({value: k});
    }
    if (this.selectPerson_primaryAddress1.length === 1) {
      this.primaryAddress1Control = new FormControl(this.selectPerson_primaryAddress1[0].value);
      this.finalPeopleToMerge[0].primaryAddress1 = this.selectPerson_primaryAddress1[0].value;
    }
    else if ( this.selectPerson_primaryAddress1.length > 1 && this.selectPerson_primaryAddress1.some( e => e.value === '')){
      const primaryAddress1 = this.selectPerson_primaryAddress1.find( e => e.value !== '').value;
      this.primaryAddress1Control = new FormControl(primaryAddress1);
      this.finalPeopleToMerge[0].primaryAddress1 = primaryAddress1;
    }
    // shippingaddr2
    array = [];
    for (const j of this.selectPerson_primaryAddress2) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_primaryAddress2 = [];
    for (const k of array) {
      this.selectPerson_primaryAddress2.push({value: k});
    }
    if (this.selectPerson_primaryAddress2.length === 1) {
      this.primaryAddress2Control = new FormControl(this.selectPerson_primaryAddress2[0].value);
      this.finalPeopleToMerge[0].primaryAddress2 = this.selectPerson_primaryAddress2[0].value;
    }
    else if ( this.selectPerson_primaryAddress2.length > 1 && this.selectPerson_primaryAddress2.some( e => e.value === '')){
      const primaryAddress2 = this.selectPerson_primaryAddress2.find( e => e.value !== '').value;
      this.primaryAddress2Control = new FormControl(primaryAddress2);
      this.finalPeopleToMerge[0].primaryAddress2 = primaryAddress2;
    }
    // shipping city
    array = [];
    for (const j of this.selectPerson_primaryCity) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_primaryCity = [];
    for (const k of array) {
      this.selectPerson_primaryCity.push({value: k});
    }
    if ( this.selectPerson_primaryCity.length === 1) {
      this.primaryCityControl = new FormControl( this.selectPerson_primaryCity[0].value);
      this.finalPeopleToMerge[0].primaryCity =  this.selectPerson_primaryCity[0].value;
    }
    else if ( this.selectPerson_primaryCity.length > 1 && this.selectPerson_primaryCity.some( e => e.value === '')){
      const primaryCity = this.selectPerson_primaryCity.find( e => e.value !== '').value;
      this.primaryCityControl = new FormControl(primaryCity);
      this.finalPeopleToMerge[0].primaryCity = primaryCity;
    }
    // shipping state
    this.autoPopulateStatePerson();

    // shipping zipcode
    array = [];
    for (const j of this.selectPerson_primaryZip) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_primaryZip = [];
    for (const k of array) {
      this.selectPerson_primaryZip.push({value: k});
    }
    if (this.selectPerson_primaryZip.length === 1) {
      this.primaryZipControl = new FormControl(this.selectPerson_primaryZip[0].value);
      this.finalPeopleToMerge[0].primaryZip = this.selectPerson_primaryZip[0].value;
    }
    else if ( this.selectPerson_primaryZip.length > 1 && this.selectPerson_primaryZip.some( e => e.value === '')){
      const primaryZip = this.selectPerson_primaryZip.find( e => e.value !== '').value;
      this.primaryZipControl = new FormControl(primaryZip);
      this.finalPeopleToMerge[0].primaryZip = primaryZip;
    }
    // shipping country
    this.autoPopulateCountryPerson();
    // ticker
    array = [];
    for (const j of this.selectPerson_Ticker){
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_Ticker = [];
    for (const k of array){
      this.selectPerson_Ticker.push({value: k});
    }
    if (this.selectPerson_Ticker.length === 1){
      this.personTickerControl = new FormControl(this.selectPerson_Ticker[0].value);
      this.finalPeopleToMerge[0].ticker = this.selectPerson_Ticker[0].value;
    }
    else if ( this.selectPerson_Ticker.length > 1 && this.selectPerson_Ticker.some( e => e.value === '')){
      const ticker = this.selectPerson_Ticker.find( e => e.value !== '').value;
      this.personTickerControl = new FormControl(ticker);
      this.finalPeopleToMerge[0].ticker = ticker;
    }
    // shipping alternateAddressPreferred
    array = [];
    for (const j of this.selectPerson_alternateAddressPreferred) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_alternateAddressPreferred = [];
    for (const k of array) {
      this.selectPerson_alternateAddressPreferred.push({value: k});
    }
    if (this.selectPerson_alternateAddressPreferred.length === 1) {
      this.alternateAddressPreferredControl = new FormControl(this.selectPerson_alternateAddressPreferred[0].value);
      // tslint:disable-next-line:variable-name radix
      this.finalPeopleToMerge[0].alternateAddressPreferred = parseInt(this.selectPerson_alternateAddressPreferred[0].value);
    }
    else if ( this.selectPerson_alternateAddressPreferred.length > 1 && this.selectPerson_alternateAddressPreferred.some( e => e.value === '')){
      const alternateAddressPreferred = this.selectPerson_alternateAddressPreferred.find( e => e.value !== '').value;
      this.alternateAddressPreferredControl = new FormControl(alternateAddressPreferred);
      // tslint:disable-next-line:variable-name radix
      this.finalPeopleToMerge[0].alternateAddressPreferred = parseInt(alternateAddressPreferred);
    }
    // alternateAddressSource
    array = [];
    for (const j of this.selectPerson_alternateAddressSource) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_alternateAddressSource = [];
    for (const k of array) {
      this.selectPerson_alternateAddressSource.push({value: k});
    }
    if (this.selectPerson_alternateAddressSource.length === 1) {
      this.alternateAddressSourceControl = new FormControl(this.selectPerson_alternateAddressSource[0].value);
      // tslint:disable-next-line:variable-name radix
      this.finalPeopleToMerge[0].alternateAddressSource = this.selectPerson_alternateAddressSource[0].value;
    }
    else if ( this.selectPerson_alternateAddressSource.length > 1 && this.selectPerson_alternateAddressSource.some( e => e.value === '')){
      const alternateAddressSource = this.selectPerson_alternateAddressSource.find( e => e.value !== '').value;
      this.alternateAddressSourceControl = new FormControl(alternateAddressSource);
      this.finalPeopleToMerge[0].alternateAddressSource = alternateAddressSource;
    }
    // alternateAddressType

    // tslint:disable-next-line:only-arrow-functions typedef
    this.selectPerson_alternateAddressType = this.selectPerson_alternateAddressType.filter((elem, index, self) =>
          index === self.findIndex((t) => (
          t.value === elem.value))
    );
    if (this.selectPerson_alternateAddressType.length === 1) {
      this.alternateAddressTypeControl = new FormControl(this.selectPerson_alternateAddressType[0].value);
      this.finalPeopleToMerge[0].alternateAddressType = this.selectPerson_alternateAddressType[0].value;
    }
    else if ( this.selectPerson_alternateAddressType.length > 1 && this.selectPerson_alternateAddressType.some( e => e.value === '')){
      const alternateAddressType = this.selectPerson_alternateAddressType.find( e => e.value !== '').value;
      this.alternateAddressTypeControl = new FormControl(alternateAddressType);
      this.finalPeopleToMerge[0].alternateAddressType = alternateAddressType;
    }
    // verifiedDate
    this.selectPerson_verifiedDate = this.selectPerson_verifiedDate.filter((elem, index, self) =>
        index === self.findIndex((t) => (
            t.value === elem.value))
    );
    if (this.selectPerson_verifiedDate.length === 1) {
      this.personVerifiedDateControl = new FormControl(this.selectPerson_verifiedDate[0].value);
      this.finalPeopleToMerge[0].verifiedDate = this.selectPerson_verifiedDate[0].value;
    }
    else if ( this.selectPerson_verifiedDate.length > 1 && this.selectPerson_verifiedDate.some( e => e.importedData === true)){
      const verifiedDate = this.selectPerson_verifiedDate.find( e => e.importedData === true).value;
      this.personVerifiedDateControl = new FormControl(verifiedDate);
      this.finalPeopleToMerge[0].verifiedDate = verifiedDate;
    }
    // activeStatus
    this.selectPerson_activeStatus = [];
    this.selectPerson_activeStatus.push({value: 'true'});
    this.selectPerson_activeStatus.push({value: 'false'});

    // Primary Co ID
    array = [];
    for (const j of this.selectPerson_companyId){
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_companyId = [];
    for (const k of array){
      this.selectPerson_companyId.push({value: k});
    }
    if (this.selectPerson_companyId.length === 1){
      this.personCompanyIdControl = new FormControl(this.selectPerson_companyId[0].value);
      this.finalPeopleToMerge[0].companyId = parseInt(this.selectPerson_companyId[0].value, 10);
    }
    else if ( this.selectPerson_companyId.length > 1 && this.selectPerson_companyId.some( e => e.value === '')){
      const companyId = this.selectPerson_companyId.find( e => e.value !== '').value;
      this.personCompanyIdControl = new FormControl(companyId);
      this.finalPeopleToMerge[0].companyId = Number(companyId);
    }
    // WordPress ID
    this.selectPerson_WordpressId = this.selectPerson_WordpressId.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_WordpressId.length === 1){
      this.personWordpressIdControl = new FormControl(this.selectPerson_WordpressId[0].value);
      this.finalPeopleToMerge[0].wordpressId = parseInt(this.selectPerson_WordpressId[0].value,  10);
    }
    else if ( this.selectPerson_WordpressId.length > 1 && this.selectPerson_WordpressId.some( e => e.value === '')){
      const wordpressId = this.selectPerson_WordpressId.find( e => e.value !== '').value;
      this.personWordpressIdControl = new FormControl(wordpressId);
      this.finalPeopleToMerge[0].wordpressId = parseInt(wordpressId, 10);
    }
    // memberClicksId
    this.selectPerson_memberClicksId = this.selectPerson_memberClicksId.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_memberClicksId.length === 1){
      this.personMemberClicksIdControl = new FormControl(this.selectPerson_memberClicksId[0].value);
      this.finalPeopleToMerge[0].memberClicksId = parseInt(this.selectPerson_memberClicksId[0].value,  10);
    }
    else if ( this.selectPerson_memberClicksId.length > 1 && this.selectPerson_memberClicksId.some( e => e.value === '')){
      const wordpressId = this.selectPerson_memberClicksId.find( e => e.value !== '').value;
      this.personMemberClicksIdControl = new FormControl(wordpressId);
      this.finalPeopleToMerge[0].memberClicksId = parseInt(wordpressId, 10);
    }
    // cexSubStatus
    array = [];
    for (const j of this.selectPerson_cexSubStatus) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_cexSubStatus = [];
    for (const k of array) {
      this.selectPerson_cexSubStatus.push({value: k});
    }
    if (this.selectPerson_cexSubStatus.length === 1) {
      this.cexSubStatusControl = new FormControl(this.selectPerson_cexSubStatus[0].value);
      // tslint:disable-next-line:variable-name radix
      this.finalPeopleToMerge[0].cexSubStatus = this.selectPerson_cexSubStatus[0].value;
    }
    else if ( this.selectPerson_cexSubStatus.length > 1 && this.selectPerson_cexSubStatus.some( e => e.value === '')){
      const cexSubStatus = this.selectPerson_cexSubStatus.find( e => e.value !== '').value;
      this.cexSubStatusControl = new FormControl(cexSubStatus);
      this.finalPeopleToMerge[0].cexSubStatus = cexSubStatus;
    }

    // cbmSubStatus
    array = [];
    for (const j of this.selectPerson_cbmSubStatus) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_cbmSubStatus = [];
    for (const k of array) {
      this.selectPerson_cbmSubStatus.push({value: k});
    }
    if (this.selectPerson_cbmSubStatus.length === 1) {
      this.cbmSubStatusControl = new FormControl(this.selectPerson_cbmSubStatus[0].value);
      // tslint:disable-next-line:variable-name radix
      this.finalPeopleToMerge[0].cbmSubStatus = this.selectPerson_cbmSubStatus[0].value;
    }  else if ( this.selectPerson_cbmSubStatus.length > 1 && this.selectPerson_cbmSubStatus.some( e => e.value === '')){
      const cbmSubStatus = this.selectPerson_cbmSubStatus.find( e => e.value !== '').value;
      this.cbmSubStatusControl = new FormControl(cbmSubStatus);
      this.finalPeopleToMerge[0].cbmSubStatus = cbmSubStatus;
    }

    // ceoDigitalAndPrint code
    array = [];
    for (const j of this.selectPerson_ceoDigitalAndPrint) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_ceoDigitalAndPrint = [];
    for (const k of array) {
      this.selectPerson_ceoDigitalAndPrint.push({value: k});
    }
    if (this.selectPerson_ceoDigitalAndPrint.length === 1) {
      this.ceoDigitalAndPrintControl = new FormControl(this.selectPerson_ceoDigitalAndPrint[0].value);
      if (this.selectPerson_ceoDigitalAndPrint[0].value ===  'True' || this.selectPerson_ceoDigitalAndPrint[0].value ===  'true' ){
        this.finalPeopleToMerge[0].ceoDigitalAndPrint = true;
      }
      else{
        this.finalPeopleToMerge[0].ceoDigitalAndPrint = false;
      }
    }
    // ceoDigitalOnly code
    array = [];
    for (const j of this.selectPerson_ceoDigitalOnly) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_ceoDigitalOnly = [];
    for (const k of array) {
      this.selectPerson_ceoDigitalOnly.push({value: k});
    }
    if (this.selectPerson_ceoDigitalOnly.length === 1) {
      this.ceoDigitalOnlyControl = new FormControl(this.selectPerson_ceoDigitalOnly[0].value);
      if (this.selectPerson_ceoDigitalOnly[0].value ===  'True' || this.selectPerson_ceoDigitalOnly[0].value ===  'true' ){
        this.finalPeopleToMerge[0].ceoDigitalOnly = true;
      }
      else{
        this.finalPeopleToMerge[0].ceoDigitalOnly = false;
      }
    }
    // cbmDigitalAndPrint code
    array = [];
    for (const j of this.selectPerson_cbmDigitalAndPrint) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_cbmDigitalAndPrint = [];
    for (const k of array) {
      this.selectPerson_cbmDigitalAndPrint.push({value: k});
    }
    if (this.selectPerson_cbmDigitalAndPrint.length === 1) {
      this.cbmDigitalAndPrintControl = new FormControl(this.selectPerson_cbmDigitalAndPrint[0].value);
      if (this.selectPerson_cbmDigitalAndPrint[0].value ===  'True' || this.selectPerson_cbmDigitalAndPrint[0].value ===  'true' ){
        this.finalPeopleToMerge[0].cbmDigitalAndPrint = true;
      }
      else{
        this.finalPeopleToMerge[0].cbmDigitalAndPrint = false;
      }
    }
    // cbmDigitalOnly code
    array = [];
    for (const j of this.selectPerson_cbmDigitalOnly) {
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_cbmDigitalOnly = [];
    for (const k of array) {
      this.selectPerson_cbmDigitalOnly.push({value: k});
    }
    if (this.selectPerson_cbmDigitalOnly.length === 1) {
      this.cbmDigitalOnlyControl = new FormControl(this.selectPerson_cbmDigitalOnly[0].value);
      if (this.selectPerson_cbmDigitalOnly[0].value ===  'True' || this.selectPerson_cbmDigitalOnly[0].value ===  'true' ){
        this.finalPeopleToMerge[0].cbmDigitalOnly = true;
      }
      else{
        this.finalPeopleToMerge[0].cbmDigitalOnly = false;
      }
    }
    // cen
    this.selectPerson_cen = this.selectPerson_cen.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_cen.length === 1) {
      this.cenControl = new FormControl(this.selectPerson_cen[0].value);
      if (this.selectPerson_cen[0].value ===  'True' || this.selectPerson_cen[0].value ===  'true' ){
        this.finalPeopleToMerge[0].cen = true;
      }
      else{
        this.finalPeopleToMerge[0].cen = false;
      }
    }
    // sen
    this.selectPerson_sen = this.selectPerson_sen.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_sen.length === 1) {
      this.senControl = new FormControl(this.selectPerson_sen[0].value);
      if (this.selectPerson_sen[0].value ===  'True' || this.selectPerson_sen[0].value ===  'true' ){
        this.finalPeopleToMerge[0].sen = true;
      }
      else{
        this.finalPeopleToMerge[0].sen = false;
      }
    }
    // ceo100
    this.selectPerson_ceo100 = this.selectPerson_ceo100.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_ceo100.length === 1) {
      this.ceo100Control = new FormControl(this.selectPerson_ceo100[0].value);
      if (this.selectPerson_ceo100[0].value ===  'True' || this.selectPerson_ceo100[0].value ===  'true' ){
        this.finalPeopleToMerge[0].ceO100 = true;
      }
      else{
        this.finalPeopleToMerge[0].ceO100 = false;
      }
    }
    // cfo100
    this.selectPerson_cfo100 = this.selectPerson_cfo100.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_cfo100.length === 1) {
      this.cfo100Control = new FormControl(this.selectPerson_cfo100[0].value);
      if (this.selectPerson_cfo100[0].value ===  'True' || this.selectPerson_cfo100[0].value ===  'true' ){
        this.finalPeopleToMerge[0].cfO100 = true;
      }
      else{
        this.finalPeopleToMerge[0].cfO100 = false;
      }
    }
    // nextLevelLeaders
    this.selectPerson_nll = this.selectPerson_nll.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_nll.length === 1) {
      this.nllControl = new FormControl(this.selectPerson_nll[0].value);
      if (this.selectPerson_nll[0].value ===  'True' || this.selectPerson_nll[0].value ===  'true' ){
        this.finalPeopleToMerge[0].nextLevelLeaders = true;
      }
      else{
        this.finalPeopleToMerge[0].nextLevelLeaders = false;
      }
    }
    // nextLevelLeaders
    this.selectPerson_cebi = this.selectPerson_cebi.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_cebi.length === 1) {
      this.cebiControl = new FormControl(this.selectPerson_cebi[0].value);
      if (this.selectPerson_cebi[0].value ===  'True' || this.selectPerson_cebi[0].value ===  'true' ){
        this.finalPeopleToMerge[0].cebi = true;
      }
      else{
        this.finalPeopleToMerge[0].cebi = false;
      }
    }
     // revenue
    this.selectPerson_revenue = this.selectPerson_revenue.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_revenue.length === 1){
      this.revenueControl = new FormControl(this.selectPerson_revenue[0].value);
      this.finalPeopleToMerge[0].revenue = this.selectPerson_revenue[0].value;
    }
    else if ( this.selectPerson_revenue.length > 1 && this.selectPerson_revenue.some( e => e.value === '')){
      const revenue = this.selectPerson_revenue.find( e => e.value !== '').value;
      this.revenueControl = new FormControl(revenue);
      this.finalPeopleToMerge[0].revenue = revenue;
    }
    // industry
    this.selectPerson_industry = this.selectPerson_industry.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_industry.length === 1){
      this.industryControl = new FormControl(this.selectPerson_industry[0].value);
      this.finalPeopleToMerge[0].industry = this.selectPerson_industry[0].value;
    }
    else if ( this.selectPerson_industry.length > 1 && this.selectPerson_industry.some( e => e.value === '')){
      const industry = this.selectPerson_industry.find( e => e.value !== '').value;
      this.industryControl = new FormControl(industry);
      this.finalPeopleToMerge[0].industry = industry;
    }
    // other industry
    this.selectPerson_otherIndustry = this.selectPerson_otherIndustry.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_otherIndustry.length === 1){
      this.otherIndustryControl = new FormControl(this.selectPerson_otherIndustry[0].value);
      this.finalPeopleToMerge[0].otherIndustry = this.selectPerson_otherIndustry[0].value;
    }
    else if ( this.selectPerson_otherIndustry.length > 1 && this.selectPerson_otherIndustry.some( e => e.value === '')){
      const otherIndustry = this.selectPerson_otherIndustry.find( e => e.value !== '').value;
      this.otherIndustryControl = new FormControl(otherIndustry);
      this.finalPeopleToMerge[0].otherIndustry = otherIndustry;
    }
    // employees
    this.selectPerson_employees = this.selectPerson_employees.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_employees.length === 1){
      this.employeesControl = new FormControl(this.selectPerson_employees[0].value);
      this.finalPeopleToMerge[0].employees = this.selectPerson_employees[0].value;
    }
    else if ( this.selectPerson_employees.length > 1 && this.selectPerson_employees.some( e => e.value === '')){
      const employees = this.selectPerson_employees.find( e => e.value !== '').value;
      this.employeesControl = new FormControl(employees);
      this.finalPeopleToMerge[0].employees = employees;
    }
    // ownership
    this.selectPerson_ownership = this.selectPerson_ownership.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj.value).indexOf(obj.value) === pos;
    });
    if (this.selectPerson_ownership.length === 1){
      this.ownershipControl = new FormControl(this.selectPerson_ownership[0].value);
      this.finalPeopleToMerge[0].ownership = this.selectPerson_ownership[0].value;
    }
    else if ( this.selectPerson_ownership.length > 1 && this.selectPerson_ownership.some( e => e.value === '')){
      const ownership = this.selectPerson_ownership.find( e => e.value !== '').value;
      this.ownershipControl = new FormControl(ownership);
      this.finalPeopleToMerge[0].ownership = ownership;
    }
    // ownership SubType
    array = [];
    for (const j of this.selectPerson_ownershipSubType){
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_ownershipSubType = [];
    for (const k of array){
      this.selectPerson_ownershipSubType.push({value: k});
    }
    if (this.selectPerson_ownershipSubType.length === 1){
      this.ownershipSubTypeControl = new FormControl(this.selectPerson_ownershipSubType[0].value);
      this.finalPeopleToMerge[0].ownershipSubType = this.selectPerson_ownershipSubType[0].value;
    }
    else if ( this.selectPerson_ownershipSubType.length > 1 && this.selectPerson_ownershipSubType.some( e => e.value === '')){
      const ownershipSubType = this.selectPerson_ownershipSubType.find( e => e.value !== '').value;
      this.ownershipSubTypeControl = new FormControl(ownershipSubType);
      this.finalPeopleToMerge[0].ownershipSubType = ownershipSubType;
    }

    // bpa qual source
    array = [];
    for (const j of this.selectPerson_bpaQualSource){
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_bpaQualSource = [];
    for (const k of array){
      this.selectPerson_bpaQualSource.push({value: k});
    }
    if (this.selectPerson_bpaQualSource.length === 1){
      this.personBPAQualSourceControl = new FormControl(this.selectPerson_bpaQualSource[0].value);
      this.finalPeopleToMerge[0].qualificationSource = this.selectPerson_bpaQualSource[0].value;
    }
    else if ( this.selectPerson_bpaQualSource.length > 1 && this.selectPerson_bpaQualSource.some( e => e.value === '')){
      const qualificationSource = this.selectPerson_bpaQualSource.find( e => e.value !== '').value;
      this.personBPAQualSourceControl = new FormControl(qualificationSource);
      this.finalPeopleToMerge[0].qualificationSource = qualificationSource;
    }
    // bpa job title
    array = [];
    for (const j of this.selectPerson_bpaJobTitle){
      array.push(j.value);
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.selectPerson_bpaJobTitle = [];
    for (const k of array){
      this.selectPerson_bpaJobTitle.push({value: k});
    }
    if (this.selectPerson_bpaJobTitle.length === 1){
      this.personBPAJobTitleControl = new FormControl(this.selectPerson_bpaJobTitle[0].value);
      this.finalPeopleToMerge[0].bpaJobTitle = this.selectPerson_bpaJobTitle[0].value;
    }
    else if ( this.selectPerson_bpaJobTitle.length > 1 && this.selectPerson_bpaJobTitle.some( e => e.value === '')){
      const bpaJobTitle = this.selectPerson_bpaJobTitle.find( e => e.value !== '').value;
      this.personBPAJobTitleControl = new FormControl(bpaJobTitle);
      this.finalPeopleToMerge[0].bpaJobTitle = bpaJobTitle;
    }
  }
  autoPopulateCountryPerson(): void {
    let array = [];
    for (const j of this.selectPerson_primaryCountry) {
      const countryCodeId = this.countries.find(element => {
        return element.countryCode === j.value || element.countryName === j.value;
      });
      if (countryCodeId !== undefined){
        array.push(countryCodeId);
      }
      else{
        array.push({countryName: ''});
      }
    }
    // tslint:disable-next-line:only-arrow-functions typedef
    array = _.uniqWith(array, (arrVal, othVal) => arrVal.countryName === othVal.countryName);
    this.selectPerson_primaryCountry = [];
    array.forEach(country => {
      this.selectPerson_primaryCountry.push({value: country.countryName});
    });
    if (this.selectPerson_primaryCountry.length === 1) {
      this.primaryCountryControl = new FormControl(this.selectPerson_primaryCountry[0].value);
      this.finalPeopleToMerge[0].primaryCountry = this.selectPerson_primaryCountry[0].value;
    }
    else if ( this.selectPerson_primaryCountry.length > 1 && this.selectPerson_primaryCountry.some( e => e.value === '')){
      const primaryCountry = this.selectPerson_primaryCountry.find( e => e.value !== '').value;
      this.primaryCountryControl = new FormControl(primaryCountry);
      this.finalPeopleToMerge[0].primaryCountry = primaryCountry;
    }
  }
  // tslint:disable-next-line:typedef
  autoPopulateStatePerson(){
    let array = [];
    for (const j of this.selectPerson_primaryState) {
      const state = this.findState(j.stateCode, j.countryCode)
      if (state !== undefined){
           array.push(state);
      }
      // if the country is empty or is not in our db
      else{
        array.push({stateCode: null, stateName: ''});
      }
    }
    array = _.uniqWith(array, (arrVal, othVal) => arrVal.stateName === othVal.stateName);
    this.selectPerson_primaryState = [];
    array.forEach(state => {
      this.selectPerson_primaryState.push({stateCode: state.stateName, countryCode: state.countryCodeId});
    });
    if ( this.selectPerson_primaryState.length === 1) {
      this.primaryStateControl = new FormControl ( this.selectPerson_primaryState[0].stateCode);
      this.finalPeopleToMerge[0].primaryState =  this.selectPerson_primaryState[0].stateCode;
    }
    else if ( this.selectPerson_primaryState.length > 1 && this.selectPerson_primaryState.some( e => e.stateCode === '')){
      const primaryState = this.selectPerson_primaryState.find( e => e.stateCode !== '').stateCode;
      this.primaryStateControl = new FormControl(primaryState);
      this.finalPeopleToMerge[0].primaryState = primaryState;
    }
  }
  populateStateCompany(): void{
    let array = [];
    for (const j of this.companySelection.state) {
      const state = this.findState(j.stateCode, j.countryCode)
      if (state != undefined){
          array.push(state);
      }
      else{
        array.push({stateCode: null, stateName: ''});
      }
    }
    array = _.uniqWith(array, (arrVal, othVal) => arrVal.stateName === othVal.stateName);
    this.companySelection.state = [];
    array.forEach(state => {
      this.companySelection.state.push({stateCode: state.stateName, countryCode: state.countryCodeId});
    });
    if ( this.companySelection.state.length === 1) {
      this.companyControl.controls.state.setValue( this.companySelection.state[0].stateCode);
      this.finalCompaniesToMerge[0].state =  this.companySelection.state[0].stateCode;
    }
    else if ( this.companySelection.state.length > 1){
      if (this.companySelection.state.some( e => e.stateCode === '')){
        const state = this.companySelection.state.find( e => e.stateCode !== '').stateCode;
        this.companyControl.controls.state = new FormControl(state);
        this.finalCompaniesToMerge[0].city = state;
      }
    }
  }

  constructMergedCompany(type: string, event: any): void {
    const content = event.value;
    switch (type) {
      case 'WinpurePrimeK': {
        // tslint:disable-next-line:prefer-for-of
        this.mergedCompanies[0].winPurePrimK = content;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.companiesToMerge.length; i++){
          if (this.companiesToMerge[i].winPurePrimK === content) {
            this.mergedCompanies[0].companyId = this.companiesToMerge[i].companyId;
            this.finalCompaniesToMerge[0].companyId = this.companiesToMerge[i].companyId;
            this.finalCompaniesToMerge[0].ticker = this.companiesToMerge[i].ticker;
            this.finalCompaniesToMerge[0].exchange = this.companiesToMerge[i].exchange;
            this.finalCompaniesToMerge[0].groupId = this.selected_group;
            this.finalCompaniesToMerge[0]['group ID'] = this.selected_group;
            this.finalCompaniesToMerge[0].ownershipTypeDesc = this.companiesToMerge[i].ownershipTypeDesc;
            this.finalCompaniesToMerge[0].fileName = this.companiesToMerge[i].fileName;
            this.finalCompaniesToMerge[0].importId = this.companiesToMerge[i].importId;
            this.finalCompaniesToMerge[0].numberOfEmployees = this.companiesToMerge[i].numberOfEmployees;
            this.finalCompaniesToMerge[0].sicCode = this.companiesToMerge[i].sicCode;
            this.finalCompaniesToMerge[0].naiscCode = this.companiesToMerge[i].naiscCode;
            this.finalCompaniesToMerge[0].businessIndustry = this.companiesToMerge[i].businessIndustry;
            this.finalCompaniesToMerge[0].companyType = this.companiesToMerge[i].companyType;
            this.finalCompaniesToMerge[0].h_IsHeadquarters = this.companiesToMerge[i].h_IsHeadquarters;
            this.finalCompaniesToMerge[0].h_ParentCompany = this.companiesToMerge[i].h_ParentCompany;
            this.finalCompaniesToMerge[0].h_ParentCountry_Region = this.companiesToMerge[i].h_ParentCountry_Region;
            this.finalCompaniesToMerge[0].h_BusinessDescription = this.companiesToMerge[i].h_BusinessDescription;
            this.finalCompaniesToMerge[0].h_UltimateParentCompany = this.companiesToMerge[i].h_UltimateParentCompany;
            this.finalCompaniesToMerge[0].h_Tradestyle = this.companiesToMerge[i].h_Tradestyle;
            this.finalCompaniesToMerge[0].h_D_U_N_S_Number = this.companiesToMerge[i].h_D_U_N_S_Number;
            this.finalCompaniesToMerge[0].h_EntityType = this.companiesToMerge[i].h_EntityType;
            this.finalCompaniesToMerge[0].h_ImportDate = this.companiesToMerge[i].h_ImportDate;
            this.finalCompaniesToMerge[0].h_UltimateParentCountryRegion = this.companiesToMerge[i].h_UltimateParentCountryRegion;
            this.finalCompaniesToMerge[0].verifiedDate = this.companiesToMerge[i].verifiedDate;
          }
        }
        break;
      }case 'CompanyId': {
        this.mergedCompanies[0].companyId = content;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.companiesToMerge.length; i++) {
          if (this.companiesToMerge[i].companyId === content) {
            this.mergedCompanies[0].winPurePrimK = this.companiesToMerge[i].winPurePrimK;
            this.finalCompaniesToMerge[0].winPurePrimK = this.companiesToMerge[i].winPurePrimK;
            this.finalCompaniesToMerge[0].ticker = this.companiesToMerge[i].ticker;
            this.finalCompaniesToMerge[0].exchange = this.companiesToMerge[i].exchange;
            this.finalCompaniesToMerge[0].groupId = this.selected_group;
            this.finalCompaniesToMerge[0]['group ID'] = this.selected_group;
            this.finalCompaniesToMerge[0].ownershipTypeDesc = this.companiesToMerge[i].ownershipTypeDesc;
            this.finalCompaniesToMerge[0].fileName = this.companiesToMerge[i].fileName;
            this.finalCompaniesToMerge[0].importId = this.companiesToMerge[i].importId;
            this.finalCompaniesToMerge[0].numberOfEmployees = this.companiesToMerge[i].numberOfEmployees;
            this.finalCompaniesToMerge[0].sicCode = this.companiesToMerge[i].sicCode;
            this.finalCompaniesToMerge[0].naiscCode = this.companiesToMerge[i].naiscCode;
            this.finalCompaniesToMerge[0].businessIndustry = this.companiesToMerge[i].businessIndustry;
            this.finalCompaniesToMerge[0].companyType = this.companiesToMerge[i].companyType;
            this.finalCompaniesToMerge[0].h_IsHeadquarters = this.companiesToMerge[i].h_IsHeadquarters;
            this.finalCompaniesToMerge[0].h_ParentCompany = this.companiesToMerge[i].h_ParentCompany;
            this.finalCompaniesToMerge[0].h_ParentCountry_Region = this.companiesToMerge[i].h_ParentCountry_Region;
            this.finalCompaniesToMerge[0].h_BusinessDescription = this.companiesToMerge[i].h_BusinessDescription;
            this.finalCompaniesToMerge[0].h_UltimateParentCompany = this.companiesToMerge[i].h_UltimateParentCompany;
            this.finalCompaniesToMerge[0].h_Tradestyle = this.companiesToMerge[i].h_Tradestyle;
            this.finalCompaniesToMerge[0].h_D_U_N_S_Number = this.companiesToMerge[i].h_D_U_N_S_Number;
            this.finalCompaniesToMerge[0].h_EntityType = this.companiesToMerge[i].h_EntityType;
            this.finalCompaniesToMerge[0].h_ImportDate = this.companiesToMerge[i].h_ImportDate;
            this.finalCompaniesToMerge[0].h_UltimateParentCountryRegion = this.companiesToMerge[i].h_UltimateParentCountryRegion;
            this.finalCompaniesToMerge[0].verifiedDate = this.companiesToMerge[i].verifiedDate;
          }
        }
        break;
      }
      case 'CompanyName': {
        this.mergedCompanies[0].companyName = content;
        this.finalCompaniesToMerge[0].companyName = content;
        break;
      }
      case 'Address1': {
        this.mergedCompanies[0].address1 = content;
        this.finalCompaniesToMerge[0].address1 = content;
        this.autofillAddressCompany(content);
        break;
      }
      case 'Address2': {
        this.mergedCompanies[0].address2 = content;
        this.finalCompaniesToMerge[0].address2 = content;
        break;
      }
      case 'City': {
        this.mergedCompanies[0].city = content;
        this.finalCompaniesToMerge[0].city = content;
        break;
      }
      case 'State': {
        this.mergedCompanies[0].state = content;
        this.finalCompaniesToMerge[0].state = content;
        break;
      }
      case 'Zip': {
        this.mergedCompanies[0].zip = content;
        this.finalCompaniesToMerge[0].zip = content;
        break;
      }
      case 'Country': {
        this.mergedCompanies[0].country = content;
        this.finalCompaniesToMerge[0].country = content;
        break;
      }
      case 'Ticker Company': {
        this.mergedCompanies[0].ticker = content;
        this.finalCompaniesToMerge[0].ticker = content;
        break;
      }
      case 'Phone': {
        this.mergedCompanies[0].phone = content;
        this.finalCompaniesToMerge[0].phone = content;
        break;
      }
      case 'Website': {
        this.mergedCompanies[0].website = content;
        this.finalCompaniesToMerge[0].website = content;
        break;
      }
      case 'AddressSource': {
        this.mergedCompanies[0].addressSource = content;
        this.finalCompaniesToMerge[0].addressSource = content;
        break;
      }
      case 'AnnualRevenue': {
        this.mergedCompanies[0].annualRevenue = content;
        this.finalCompaniesToMerge[0].annualRevenue = content;
        break;
      }
      case 'CIK ID': {
        this.mergedCompanies[0].cikId = content;
        this.finalCompaniesToMerge[0].cikId = content;
        break;
      }
      case 'SIC Code': {
        this.mergedCompanies[0].sicCode = content;
        this.finalCompaniesToMerge[0].sicCode = content;
        break;
      }
      case 'IPO Date': {
        this.mergedCompanies[0].ipoDate = content;
        this.finalCompaniesToMerge[0].ipoDate = content;
        break;
      }
      case 'Exchange': {
        this.mergedCompanies[0].exchange = content;
        this.finalCompaniesToMerge[0].exchange = content;
        break;
      }
      case 'Employee Exact': {
        this.mergedCompanies[0].numberOfEmployees = content;
        this.finalCompaniesToMerge[0].numberOfEmployees = content;
        break;
      }
      case 'Industry': {
        this.mergedCompanies[0].businessIndustry = content;
        this.finalCompaniesToMerge[0].businessIndustry = content;
        break;
      }
      case 'Ownership': {
        this.mergedCompanies[0].ownershipTypeDesc = content;
        this.finalCompaniesToMerge[0].ownershipTypeDesc = content;
        break;
      }
      case 'Ownership Sub Type': {
        this.mergedCompanies[0].ownershipSubType = content;
        this.finalCompaniesToMerge[0].ownershipSubType = content;
        break;
      }
      case 'HowVerified': {
        this.mergedCompanies[0].howVerified = content;
        this.finalCompaniesToMerge[0].howVerified = content;
        break;
      }
      case 'VerifiedDate': {
        this.mergedCompanies[0].verifiedDate = content;
        this.finalCompaniesToMerge[0].verifiedDate = content;
        break;
      }
      case 'ActiveStatus': {
        this.mergedCompanies[0].activeStatus = content;
        this.finalCompaniesToMerge[0].activeStatus = content;
        break;
      }
    }
  }
  findCountry(country:string): Country{
    return this.countries.find(element => {
      return element.countryCode === country || element.countryName === country;
    });
  }
  findState(state: string, country: string): State{
    const countryFound = this.findCountry(country);
    if(countryFound !== undefined){
      return this.states.find(element => {
        // tslint:disable-next-line:max-line-length
        return (element.stateName === state || element.stateCode === state) && element.countryCodeId === countryFound.countryCodeId
      });
    }
    return undefined;
  }
  autofillAddressCompany(address1: string){
    const company = this.companiesToReview.find(e=> e.address1 === address1)
    this.mergedCompanies[0].address2 = company.address2;
    this.finalCompaniesToMerge[0].address2 = company.address2;
    this.companyControl.controls.address2.setValue(company.address2);

    this.mergedCompanies[0].city = company.city;
    this.finalCompaniesToMerge[0].city = company.city;
    this.companyControl.controls.city.setValue(company.city);

    if(this.companySelection.state.length > 1){
      const state = this.findState(company.state, company.country);
      if(state !== undefined){
        this.mergedCompanies[0].state = state.stateName;
        this.finalCompaniesToMerge[0].state = state.stateName;
        this.companyControl.controls.state.setValue( state.stateName);
      }
    }

    if(this.companySelection.country.length > 1){
      const country = this.findCountry(company.country);
      if(country!==undefined){
        this.mergedCompanies[0].country = country.countryName;
        this.finalCompaniesToMerge[0].country = country.countryName;
        this.companyControl.controls.country.setValue(country.countryName);
      }
    }

    this.mergedCompanies[0].zip = company.zip;
    this.finalCompaniesToMerge[0].zip = company.zip;
    this.companyControl.controls.zip.setValue(company.zip);

    this.mergedCompanies[0].addressSource = company.addressSource;
    this.finalCompaniesToMerge[0].addressSource = company.addressSource;
    this.companyControl.controls.addressSource.setValue(company.addressSource);

  }
  autofillAddressPerson(address1: string){
    const person = this.peopleToReview.find(e=> e.primaryAddress1 === address1);
    console.log(person);
    if(person!==undefined){
      this.mergedPeople[0].primaryAddress2 = person.primaryAddress2;
      this.finalPeopleToMerge[0].primaryAddress2 = person.primaryAddress2;
      this.primaryAddress2Control.setValue(person.primaryAddress2);

      this.mergedPeople[0].primaryCity = person.primaryCity;
      this.finalPeopleToMerge[0].primaryCity = person.primaryCity;
      this.primaryCityControl.setValue(person.primaryCity);

      if(this.selectPerson_primaryCountry.length > 1){
        this.mergedPeople[0].primaryCountry = person.primaryCountry;
        this.finalPeopleToMerge[0].primaryCity = person.primaryCountry;
        this.primaryCountryControl.setValue(person.primaryCountry);
      }

      if(this.selectPerson_primaryState.length > 1){
        const state = this.findState(person.primaryState, person.primaryCountry);
        if(state!== undefined){
          this.mergedPeople[0].primaryState = state.stateName;
          this.finalPeopleToMerge[0].primaryCity = state.stateName;
          this.primaryStateControl.setValue(state.stateName);
        }
      }

      this.mergedPeople[0].primaryZip = person.primaryZip;
      this.finalPeopleToMerge[0].primaryZip = person.primaryZip;
      this.primaryZipControl.setValue(person.primaryZip);

      this.mergedPeople[0].alternateAddressType = person.alternateAddressType;
      this.finalPeopleToMerge[0].alternateAddressType = person.alternateAddressType;
      this.alternateAddressTypeControl.setValue(person.alternateAddressType);

      this.mergedPeople[0].alternateAddressSource = person.alternateAddressSource;
      this.finalPeopleToMerge[0].alternateAddressSource = person.alternateAddressSource;
      this.alternateAddressSourceControl.setValue(person.alternateAddressSource);

      this.mergedPeople[0].alternateAddressPreferred = person.alternateAddressPreferred;
      this.finalPeopleToMerge[0].alternateAddressPreferred = person.alternateAddressPreferred;
      this.alternateAddressPreferredControl.setValue(person.alternateAddressPreferred);
    }

  }
  constructMergedPerson(type: string, event: any):void {
    const content = event.value;
    switch (type){
      case 'WinpurePrimeK': {
        // tslint:disable-next-line:prefer-for-of
        this.mergedPeople[0].winPurePrimK = content;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.peopleToMerge.length; i++){
          if (this.peopleToMerge[i].winPurePrimK === content) {
            this.mergedPeople[0].personId = this.peopleToMerge[i].personId;
            this.finalPeopleToMerge[0].personId = this.peopleToMerge[i].personId;
            this.finalPeopleToMerge[0].ticker = this.peopleToMerge[i].ticker;
            this.finalPeopleToMerge[0]['group ID'] = this.selected_group;
            this.finalPeopleToMerge[0].fileName = this.peopleToMerge[i].fileName;
            this.finalPeopleToMerge[0].importId = this.peopleToMerge[i].importId;
          }
        }
        break;
      }
      case 'PersonId': {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.peopleToMerge.length; i++) {
          if (this.peopleToMerge[i].personId === content) {
            this.mergedPeople[0].winPurePrimK = this.peopleToMerge[i].winPurePrimK;
            this.finalPeopleToMerge[0].winPurePrimK = this.peopleToMerge[i].winPurePrimK;
            this.finalPeopleToMerge[0].ticker = this.peopleToMerge[i].ticker;
            this.finalPeopleToMerge[0]['group ID'] = this.selected_group;
            this.finalPeopleToMerge[0].fileName = this.peopleToMerge[i].fileName;
            this.finalPeopleToMerge[0].importId = this.peopleToMerge[i].importId;
          }
        }
        break;
      }
      case 'FirstName': {
        this.mergedPeople[0].firstName = content;
        this.finalPeopleToMerge[0].firstName = content;
        break;
      }
      case 'MiddleName': {
        this.mergedPeople[0].middleName = content;
        this.finalPeopleToMerge[0].middleName = content;
        break;
      }
      case 'LastName': {
        this.mergedPeople[0].lastName = content;
        this.finalPeopleToMerge[0].lastName = content;
        break;
      }
      case 'Suffix': {
        this.mergedPeople[0].suffix = content;
        this.finalPeopleToMerge[0].suffix = content;
        break;
      }
      case 'Title': {
        this.mergedPeople[0].title = content;
        this.finalPeopleToMerge[0].title = content;
        break;
      }
      case 'Email': {
        this.mergedPeople[0].email = content;
        this.finalPeopleToMerge[0].email = content;
        break;
      }
      case 'AltEmail': {
        this.mergedPeople[0].altEmail = content;
        this.finalPeopleToMerge[0].altEmail = content;
        break;
      }
      case 'DirectPhone': {
        this.mergedPeople[0].directPhone = content;
        this.finalPeopleToMerge[0].directPhone = content;
        break;
      }
      case 'MobilePhone': {
        this.mergedPeople[0].mobilePhone = content;
        this.finalPeopleToMerge[0].mobilePhone = content;
        break;
      }
      case 'PrimaryCompany': {
        this.mergedPeople[0].primaryCompany = content;
        this.finalPeopleToMerge[0].primaryCompany = content;
        break;
      }
      case 'CEXSubStatus': {
        this.mergedPeople[0].cexSubStatus = content;
        this.finalPeopleToMerge[0].cexSubStatus = content;
        break;
      }
      case 'CBMSubStatus': {
        this.mergedPeople[0].cbmSubStatus = content;
        this.finalPeopleToMerge[0].cbmSubStatus = content;
        break;
      }
      case 'PrimaryCompanyId': {
        this.mergedPeople[0].companyId = content;
        this.finalPeopleToMerge[0].companyId = content;
        break;
      }
      case 'BoardCompanyAssoc': {
        this.mergedPeople[0].boardCompanyName = content;
        this.finalPeopleToMerge[0].boardCompanyName = content;
        break;
      }
      case 'Ticker': {
        this.mergedPeople[0].ticker = content;
        this.finalPeopleToMerge[0].ticker = content;
        break;
      }
      case 'PrimaryCity': {
        this.mergedPeople[0].primaryCity = content;
        this.finalPeopleToMerge[0].primaryCity = content;
        break;
      }
      case 'PrimaryState': {
        this.mergedPeople[0].primaryState = content;
        this.finalPeopleToMerge[0].primaryState = content;
        break;
      }
      case 'PrimaryCountry': {
        this.mergedPeople[0].primaryCountry = content;
        this.finalPeopleToMerge[0].primaryCountry = content;
        break;
      }
      case 'PrimaryAddress1': {
        this.mergedPeople[0].primaryAddress1 = content;
        this.finalPeopleToMerge[0].primaryAddress1 = content;
        this.autofillAddressPerson(content);
        break;
      }
      case 'PrimaryAddress2': {
        this.mergedPeople[0].primaryAddress2 = content;
        this.finalPeopleToMerge[0].primaryAddress2 = content;
        break;
      }
      case 'PrimaryZip': {
        this.mergedPeople[0].primaryZip = content;
        this.finalPeopleToMerge[0].primaryZip = content;
        break;
      }
      case 'WordpressId': {
        this.mergedPeople[0].wordpressId = content;
        this.finalPeopleToMerge[0].wordpressId = content;
        break;
      }
      case 'MemberClicksId': {
        this.mergedPeople[0].memberClicksId = content;
        this.finalPeopleToMerge[0].memberClicksId = content;
        break;
      }
      case 'CEXSubscriberCode': {
        this.mergedPeople[0].cexSubscriberCode = content;
        this.finalPeopleToMerge[0].cexSubscriberCode = content;
        break;
      }
      case 'CEODigitalAndPrint': {
        this.mergedPeople[0].ceoDigitalAndPrint = content;
        this.finalPeopleToMerge[0].ceoDigitalAndPrint = content;
        break;
      }
      case 'CEODigitalOnly': {
        this.mergedPeople[0].ceoDigitalOnly = content;
        this.finalPeopleToMerge[0].ceoDigitalOnly = content;
        break;
      }
      case 'CBMDigitalAndPrint': {
        this.mergedPeople[0].cbmDigitalAndPrint = content;
        this.finalPeopleToMerge[0].cbmDigitalAndPrint = content;
        break;
      }
      case 'CBMDigitalOnly': {
        this.mergedPeople[0].cbmDigitalOnly = content;
        this.finalPeopleToMerge[0].cbmDigitalOnly = content;
        break;
      }
      case 'CEN': {
        this.mergedPeople[0].cen = content;
        this.finalPeopleToMerge[0].cen = content;
        break;
      }
      case 'SEN': {
        this.mergedPeople[0].sen = content;
        this.finalPeopleToMerge[0].sen = content;
        break;
      }
      case 'CEO100': {
        this.mergedPeople[0].ceO100 = content;
        this.finalPeopleToMerge[0].ceO100 = content;
        break;
      }
      case 'CFO100': {
        this.mergedPeople[0].cfO100 = content;
        this.finalPeopleToMerge[0].cfO100 = content;
        break;
      }
      case 'NLL': {
        this.mergedPeople[0].nextLevelLeaders = content;
        this.finalPeopleToMerge[0].nextLevelLeaders = content;
        break;
      }
      case 'CEBI': {
        this.mergedPeople[0].cebi = content;
        this.finalPeopleToMerge[0].cebi = content;
        break;
      }
      case 'Revenue': {
        this.mergedPeople[0].revenue = content;
        this.finalPeopleToMerge[0].revenue = content;
        break;
      }
      case 'Ownership': {
        this.mergedPeople[0].ownership = content;
        this.finalPeopleToMerge[0].ownership = content;
        break;
      }
      case 'OwnershipSubType': {
        this.mergedPeople[0].ownershipSubType = content;
        this.finalPeopleToMerge[0].ownershipSubType = content;
        break;
      }
      case 'Employees': {
        this.mergedPeople[0].employees = content;
        this.finalPeopleToMerge[0].employees = content;
        break;
      }
      case 'Industry': {
        this.mergedPeople[0].industry = content;
        this.finalPeopleToMerge[0].industry = content;
        break;
      }
      case 'OtherIndustry': {
        this.mergedPeople[0].otherIndustry = content;
        this.finalPeopleToMerge[0].otherIndustry = content;
        break;
      }
      case 'BPAQualDate': {
        this.mergedPeople[0].bpaQualDate = content;
        this.finalPeopleToMerge[0].bpaQualDate = content;
        break;
      }
      case 'BPAQualSource': {
        this.mergedPeople[0].qualificationSource = content;
        this.finalPeopleToMerge[0].qualificationSource = content;
        break;
      }
      case 'BPAJobTitle': {
        this.mergedPeople[0].bpaJobTitle = content;
        this.finalPeopleToMerge[0].bpaJobTitle = content;
        break;
      }
      case 'VerifiedDate': {
        this.mergedPeople[0].verifiedDate = content;
        this.finalPeopleToMerge[0].verifiedDate = content;
        break;
      }
      case 'ActiveStatus': {
        this.mergedPeople[0].activeStatus = content;
        this.finalPeopleToMerge[0].activeStatus = content;
        break;
      }
      case 'AlternateAddressSource': {
        this.mergedPeople[0].alternateAddressSource = content;
        this.finalPeopleToMerge[0].alternateAddressSource = content;
        break;
      }
      case 'AlternateAddressPreferred': {
        this.mergedPeople[0].alternateAddressPreferred = content;
        this.finalPeopleToMerge[0].alternateAddressPreferred = content;
        break;
      }
      case 'AlternateAddressType': {
        this.mergedPeople[0].alternateAddressType = content;
        this.finalPeopleToMerge[0].alternateAddressType = content;
        break;
      }
    }
  }
  postEditMatch(): void{
    if (this.selectedDataType === 'Company'){
      for (const i of this.companiesToMerge){
        if (i.winPurePrimK !== this.finalCompaniesToMerge[0].winPurePrimK){
          this.mergedCompanies.push({
            actionType: 'Edit',
            address1: '',
            address2: '',
            annualRevenue: 0,
            businessIndustry: '',
            city: '',
            companyId: i.companyId,
            companyName: '',
            companyType: '',
            country: '',
            exchange: '',
            fileName: '',
            fileRowId: 0,
            groupId: this.selected_group,
            h_BusinessDescription: '',
            h_D_U_N_S_Number: '',
            h_EntityType: '',
            h_ImportDate: '',
            h_IsHeadquarters: false,
            h_ParentCompany: '',
            h_ParentCountry_Region: '',
            h_Tradestyle: '',
            h_UltimateParentCompany: '',
            h_UltimateParentCountryRegion: '',
            id: 0,
            importId: '',
            lastUpdated: '',
            naiscCode: 0,
            numberOfEmployees: 0,
            ownershipTypeDesc: '',
            ownershipSubType: '',
            phone: '',
            sicCode: 0,
            state: null,
            ticker: '',
            website: '',
            addressSource: '',
            winPurePrimK: i.winPurePrimK,
            zip: '',
            activeStatus: false,
            verifiedDate: '',
            howVerified: '',
            'group ID': this.selected_group,
            cikId : null,
            ipoDate: ''
          });
        }
        else{
          this.mergedCompanies.push({
            actionType: 'Edit',
            address1: i.address1,
            address2: i.address2,
            annualRevenue: i.annualRevenue,
            businessIndustry: i.businessIndustry,
            city: i.city,
            companyId: i.companyId,
            companyName: i.companyName,
            companyType: i.companyType,
            country: i.country,
            exchange: i.exchange,
            fileName: i.fileName,
            fileRowId: 0,
            groupId: this.selected_group,
            h_BusinessDescription: i.h_BusinessDescription,
            h_D_U_N_S_Number: i.h_D_U_N_S_Number,
            h_EntityType: i.h_EntityType,
            h_ImportDate: i.h_ImportDate,
            h_IsHeadquarters: i.h_IsHeadquarters,
            h_ParentCompany: i.h_ParentCompany,
            h_ParentCountry_Region: i.h_ParentCountry_Region,
            h_Tradestyle: i.h_Tradestyle,
            h_UltimateParentCompany: i.h_UltimateParentCompany,
            h_UltimateParentCountryRegion: i.h_UltimateParentCountryRegion,
            id: i.id,
            importId: i.importId,
            lastUpdated: i.lastUpdated,
            naiscCode: i.naiscCode,
            numberOfEmployees: i.numberOfEmployees,
            ownershipTypeDesc: i.ownershipTypeDesc,
            ownershipSubType: i.ownershipSubType,
            phone: i.phone,
            sicCode: i.sicCode,
            state: i.state,
            ticker: i.ticker,
            website: i.website,
            addressSource: i.addressSource,
            winPurePrimK: i.winPurePrimK,
            zip: i.zip,
            activeStatus: i.activeStatus,
            verifiedDate: i.verifiedDate,
            howVerified: i.howVerified,
            'group ID': this.selected_group,
            cikId: i.cikId,
            ipoDate: i.ipoDate
          });
        }
      }
      this.mergedCompanies.splice(0, 1);
    }
    else if (this.selectedDataType === 'Person'){
      for (const i of this.peopleToMerge){
        if (i.winPurePrimK !== this.finalPeopleToMerge[0].winPurePrimK){
          this.mergedPeople.push({
            'source name': i['source name'],
            actionType: 'Edit',
            companyId: 0,
            email: '',
            fileName: '',
            fileRowId: 0,
            firstName: '',
            groupId: this.selected_group,
            id: 0,
            importId: '',
            lastName: '',
            lastUpdated: '',
            middleName: '',
            mobilePhone: '',
            personId: i.personId,
            prefix: '',
            primaryAddress1: '',
            primaryAddress2: '',
            primaryCity: '',
            primaryCountry: '',
            alternateAddressSource: '',
            alternateAddressPreferred: 0,
            alternateAddressType: '',
            wordpressId: 0,
            cexSubscriberCode: '',
            cexSubStatus: '',
            cbmSubStatus: '',
            ceoDigitalAndPrint: false,
            cbmDigitalAndPrint: false,
            ceoDigitalOnly: false,
            cbmDigitalOnly: false,
            bpaQualDate: '',
            ownership: '',
            ownershipSubType: '',
            revenue: '',
            industry: '',
            otherIndustry: '',
            employees: '',
            primaryState: '',
            primaryZip: '',
            suffix: '',
            title: '',
            winPurePrimK: i.winPurePrimK,
            ticker: i.ticker,
            primaryCompany: '',
            directPhone: '',
            altEmail: '',
            activeStatus: false,
            boardCompanyName: '',
            verifiedDate: '',
            memberClicksId: i.memberClicksId,
            qualificationSource: '',
            bpaJobTitle: '',
            memberStatus: '',
            cen: false,
            sen: false,
            ceO100: false,
            cfO100: false,
            nextLevelLeaders: false,
            cebi: false,
          });
        }
        else{
          this.mergedPeople.push({
            'source name': i['source name'],
            actionType: 'Edit',
            companyId: i.companyId,
            email: i.email,
            fileName: '',
            fileRowId: 0,
            firstName: i.firstName,
            groupId: this.selected_group,
            id: 0,
            importId: '',
            lastName: i.lastName,
            lastUpdated: i.lastUpdated,
            middleName: i.middleName,
            mobilePhone: i.mobilePhone,
            personId: i.personId,
            prefix: i.prefix,
            primaryAddress1: i.primaryAddress1,
            primaryAddress2: i.primaryAddress2,
            primaryCity: i.primaryCity,
            primaryCountry: i.primaryCountry,
            primaryState: i.primaryState,
            primaryZip: i.primaryZip,
            alternateAddressSource: i.alternateAddressSource,
            alternateAddressPreferred: i.alternateAddressPreferred,
            cexSubscriberCode: i.cexSubscriberCode,
            wordpressId: i.wordpressId,
            cbmDigitalAndPrint: i.cbmDigitalAndPrint,
            cexSubStatus: i.cexSubStatus,
            cbmSubStatus: i.cbmSubStatus,
            ceoDigitalAndPrint: i.ceoDigitalAndPrint,
            cbmDigitalOnly: i.ceoDigitalOnly,
            ceoDigitalOnly: i.cbmDigitalOnly,
            bpaQualDate: i.bpaQualDate,
            industry: i.industry,
            otherIndustry: i.otherIndustry,
            employees: i.employees,
            ownership: i.ownership,
            ownershipSubType: i.ownershipSubType,
            revenue: i.revenue,
            alternateAddressType: i.alternateAddressType,
            suffix: i.suffix,
            title: i.title,
            winPurePrimK: i.winPurePrimK,
            ticker: i.ticker,
            primaryCompany: i.primaryCompany,
            directPhone: i.directPhone,
            altEmail: i.altEmail,
            activeStatus: false,
            boardCompanyName: i.boardCompanyName,
            verifiedDate: i.verifiedDate,
            memberClicksId: i.memberClicksId,
            qualificationSource: i.qualificationSource,
            bpaJobTitle: i.bpaJobTitle,
            memberStatus: i.memberStatus,
            cen: i.cen,
            sen: i.sen,
            cebi: i.cebi,
            nextLevelLeaders: i.nextLevelLeaders,
            ceO100: i.ceO100,
            cfO100: i.cfO100
          });
        }
      }
      this.mergedPeople.splice(0, 1);
    }
    let jsonToSend = '{ "mergeOption": "' + 'UpdateAllField' + '", ';
    let url = '';
    this.teamMemberId = this.users.find(x => x.email === this.user).teamMemberId;
    switch (this.selectedDataType) {
      case 'Company': {
        const data2 = {verifiedBy: this.teamMemberId};
        for (let i = 0; i < this.mergedCompanies.length; i++) {
          this.mergedCompanies[i] = Object.assign(this.mergedCompanies[i], data2);
        }
        jsonToSend += '"companyGroup":' + JSON.stringify(this.mergedCompanies) + '}';
        if (this.urlType === 1) {
          url = this.api.url_post_ReviewProcessGroup('company');
        } else if (this.urlType === 2) {
          url = this.api.url_post_QuarantineProcessGroup('company', '');
        } else if (this.urlType === 3) {
          url = this.api.url_post_ResearchProcessGroup('company');
        }
        break;
      }
      case 'Person': {
        const data2 = {verifiedBy: this.teamMemberId};
        console.log('user', this.teamMemberId);
        for (let i = 0; i < this.mergedPeople.length; i++) {
          this.mergedPeople[i] = Object.assign(this.mergedPeople[i], data2);
        }
        jsonToSend += '"personGroup":' + JSON.stringify(this.mergedPeople) + '}';
        if (this.urlType === 1) {
          url = this.api.url_post_ReviewProcessGroup('person');
        } else if (this.urlType === 2) {
          url = this.api.url_post_QuarantineProcessGroup('person', '');
        } else if (this.urlType === 3) {
          url = this.api.url_post_ResearchProcessGroup('person');
        }
        break;
      }
    }
    // console.log('sent', jsonToSend );
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.setRequestHeader('accept', '*/*');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          document.getElementById('addToMainDatabase').click();
          // this.postAddToMainDataBase();
        }
        else{
          document.getElementById('merge_btn').click();
        }
      }
    };
    xhr.send(jsonToSend);
  }

  addNewID(companyId: string): void{
    console.log('event', companyId);
    if (companyId !== ''){
      const Id = {value: companyId};
      this.selectPerson_companyId.push(Id);
    }
  }

   checkApiProfiles(): void{
    // if exists at least one record from WordPress and has the action merge
    const wpProfile = this.peopleToMerge.filter(x => x.actionType === 'Merge' && x.fileName === 'WordPress');
    console.log(this.peopleToMerge);
    if (wpProfile !== undefined && wpProfile.length > 0){
      console.log(wpProfile);
      this.processProfiles().then(response => {
        console.log(response);
        this.WriteWordPressId(response).then( () => {
          console.log('continue merge THEN from checkWordpressProfile');
          this.postEditStage();
        });
      });
    }
    else{
      console.log('continue check from MemberClicks profiles');
      const mbProfile = this.peopleToMerge.find(x => x.actionType === 'Merge' && x.fileName === 'MemberClicks' && x.personId === 0);
      console.log(mbProfile);
      if (mbProfile !== undefined){
        const ceoId = this.finalPeopleToMerge[0].personId;
        this.api.writeCeoIdInMemberClicks(mbProfile.memberClicksId, ceoId, this.token).then(response => {
          if (response.status === 200){
            this.modificationProceeded('CeoId ' + ceoId + ' successful written to the MemberClicks profile ' + mbProfile.memberClicksId );
          }
          else{
            this.snackbar_Error('CeoId was not written to the MemberClicks profile');
          }
          this.postEditStage();
        });
      }
      else{
          this.postEditStage();
      }
    }
  }
  async processProfiles(): Promise<number[]>{
    const ceoId = this.finalPeopleToMerge[0].personId;
    const wpIdToWrite: number[] = [];
    for (const profile of this.peopleToMerge){
      console.log(profile.personId);
      if (profile.fileName === 'WordPress' && profile.personId !== ceoId){
        if (!wpIdToWrite.includes(profile.wordpressId)){
          console.log('need to write wp id', profile.wordpressId, ceoId);
          wpIdToWrite.push(profile.wordpressId);
        }
      }
      if (profile['source name'] === 'Person' && profile.wordpressId != null){
        if (!wpIdToWrite.includes(profile.wordpressId)){
          console.log('need to write wp id form the ceo', profile.wordpressId, ceoId);
          wpIdToWrite.push(profile.wordpressId);
        }
      }
    }
    return wpIdToWrite;
  }
  async WriteWordPressId(wordPressId: number[]): Promise<string>{
       const userId = this.users.find(x => x.email === this.user).id;
       const ceoId  = this.finalPeopleToMerge[0].personId;
       wordPressId.forEach(wp => {
         this.api.writeCeoIdInWordpress(wp, ceoId, userId, this.token).then(response => {
             if (response.status === 200){
               this.modificationProceeded('CeoId ' + ceoId + ' successful written to the WP profile ' + wp );
             }
             else{
               this.snackbar_Error('CeoId was not written to the WP profile');
             }
           });
       });
       return 'done';
  }
  postEditStage(): void{
    if (this.selectedDataType === 'Company'){
      for (const i of this.companiesToMerge){
        if (i.winPurePrimK !== this.finalCompaniesToMerge[0].winPurePrimK){
          this.mergedCompanies.push(i);
          this.mergedCompanies[this.mergedCompanies.length - 1].actionType = 'ManualMerge';

        }
        else{
          this.mergedCompanies.push(this.finalCompaniesToMerge[0]);
          this.mergedCompanies[this.mergedCompanies.length - 1].actionType = 'AddToMainDatabase';
        }
      }
      this.mergedCompanies.splice(0, 1);
    }
    else if (this.selectedDataType === 'Person'){
      for (const i of this.peopleToMerge){
        if (i.winPurePrimK !== this.finalPeopleToMerge[0].winPurePrimK){
          this.mergedPeople.push(i);
          this.mergedPeople[this.mergedPeople.length - 1].actionType = 'ManualMerge';
        }
        else{
          this.mergedPeople.push(this.finalPeopleToMerge[0]);
          this.mergedPeople[this.mergedPeople.length - 1].actionType = 'AddToMainDatabase';
        }
      }
      this.mergedPeople.splice(0, 1);
    }
    console.log('posteditStage1');
    let jsonToSend = '{ "mergeOption": "' + 'UpdateAllField' + '", ';
    let url = '';
    this.teamMemberId = this.users.find(x => x.email === this.user).teamMemberId;
    switch (this.selectedDataType) {
      case 'Company': {
        if (this.isPublicDataCompanyMatches === false){
          const data2 = {verifiedBy : this.teamMemberId};
          for (let i = 0; i < this.mergedCompanies.length; i++) {
            this.mergedCompanies[i] = Object.assign(this.mergedCompanies[i], data2);
          }
        }
        jsonToSend += '"companyGroup":' + JSON.stringify(this.mergedCompanies) + '}';
        if (this.urlType === 1){
          url = this.api.url_post_ReviewProcessGroup('company');
        }
        else if (this.urlType === 2){
          url = this.api.url_post_QuarantineProcessGroup('company', '');
        }
        else if (this.urlType === 3){
          url = this.api.url_post_ResearchProcessGroup('company');
        }
        break;
      }
      case 'Person': {
        const data2 = {verifiedBy : this.teamMemberId};
        for (let i = 0; i < this.mergedPeople.length; i++) {
          this.mergedPeople[i] = Object.assign(this.mergedPeople[i], data2);
        }
        jsonToSend += '"personGroup":' + JSON.stringify(this.mergedPeople) + '}';
        if (this.urlType === 1){
          url = this.api.url_post_ReviewProcessGroup('person');
        }
        else if (this.urlType === 2){
          url = this.api.url_post_QuarantineProcessGroup('person', '');
        }
        else if (this.urlType === 3){
          url = this.api.url_post_ResearchProcessGroup('person');
        }
        break;
      }
    }
    console.log('sent', jsonToSend );
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.setRequestHeader('accept', '*/*');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          document.getElementById('merge_succeeded_snack').click();
          document.getElementById('merge_done_button').style.display = 'block';
        }
        else{
          document.getElementById('merge_failed_snack').click();
        }
      }
    };
    xhr.send(jsonToSend);
  }
  // tslint:disable-next-line:typedef
  postEdit(){
    if (this.parent_page === 'Match'){
      this.postEditMatch();
    }else if (this.parent_page){
      this.proceedMerge();
      // uncomment this in case of revert
      // this.postEditStage();
    }
  }
  proceedMerge(): void{
    if (this.selectedDataType === 'Person' && this.isPersonApiData === true){
      this.checkApiProfiles();
    }
    else{
      console.log('continue merge');
      this.postEditStage();
    }

  }
  // tslint:disable-next-line:typedef
  postAddToMainDataBase() {
    if (this.selectedDataType === 'Company') {
      this.finalCompaniesToMerge[0].actionType = 'AddToMainDatabase';
    }
    else if (this.selectedDataType === 'Person') {
      this.finalPeopleToMerge[0].actionType = 'AddToMainDatabase';
    }
    this.updateMergeForAll();
  }
  // tslint:disable-next-line:typedef
  updateMergeForAll(){
    if (this.selectedDataType === 'Company'){
      for (const i of this.mergedCompanies){
        if (i.winPurePrimK !== this.finalCompaniesToMerge[0].winPurePrimK){
          if ( i.state ) {
            this.finalCompaniesToMerge.push({
              id: i.id,
              importId: i.importId,
              fileName: i.fileName,
              fileRowId: i.fileRowId,
              companyId: i.companyId,
              companyName: i.companyName,
              address1: i.address1,
              address2: i.address2,
              city: i.city,
              state: i.state,
              zip: i.zip,
              country: i.country,
              phone: i.phone,
              website: i.website,
              addressSource: i.addressSource,
              companyType: i.companyType,
              lastUpdated: i.lastUpdated,
              ticker: i.ticker,
              exchange: i.exchange,
              sicCode: i.sicCode,
              naiscCode: i.naiscCode,
              numberOfEmployees: i.numberOfEmployees,
              annualRevenue: i.annualRevenue,
              ownershipTypeDesc: i.ownershipTypeDesc,
              ownershipSubType: i.ownershipSubType,
              businessIndustry: i.businessIndustry,
              h_D_U_N_S_Number: i.h_D_U_N_S_Number,
              h_Tradestyle: i.h_Tradestyle,
              h_EntityType: i.h_EntityType,
              h_IsHeadquarters: i.h_IsHeadquarters,
              h_BusinessDescription: i.h_BusinessDescription,
              h_ParentCompany: i.h_ParentCompany,
              h_ParentCountry_Region: i.h_ParentCountry_Region,
              h_UltimateParentCompany: i.h_UltimateParentCompany,
              h_UltimateParentCountryRegion: i.h_UltimateParentCountryRegion,
              h_ImportDate: i.h_ImportDate,
              actionType: 'Merge',
              winPurePrimK: i.winPurePrimK,
              groupId: i.groupId,
              activeStatus: i.activeStatus,
              verifiedDate: i.verifiedDate,
              howVerified: i.howVerified,
              'group ID': this.selected_group,
              cikId: i.cikId,
              ipoDate: i.ipoDate
            });
          }else {
            this.finalCompaniesToMerge.push({
              id: i.id,
              importId: i.importId,
              fileName: i.fileName,
              fileRowId: i.fileRowId,
              companyId: i.companyId,
              companyName: i.companyName,
              address1: i.address1,
              address2: i.address2,
              city: i.city,
              state: null,
              zip: i.zip,
              country: i.country,
              phone: i.phone,
              website: i.website,
              addressSource: i.addressSource,
              companyType: i.companyType,
              lastUpdated: i.lastUpdated,
              ticker: i.ticker,
              exchange: i.exchange,
              sicCode: i.sicCode,
              naiscCode: i.naiscCode,
              numberOfEmployees: i.numberOfEmployees,
              annualRevenue: i.annualRevenue,
              ownershipTypeDesc: i.ownershipTypeDesc,
              ownershipSubType: i.ownershipSubType,
              businessIndustry: i.businessIndustry,
              h_D_U_N_S_Number: i.h_D_U_N_S_Number,
              h_Tradestyle: i.h_Tradestyle,
              h_EntityType: i.h_EntityType,
              h_IsHeadquarters: i.h_IsHeadquarters,
              h_BusinessDescription: i.h_BusinessDescription,
              h_ParentCompany: i.h_ParentCompany,
              h_ParentCountry_Region: i.h_ParentCountry_Region,
              h_UltimateParentCompany: i.h_UltimateParentCompany,
              h_UltimateParentCountryRegion: i.h_UltimateParentCountryRegion,
              h_ImportDate: i.h_ImportDate,
              actionType: 'Merge',
              winPurePrimK: i.winPurePrimK,
              groupId: i.groupId,
              activeStatus: i.activeStatus,
              verifiedDate: i.verifiedDate,
              howVerified: i.howVerified,
              'group ID': this.selected_group,
              cikId: i.cikId,
              ipoDate: i.ipoDate
            });
          }
        }
      }
    }
    else if (this.selectedDataType === 'Person'){
      for (const i of this.mergedPeople){
        if (i.winPurePrimK !== this.finalPeopleToMerge[0].winPurePrimK){
          this.finalPeopleToMerge.push({
            'group 1 FirstName score': 0,
            'group 1 LastName score': 0,
            'group 1 score': 0,
            'group ID': this.selected_group,
            'source name': i['source name'],
            'total score': i['total score'],
            actionType: 'Merge',
            companyId: i.companyId,
            email: i.email,
            firstName: i.firstName,
            lastName: i.lastName,
            lastUpdated: i.lastUpdated,
            middleName: i.middleName,
            personId: i.personId,
            prefix: i.prefix,
            primaryAddress1: i.primaryAddress1,
            primaryAddress2: i.primaryAddress2,
            primaryCity: i.primaryCity,
            primaryCountry: i.primaryCountry,
            primaryState: i.primaryState,
            primaryZip: i.primaryZip,
            alternateAddressSource: i.alternateAddressSource,
            alternateAddressPreferred: i.alternateAddressPreferred,
            alternateAddressType: i.alternateAddressType,
            cexSubscriberCode: i.cexSubscriberCode,
            wordpressId: i.wordpressId,
            cexSubStatus: i.cexSubStatus,
            cbmSubStatus: i.cbmSubStatus,
            cbmDigitalAndPrint: i.cbmDigitalAndPrint,
            ceoDigitalAndPrint: i.ceoDigitalAndPrint,
            cbmDigitalOnly: i.ceoDigitalOnly,
            ceoDigitalOnly: i.cbmDigitalOnly,
            bpaQualDate: i.bpaQualDate,
            industry: i.industry,
            otherIndustry: i.otherIndustry,
            employees: i.employees,
            ownership: i.ownership,
            ownershipSubType: i.ownershipSubType,
            revenue: i.revenue,
            title: i.title,
            winPurePrimK: i.winPurePrimK,
            ticker: i.ticker,
            primaryCompany: i.primaryCompany,
            suffix: i.suffix,
            directPhone: i.directPhone,
            altEmail: i.altEmail,
            activeStatus: i.activeStatus,
            mobilePhone: i.mobilePhone,
            boardCompanyName: i.boardCompanyName,
            verifiedDate: i.verifiedDate,
            fileName: i.fileName,
            importId: i.importId,
            id: i.id,
            fileRowId: i.fileRowId,
            groupId: i.groupId,
            memberClicksId: i.memberClicksId,
            qualificationSource: i.qualificationSource,
            bpaJobTitle: i.bpaJobTitle,
            memberStatus: i.memberStatus,
            cen: i.cen,
            sen: i.sen,
            cebi: i.cebi,
            nextLevelLeaders: i.nextLevelLeaders,
            ceO100: i.ceO100,
            cfO100: i.cfO100
          });
        }
      }
    }
    let jsonToSend = '{ "mergeOption": "' + 'UpdateEmptyField' + '", ';
    let url = '';
    this.teamMemberId = this.users.find(x => x.email === this.user).teamMemberId;
    switch (this.selectedDataType) {
      case 'Company': {
        const data2 = {verifiedBy : this.teamMemberId};
        console.log('user', this.teamMemberId);
        for (let i = 0; i < this.mergedCompanies.length; i++) {
          this.mergedCompanies[i] = Object.assign(this.mergedCompanies[i], data2);
        }
        jsonToSend += '"companyGroup":' + JSON.stringify(this.finalCompaniesToMerge) + '}';
        if (this.urlType === 1){
          url = this.api.url_post_ReviewProcessGroup('company');
        }
        else if (this.urlType === 2){
          url = this.api.url_post_QuarantineProcessGroup('company', '');
        }
        else if (this.urlType === 3){
          url = this.api.url_post_ResearchProcessGroup('company');
        }
        break;
      }
      case 'Person': {
        const data2 = {verifiedBy : this.teamMemberId};
        console.log('user', this.teamMemberId);
        for (let i = 0; i < this.mergedPeople.length; i++) {
          this.mergedPeople[i] = Object.assign(this.mergedPeople[i], data2);
        }
        jsonToSend += '"personGroup":' + JSON.stringify(this.finalPeopleToMerge) + '}';
        if (this.urlType === 1){
          url = this.api.url_post_ReviewProcessGroup('person');
        }
        else if (this.urlType === 2){
          url = this.api.url_post_QuarantineProcessGroup('person', '');
        }
        else if (this.urlType === 3){
          url = this.api.url_post_ResearchProcessGroup('person');
        }
        break;
      }
    }
    console.log('sent', jsonToSend );
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.setRequestHeader('accept', '*/*');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          document.getElementById('merge_succeeded_snack').click();
          document.getElementById('merge_done_button').style.display = 'block';
        }
        else{
          document.getElementById('merge_failed_snack').click();
        }
      }
    };
    xhr.send(jsonToSend);
  }

  verifyImportantFields(): boolean{
    let isOk = false;
    if (this.selectedDataType === 'Company'){
      if (this.finalCompaniesToMerge[0].winPurePrimK === -1){
        this.snackbar_Error('WinpurePrimK is mandatory!');
        document.getElementById('company-select-winpureprimek').style.backgroundColor = '#ffad99';
      }
      if(this.finalCompaniesToMerge[0].companyName === '' || this.finalCompaniesToMerge[0].companyName === null){
        this.snackbar_Error('Company Name is mandatory!');
        document.getElementById('company-select-companyName').style.backgroundColor = '#ffad99';
      }
      else {
        document.getElementById('company-select-winpureprimek').style.backgroundColor = '#f0f0f0';
        document.getElementById('merge_proceed_btn').style.display = 'none';
        document.getElementById('merge_cancel_btn').style.display = 'none';
        isOk = true;
      }
    }
    else if (this.selectedDataType === 'Person'){
      if (this.finalPeopleToMerge[0].winPurePrimK === -1){
        this.snackbar_Error('WinpurePrimK is mandatory!');
        document.getElementById('person-select-winpureprimk').style.backgroundColor = '#ffad99';
      }
      else {
        document.getElementById('person-select-winpureprimk').style.backgroundColor = '#f0f0f0';
        document.getElementById('merge_proceed_btn').style.display = 'none';
        document.getElementById('merge_cancel_btn').style.display = 'none';
        isOk = true;
      }
    }
    return isOk;
  }
  // tslint:disable-next-line:typedef
  show_users(){
    this.api.get_Users().subscribe(res => {
          // @ts-ignore
          this.users = res;
          console.log('users', this.users);
        },
        err => {
          console.log(err);
        });
  }
  Proceed(): void {
    if (this.verifyImportantFields()){
      this.postEdit();
    }
    // this.setMergedConnection();
  }
  // tslint:disable-next-line:typedef
  setMergedConnection(){
    this._mergeservice.update_alreadyMerged(1);
    this.setWasManualMergeAccessed(true);
  }
  // tslint:disable-next-line:typedef
  setWasManualMergeAccessed(state: boolean){
    this._mergeservice.update_manualMergeAccessed(state);
  }

  // tslint:disable-next-line:typedef
  findSelectedCompanyObject(){
    console.log('companiesToReview', this.companiesToReview);
    for (const i of this.companiesToReview){
      if (i.actionType === 'AddToMainDatabase') {
        this.finalCompaniesToMerge[0].winPurePrimK = i.winPurePrimK;
        this.finalCompaniesToMerge[0].companyId = i.companyId;
        this.finalCompaniesToMerge[0].activeStatus = true;
        this.finalCompaniesToMerge[0].sicCode = i.sicCode;
        this.finalCompaniesToMerge[0].businessIndustry = i.businessIndustry;
        this.finalCompaniesToMerge[0].numberOfEmployees = i.numberOfEmployees;
        this.finalCompaniesToMerge[0].exchange = i.exchange;
        this.finalCompaniesToMerge[0].ownershipTypeDesc = i.ownershipTypeDesc;
        this.finalCompaniesToMerge[0].annualRevenue = i.annualRevenue;
        this.finalCompaniesToMerge[0].naiscCode = i.naiscCode;

      }
    }
    const publicCoFilingDate = this.companiesToReview.find(item => item.publicCoFilingDate !== undefined && item.publicCoFilingDate !== null)?.publicCoFilingDate
    if(publicCoFilingDate){
      this.finalCompaniesToMerge[0].publicCoFilingDate = publicCoFilingDate;
    }
  }
  // tslint:disable-next-line:typedef
  findSelectedPersonObject() {
    console.log('this.is_wordpress');
    console.log(this.isPersonApiData);
    if (this.isPersonApiData === false) {
      for (const i of this.peopleToReview) {
        if (i.actionType === 'AddToMainDatabase') {
          this.finalPeopleToMerge[0].winPurePrimK = i.winPurePrimK;
          this.finalPeopleToMerge[0].personId = i.personId;
          this.finalPeopleToMerge[0].bpaQualDate = i.bpaQualDate;
          this.finalPeopleToMerge[0].cbmDigitalAndPrint = i.cbmDigitalAndPrint;
          this.finalPeopleToMerge[0].cbmDigitalOnly = i.cbmDigitalOnly;
          this.finalPeopleToMerge[0].ceoDigitalAndPrint = i.ceoDigitalAndPrint;
          this.finalPeopleToMerge[0].ceoDigitalOnly = i.ceoDigitalOnly;
          this.finalPeopleToMerge[0].wordpressId = i.wordpressId;
          this.finalPeopleToMerge[0].industry = i.industry;
          this.finalPeopleToMerge[0].revenue = i.revenue;
          this.finalPeopleToMerge[0].ownership = i.ownership;
          this.finalPeopleToMerge[0].employees = i.ownership;
          this.finalPeopleToMerge[0].cexSubscriberCode = i.cexSubscriberCode;
          this.finalPeopleToMerge[0].prefix = i.prefix;
          this.finalPeopleToMerge[0].activeStatus = true;
        }
      }
    }
    if (this.isPersonApiData === true) {
      for (const i of this.peopleToReview){
        if (i.actionType === 'AddToMainDatabase') {
          this.finalPeopleToMerge[0].winPurePrimK = i.winPurePrimK;
          this.finalPeopleToMerge[0].personId = i.personId;
          this.finalPeopleToMerge[0].prefix = i.prefix;
          this.finalPeopleToMerge[0].activeStatus = true;
          this.finalPeopleToMerge[0].alternateAddressSource = i.alternateAddressSource;
          this.finalPeopleToMerge[0].alternateAddressPreferred = i.alternateAddressPreferred;
          this.finalPeopleToMerge[0].alternateAddressType = i.alternateAddressType;
          this.finalPeopleToMerge[0].cexSubscriberCode = i.cexSubscriberCode;
        }
      }
    }
  }

  ngOnInit(): void {
    this.api.getAuthToken().subscribe(auth => {
      this.token = auth;
    });
    this.auth.user$.subscribe(
        (profile) => {
          (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
          this.user = profile.name;
          // this.userEmail = 'kjalbert@chiefexecutivegroup.com';
          this.userEmail = profile.email;
        });
    this.show_users();
    if (this.selectedDataType === 'Company'){
      this.findSelectedCompanyObject();
      this.constructCompaniesToMerge();
    }
    else if (this.selectedDataType === 'Person'){
      this.findSelectedPersonObject();
      this.constructPeopleToMerge();
    }
  }
}
