import {Component,  OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {DataType} from '../shared/DataType';
import {ConfigService} from '../config/config.service';
import {Statistics} from '../shared/Statistics';
import {Company} from '../shared/Company';
import {Person} from '../shared/Person';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {ReviewedCompany} from '../shared/ReviewedCompany';
import {ReviewedPerson} from '../shared/ReviewedPerson';
import {CompanyToReview} from '../shared/CompanyToReview';
import {PersonToReview} from '../shared/PersonToReview';
import {MatDialog} from '@angular/material/dialog';
import {MergeOptionsComponent} from '../merge-options/merge-options.component';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MergedConnectionService} from '../service/mergedConnection.service';
import {Jobs} from '../shared/Jobs';
import {AppComponent} from '../app.component';
import {from, interval} from 'rxjs';
import {WarningComponent} from '../warning/warning.component';
import * as XLSX from 'xlsx';
import {AuthService} from '@auth0/auth0-angular';
import {Country} from '../shared/Country';
import {State} from '../shared/State';
export interface DialogData {
  action: string;
  group: string;
}
@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ReviewComponent implements OnInit {

  constructor(private api: ConfigService, public auth: AuthService, private route: Router, public dialog: MatDialog,
              // tslint:disable-next-line:variable-name max-line-length
              private snackBar: MatSnackBar,  private _mergeservice: MergedConnectionService, private http: HttpClient,
              private appcomponent: AppComponent)
  {
    this.appcomponent.jobChecking();
    route.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }
  // auth0 data
  token: string;
  user: string;
  userEmail: string;
  userRole: string;
  isAdmin: boolean;
  profileJson: string = null;
  public showOverlay = false;
  datatypes: DataType[];
  selectedDataType;
  statistics: Statistics;
  matchGroups: string;
  notMatched: string;
  totalRecords: number;
  // tslint:disable-next-line:variable-name
  us_country: Country = {id: 75, countryCode: 'US', countryName: 'United States', countryCodeId: 232 };
  // tslint:disable-next-line:variable-name
  group_list: number[] = [];
  companiesToReview: CompanyToReview[] = [];
  mergedCompanies: CompanyToReview[] = [];
  reviewedCompanies: ReviewedCompany[] = [];
  peopleToReview: PersonToReview[] = [{
    'group ID': 0,
    'source name': '',
    'total score': 0,
    'group 1 score': 0,
    'group 1 FirstName score': 0,
    'group 1 LastName score': 0,
    personId: 0,
    prefix: '',
    firstName: '',
    middleName: '',
    lastName: '',
    companyId: 0,
    title: '',
    primaryAddress1: '',
    primaryAddress2: '',
    primaryCity: '',
    primaryState: '',
    primaryZip: '',
    primaryCountry: '',
    alternateAddressSource: '',
    alternateAddressPreferred: 0,
    wordpressId: 0,
    cexSubscriberCode: '',
    cexSubStatus: '',
    cbmSubStatus: '',
    ceoDigitalAndPrint: false,
    cbmDigitalAndPrint: false,
    ceoDigitalOnly: false,
    cbmDigitalOnly: false,
    bpaQualDate: '',
    ownership: '',
    ownershipSubType: '',
    revenue: '',
    industry: '',
    otherIndustry: '',
    employees: '',
    alternateAddressType: '',
    lastUpdated: '',
    email: '',
    actionType: 'empty',
    winPurePrimK: 0,
    ticker: '',
    primaryCompany: '',
    suffix: '',
    directPhone: '',
    altEmail: '',
    activeStatus: false,
    mobilePhone: '',
    boardCompanyName: '',
    verifiedDate: '',
    importId: '',
    fileName: '',
    id: 0,
    fileRowId: 0,
    groupId: 0,
    memberClicksId: 0,
    qualificationSource: '',
    bpaJobTitle: '',
    memberStatus: '',
    cen: false,
    sen: false,
    ceO100: false,
    cfO100: false,
    nextLevelLeaders: false,
    cebi: false,
  }];
  mergedPeople: PersonToReview[] = [{
    'group ID': 0,
    'source name': 'empty',
    'total score': 0,
    'group 1 score': 0,
    'group 1 FirstName score': 0,
    'group 1 LastName score': 0,
    personId: 0,
    prefix: '',
    firstName: '',
    middleName: '',
    lastName: '',
    companyId: 0,
    title: '',
    primaryAddress1: '',
    primaryAddress2: '',
    primaryCity: '',
    primaryState: '',
    primaryZip: '',
    primaryCountry: '',
    alternateAddressSource: '',
    alternateAddressPreferred: 0,
    alternateAddressType: '',
    wordpressId: 0,
    cexSubscriberCode: '',
    cexSubStatus: '',
    cbmSubStatus: '',
    ceoDigitalAndPrint: false,
    cbmDigitalAndPrint: false,
    ceoDigitalOnly: false,
    cbmDigitalOnly: false,
    bpaQualDate: '',
    ownership: '',
    ownershipSubType: '',
    revenue: '',
    industry: '',
    otherIndustry: '',
    employees: '',
    lastUpdated: '',
    email: '',
    actionType: 'None',
    winPurePrimK: 0,
    ticker: '',
    primaryCompany: '',
    suffix: '',
    directPhone: '',
    altEmail: '',
    activeStatus: false,
    mobilePhone: '',
    boardCompanyName: '',
    verifiedDate: '',
    importId: '',
    fileName: '',
    id: 0,
    fileRowId: 0,
    groupId: 0,
    memberClicksId: 0,
    qualificationSource: '',
    bpaJobTitle: '',
    memberStatus: '',
    cen: false,
    sen: false,
    ceO100: false,
    cfO100: false,
    nextLevelLeaders: false,
    cebi: false,
  }];
  reviewedPeople: ReviewedPerson[] = [{
    'source name': '',
    id: 0,
    importId: '',
    fileName: '',
    fileRowId: 0,
    personId: 0,
    prefix: '',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    companyId: 0,
    title: '',
    primaryAddress1: '',
    primaryAddress2: '',
    primaryCity: '',
    primaryState: '',
    primaryZip: '',
    primaryCountry: '',
    alternateAddressSource: '',
    alternateAddressPreferred: 0,
    alternateAddressType: '',
    wordpressId: 0,
    cexSubscriberCode: '',
    cexSubStatus: '',
    cbmSubStatus: '',
    ceoDigitalAndPrint: false,
    cbmDigitalAndPrint: false,
    ceoDigitalOnly: false,
    cbmDigitalOnly: false,
    bpaQualDate: '',
    ownership: '',
    ownershipSubType: '',
    revenue: '',
    industry: '',
    otherIndustry: '',
    employees: '',
    mobilePhone: '',
    lastUpdated: '',
    email: '',
    actionType: 'None',
    winPurePrimK: 0,
    groupId: 0,
    ticker: '',
    primaryCompany: '',
    directPhone: '',
    altEmail: '',
    activeStatus: false,
    boardCompanyName: '',
    verifiedDate: '',
    memberClicksId: 0,
    qualificationSource: '',
    bpaJobTitle: '',
    memberStatus: '',
    cen: false,
    sen: false,
    ceO100: false,
    cfO100: false,
    nextLevelLeaders: false,
    cebi: false,
  }];
  // tslint:disable-next-line:variable-name
  is_company_search = false;
  // tslint:disable-next-line:variable-name
  is_person_search = false;
  // tslint:disable-next-line:variable-name
  is_data_available = false;
  // tslint:disable-next-line:variable-name
  group_list_length;
  // tslint:disable-next-line:variable-name
  group_list_elements = [];
  // tslint:disable-next-line:variable-name
  is_totalScore_clicked = false;
  // tslint:disable-next-line:variable-name
  total_score = '-';
  // tslint:disable-next-line:variable-name
  selected_group: string;
  // tslint:disable-next-line:variable-name
  is_datatype_selected = false;
  href = 'https://dev.ceo360.pro/#/';
  dataSource;
  displayedColumns: string[];
  // tslint:disable-next-line:variable-name
  total_searches = 0;
  // tslint:disable-next-line:variable-name
  is_search_ready = false;
  // tslint:disable-next-line:variable-name
  are_actionButtons_visible = 0;
  // tslint:disable-next-line:variable-name
  merge_rule = 'UpdateEmptyField';
  // tslint:disable-next-line:variable-name
  are_alreadyMerged = 0;
  // tslint:disable-next-line:variable-name
  was_manual_merge_accessed = false;
  jobs: Jobs[] = [{
    key: '',
    type: 'empty',
    inProcess: false,
    state: ''
  }];
  // tslint:disable-next-line:variable-name
  are_jobsVisible = false;
  // tslint:disable-next-line:variable-name
  is_long_list = false;
  // tslint:disable-next-line:variable-name
  time_last_job_started = '';
  bulkaction = '';
  countries: Country[];
  states: State[];
  // tslint:disable-next-line:variable-name
  active_status = [true, false];
  // tslint:disable-next-line:variable-name
  not_found_state = '';
  // tslint:disable-next-line:variable-name
  not_found_primaryState = '';
  // tslint:disable-next-line:variable-name
  not_found_country = '';
  // tslint:disable-next-line:variable-name
  not_found_activeStatus = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  exportexcel(fileName: string, datatype: string): void
  {
    const element = document.getElementById('excel-table-' + datatype);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, fileName);
  }
  openWariningPopup(): void{
    const dialogRef = this.dialog.open(WarningComponent);
    console.log('bulck action', this.bulkaction);
    dialogRef.componentInstance.action = this.bulkaction;
    dialogRef.componentInstance.isBulckAnction = true;
    dialogRef.componentInstance.group = this.selected_group;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`popup result: ${result}`);
      const response = result;
      if (response === 'yes'){
        this.Proceed();
      }
    });
  }
  // tslint:disable-next-line:typedef
  ShowAllGroups(){
    this.is_long_list = true;
  }
  // tslint:disable-next-line:typedef
  snackbar_SucceededPost() {
    const sb = this.snackBar.open('Your modifications have been successfully proceeded!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedPost() {
    const sb = this.snackBar.open('Your modifications were NOT proceeded!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_SucceededStartReview() {
    const sb = this.snackBar.open('Your review has started!', '', {
      duration: 1000,
      panelClass: ['succeed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_FailedStartReview() {
    const sb = this.snackBar.open('Your review did NOT start!', '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  // tslint:disable-next-line:typedef
  snackbar_Error(errorMessage: string) {
    const sb = this.snackBar.open(errorMessage, '', {
      duration: 1000,
      panelClass: ['failed'],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
  StartCheckingJobs(): void{
    this.appcomponent.jobChecking();
  }
  SetTimeStamp(): void{
    // tslint:disable-next-line:new-parens
    const date = new Date;
    localStorage.setItem('date', date.toString());
  }
  GetTimeStamp(): void{
    // tslint:disable-next-line:new-parens
    const date = new Date;
    this.time_last_job_started = localStorage.getItem('date');
  }
  public getCatalogsData(): Promise<any>{

    return this.http.get(this.api.get_synchronization()).toPromise();
  }
  synchronizationCheck(datatype: string): void{
    this.showOverlay = true;
    const secondsCounter = interval(1000); // 60000
    const subscription = secondsCounter.subscribe(n => {
      console.log('sync');
      const url = this.api.get_JobsURL();
      const data = from(fetch(url));
      this.api.get_Jobs().subscribe(job => {
        // @ts-ignore
        this.jobs = job;
        if (this.jobs.length === 0){
          subscription.unsubscribe();
          console.log('stop sync');
          this.showOverlay = false;
          this.startMatching(datatype);
        }
      });
    });
  }

  startMatching(datatype: string): void{
    this.SetTimeStamp();

    const url = this.api.post_ReviewStart(datatype);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('accept', '*/*');
    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        console.log(xhr.status);
        console.log(xhr.responseText);
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          document.getElementById('succeeded_review').click();
          document.getElementById('hidden-company').style.display = 'block';
          document.getElementById('hidden-person').style.display = 'block';
          document.getElementById('hidden-p').style.display = 'block';
          document.getElementById('start_checking_jobs').click();
        }
        else{
          document.getElementById('failed_review').click();
        }
      }
    };
    xhr.send();
  }
  // tslint:disable-next-line:typedef
  startReview(datatype: string){
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          console.log(res);
          console.log(datatype);
          if (this.jobs.length > 1 ){
            this.snackbar_Error('Too many jobs are running now. Please try again later');
            console.log('Too many jobs are running now. Please try again later');
          }
          else{
            console.log('0 jobs');
            console.log(this.selectedDataType);
            console.log(this.jobs.length);
            if (this.jobs.length === 1 && this.jobs[0].type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              console.log('a1');
              console.log(this.jobs[0].type);
              this.synchronizationCheck(datatype);
            }
            else{
              console.log('a2');
              console.log(this.selectedDataType);
              const url = this.api.get_synchronization();
              console.log(url);
              const xhr = new XMLHttpRequest();
              xhr.open('POST', url);
              xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
              xhr.setRequestHeader('accept', '*/*');
              console.log(this.selectedDataType);
              if (datatype === 'Company'){
                console.log('company');
                // tslint:disable-next-line:typedef only-arrow-functions
                xhr.onreadystatechange = function() {
                  console.log(xhr.readyState);
                  if (xhr.readyState === 4) {
                    console.log(xhr.status);
                    console.log(xhr.responseText);
                    if (xhr.status === 200){
                      console.log('status 200');
                      document.getElementById('start-matching-Company').click();
                    }
                  }};
                xhr.send();
              }else if (datatype === 'Person'){
                console.log('person');
                // tslint:disable-next-line:typedef only-arrow-functions
                xhr.onreadystatechange = function() {
                  console.log(xhr.readyState);
                  if (xhr.readyState === 4) {
                    console.log(xhr.status);
                    console.log(xhr.responseText);
                    if (xhr.status === 200){
                      console.log('status 200');
                      document.getElementById('start-matching-Person').click();
                    }
                  }};
                xhr.send();
              }else {
                console.log('a3');
              }
            }
          }
        },
        err => {
          console.log(err);
        });

  }
  proceedSelectedAction(actionType: any, element: any): void{
    // proceed normal
    if (this.are_alreadyMerged === 1){
      if (this.selectedDataType === 'Company'){
        for (const i of this.companiesToReview){
          for (const j of this.mergedCompanies){
            if (i.winPurePrimK === j.winPurePrimK){
              i.actionType = 'None';
              document.getElementById('company-select-' + i.winPurePrimK).style.display = 'none'; // .selectedIndex = '-1';
            }
          }
        }
      }
      else if (this.selectedDataType === 'Person'){
        for (const i of this.peopleToReview){
          for (const j of this.mergedPeople){
            if (i.winPurePrimK === j.winPurePrimK){
              i.actionType = 'None';
              // @ts-ignore
              document.getElementById('person-select-' + i.winPurePrimK).style.display = 'none'; // .selectedIndex = '-1';
            }
          }
        }
      }
    }
    // verify if the merge has been done in the popup
    // tslint:disable-next-line:variable-name
    this._mergeservice.shared_are_componentsAlreadyMerged.subscribe(are_alreadyMerged => {
      this.are_alreadyMerged = are_alreadyMerged;
    });
    console.log('actiontype', actionType);
    switch (actionType) {
      case 'AddToMainDatabase': {
        this.are_actionButtons_visible++;
        break;
      }
      case 'Merge': {
        this.are_actionButtons_visible++;
        break;
      }
      case 'Delete': {
        this.are_actionButtons_visible++;
        break;
      }
      case 'None': {
        this.are_actionButtons_visible--;
        break;
      }
      case 'Edit': {
        this.are_actionButtons_visible++;
        if (this.selectedDataType === 'Company'){
          this.verifyState(element);
          this.verifyCountry(element);
        }
        else if (this.selectedDataType === 'Person'){
          this.verifyPrimaryState(element);
        }
        break;
      }
      case 'Change': {
        this.are_actionButtons_visible++;
        this.are_actionButtons_visible++;
        break;
      }
      case 'Kick': {
        this.are_actionButtons_visible++;
        break;
      }
    }
  }
  // verifyActiveStatus(element: any): void{
  //   this.not_found_activeStatus = '';
  //   if (!this.active_status.includes(element.activeStatus)){
  //     this.not_found_activeStatus = element.activeStatus;
  //   }
  // }
  verifyState(element: any): void{
    this.not_found_state = '';
    if (!this.states.some(e => e.stateName === element.state)){
      this.not_found_state = element.state;
    }
  }
  verifyPrimaryState(element: any): void{
    this.not_found_primaryState = '';
    if (!this.states.some(e => e.stateName === element.primaryState)){
      this.not_found_primaryState = element.primaryState;
    }
  }
  verifyCountry(element: any): void{
    this.not_found_country = '';
    if (!(this.countries.some(e => e.countryName === element.country))) {
      this.not_found_country = element.country;
    }
  }
// tslint:disable-next-line:typedef
  selectedAction(actionType: any, element: any) {
    if (actionType === 'Delete'){
      const dialogRef = this.dialog.open(WarningComponent);
      dialogRef.componentInstance.action = 'DeleteRecord';
      dialogRef.componentInstance.isBulckAnction = false;

      dialogRef.afterClosed().subscribe(result => {
        console.log(`popup result: ${result}`);
        const response = result;
        if (response === 'yes'){
          this.proceedSelectedAction(actionType, element);
        }
        else{
          // action type = none
          if (this.selectedDataType === 'Company'){
            for (const i of this.companiesToReview){
              if (i === element){
                i.actionType = 'None';
              }
            }
          }
          else if (this.selectedDataType === 'Person'){
            for (const i of this.peopleToReview){
              if (i === element){
                i.actionType = 'None';
              }
            }
          }
        }
      });
    }
    else{
      this.proceedSelectedAction(actionType, element);
    }
  }
  // tslint:disable-next-line:typedef
  verifySelectedActions(){
    console.log('companies to review', this.companiesToReview);
    let isOk = false;
    if (this.selectedDataType === 'Company'){
      // tslint:disable-next-line:variable-name
      let select_ct = 0;
      // tslint:disable-next-line:variable-name
      let merge_ct = 0;
      // tslint:disable-next-line:variable-name
      let edit_ct = 0;
      for (const i of this.companiesToReview){
        console.log('action', i.actionType);
        if (i.actionType === 'AddToMainDatabase'){
          select_ct++;
        }
        else if (i.actionType === 'Merge'){
          merge_ct++;
        }
        else if (i.actionType === 'Edit'){
          edit_ct ++;
        }
      }
      if (merge_ct > 0 && select_ct === 0){
        this.snackbar_Error('You need to have one \'Select\' for \'Merge\'');
        isOk = false;
      }
      else if (select_ct > 1){
        this.snackbar_Error('You can only have one \'Select\'');
        isOk = false;
      }
      // else if (edit_ct > 1){
      //   this.snackbar_Error('You can only have one \'Edit\'');
      //   isOk = false;
      // }
      else{
        isOk = true;
      }
    }
    else if (this.selectedDataType === 'Person'){
      // tslint:disable-next-line:variable-name
      let select_ct = 0;
      // tslint:disable-next-line:variable-name
      let merge_ct = 0;
      for (const i of this.peopleToReview){
        if (i.actionType === 'AddToMainDatabase'){
          select_ct++;
        }
        else if (i.actionType === 'Merge'){
          merge_ct++;
        }
      }
      if (merge_ct > 0 && select_ct === 0){
        this.snackbar_Error('You need to have one \'Select\' for \'Merge\'');
        isOk = false;
      }
      else if (select_ct > 1){
        this.snackbar_Error('You can only have one \'Select\'');
        isOk = false;
      }
      else{
        isOk = true;
      }
    }
    return isOk;
  }
  VerifyBulkActions(): void{
    if (this.selectedDataType === 'Company'){
      const jsontest = this.construct_reviewedCompanies();
      const actionType = this.reviewedCompanies[0].actionType;
      // tslint:disable-next-line:variable-name
      let different_actions_ct = 0;
      for (const i of this.reviewedCompanies){
        if (i.actionType === actionType){
          different_actions_ct++;
        }
      }
      if (different_actions_ct === this.companiesToReview.length){
        this.bulkaction = actionType;
        this.openWariningPopup();
      }
      else {
        this.Proceed();
      }
    }
    else if (this.selectedDataType === 'Person'){
      const jsontest = this.construct_reviewedPeople();
      const actionType = this.reviewedPeople[0].actionType;
      console.log('action', actionType);
      // tslint:disable-next-line:variable-name
      let different_actions_ct = 0;
      for (const i of this.reviewedPeople){
        if (i.actionType === actionType){
          different_actions_ct++;
        }
      }
      if (different_actions_ct === this.peopleToReview.length){
        this.bulkaction = actionType;
        this.openWariningPopup();
      }
      else {
        console.log('Proceed');
        this.Proceed();
      }
    }
  }
  continueProceed(): void{
    // tslint:disable-next-line:variable-name
    this._mergeservice.shared_are_componentsAlreadyMerged.subscribe(are_alreadyMerged => {
      this.are_alreadyMerged = are_alreadyMerged;
    });
    if (this.selectedDataType === 'Company'){
      if (this.are_alreadyMerged === 1){
        for (const i of this.mergedCompanies){
          if (i['source name'] === 'empty'){
            this.mergedCompanies.splice(this.mergedCompanies.indexOf(i), 1);
          }
        }
        for (const i of this.companiesToReview){
          for (const j of this.mergedCompanies){
            if (i.winPurePrimK === j.winPurePrimK){
              this.companiesToReview.splice(this.companiesToReview.indexOf(i), 1);
            }
          }
        }
        this.unsetMergedConnection();
      }
      console.log('should exclude from json: ', this.mergedCompanies);
    }
    else if (this.selectedDataType === 'Person'){
      if (this.are_alreadyMerged === 1){
        for (const i of this.mergedPeople){
          if (i['source name'] === 'empty'){
            this.mergedPeople.splice(this.mergedPeople.indexOf(i), 1);
          }
        }
        for (const i of this.peopleToReview){
          for (const j of this.mergedPeople){
            if (i.winPurePrimK === j.winPurePrimK){
              this.peopleToReview.splice(this.peopleToReview.indexOf(i), 1);
            }
          }
        }
        this.unsetMergedConnection();
      }
      console.log('should exclude from json: ', this.mergedPeople);
    }
    if (this.verifySelectedActions()){
      let jsonToSend = '{ "mergeOption": "' + this.merge_rule + '", ';
      let url = '';
      if (this.merge_rule === '') {
        this.snackbar_Error('Please select merge rules to continue!');
        // reset
        this.reviewedCompanies = [];
        this.reviewedPeople = [{
          'source name': '',
          id: 0,
          importId: '',
          fileName: '',
          fileRowId: 0,
          personId: 0,
          prefix: '',
          firstName: '',
          middleName: '',
          lastName: '',
          suffix: '',
          companyId: 0,
          title: '',
          primaryAddress1: '',
          primaryAddress2: '',
          primaryCity: '',
          primaryState: '',
          primaryZip: '',
          primaryCountry: '',
          alternateAddressSource: '',
          alternateAddressPreferred: 0,
          alternateAddressType: '',
          wordpressId: 0,
          cexSubscriberCode: '',
          cexSubStatus: '',
          cbmSubStatus: '',
          ceoDigitalAndPrint: false,
          cbmDigitalAndPrint: false,
          ceoDigitalOnly: false,
          cbmDigitalOnly: false,
          bpaQualDate: '',
          ownership: '',
          ownershipSubType: '',
          revenue: '',
          industry: '',
          otherIndustry: '',
          employees: '',
          mobilePhone: '',
          lastUpdated: '',
          email: '',
          actionType: 'None',
          winPurePrimK: 0,
          groupId: 0,
          ticker: '',
          primaryCompany: '',
          directPhone: '',
          altEmail: '',
          activeStatus: false,
          boardCompanyName: '',
          verifiedDate: '',
          memberClicksId: 0,
          qualificationSource: '',
          bpaJobTitle: '',
          memberStatus: '',
          cen: false,
          sen: false,
          ceO100: false,
          cfO100: false,
          nextLevelLeaders: false,
          cebi: false,
        }];
      }
      else {
        switch (this.selectedDataType) {
          case 'Company': {
            console.log('882', this.companiesToReview);
            jsonToSend += '"companyGroup":' + this.construct_reviewedCompanies() + '}';
            url = this.api.url_post_ReviewProcessGroup('company');
            break;
          }
          case 'Person': {
            jsonToSend += '"personGroup":' + this.construct_reviewedPeople() + '}';
            url = this.api.url_post_ReviewProcessGroup('person');
            break;
          }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
        xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
        xhr.setRequestHeader('accept', '*/*');
        // tslint:disable-next-line:only-arrow-functions typedef
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4) {
            console.log(xhr.status);
            console.log(xhr.responseText);
          }
          // tslint:disable-next-line:triple-equals
          if (xhr.status == 200){
            document.getElementById('succeeded_snack').click();
            document.getElementById('refresh_group').click();
          }
          else{
            document.getElementById('failed_snack').click();
          }
        };
        xhr.send(jsonToSend);
      }
      console.log('sent', jsonToSend);
      this.reviewedCompanies = [];
      this.emptyReviewedPeople();
    }
  }
  emptyReviewedPeople(): void{
    this.reviewedPeople = [{
      'source name': '',
      id: 0,
      importId: '',
      fileName: '',
      fileRowId: 0,
      personId: 0,
      prefix: '',
      firstName: 'empty',
      middleName: '',
      lastName: '',
      suffix: '',
      companyId: 0,
      title: '',
      primaryAddress1: '',
      primaryAddress2: '',
      primaryCity: '',
      primaryState: '',
      primaryZip: '',
      primaryCountry: '',
      alternateAddressSource: '',
      alternateAddressPreferred: 0,
      alternateAddressType: '',
      wordpressId: 0,
      cexSubscriberCode: '',
      cexSubStatus: '',
      cbmSubStatus: '',
      ceoDigitalAndPrint: false,
      cbmDigitalAndPrint: false,
      ceoDigitalOnly: false,
      cbmDigitalOnly: false,
      bpaQualDate: '',
      ownership: '',
      ownershipSubType: '',
      revenue: '',
      industry: '',
      otherIndustry: '',
      employees: '',
      mobilePhone: '',
      lastUpdated: '',
      email: '',
      actionType: 'None',
      winPurePrimK: 0,
      groupId: 0,
      ticker: '',
      primaryCompany: '',
      directPhone: '',
      altEmail: '',
      activeStatus: false,
      boardCompanyName: '',
      verifiedDate: '',
      memberClicksId: 0,
      qualificationSource: '',
      bpaJobTitle: '',
      memberStatus: '',
      cen: false,
      sen: false,
      ceO100: false,
      cfO100: false,
      nextLevelLeaders: false,
      cebi: false,
    }];
  }
  // tslint:disable-next-line:typedef
  Proceed() {
    if (this.selectedDataType === 'Company'){
      let ct = 0;
      for (const i of this.companiesToReview){
        if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge'){
          ct++;
        }
      }
      this.continueProceed();
    //  if ( ct !== 0){
       // const dialogRef = this.dialog.open(WarningComponent);
       // dialogRef.componentInstance.action = 'ManualMerge';
      // dialogRef.componentInstance.isBulckAnction = false;

      //   dialogRef.afterClosed().subscribe(result => {
      //     console.log(`popup result: ${result}`);
      //     const response = result;
      //     if (response === 'yes'){
      //       this.ManualMerge();
      //     }
      //     else if (response === 'no'){
      //       this.continueProceed();
      //     }
      //   });
      // }
      // else{
      //   this.continueProceed();
      // }
    } else if (this.selectedDataType === 'Person') {
      let ct = 0;
      for (const i of this.peopleToReview) {
        if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge') {
          ct++;
        }
      }
      this.continueProceed();
      // if (ct !== 0) {
       // const dialogRef = this.dialog.open(WarningComponent);
      //  dialogRef.componentInstance.action = 'ManualMerge';
      //  dialogRef.componentInstance.isBulckAnction = false;

      //   dialogRef.afterClosed().subscribe(result => {
      //     console.log(`popup result: ${result}`);
      //     const response = result;
      //     if (response === 'yes') {
      //       this.ManualMerge();
      //     } else if (response === 'no') {
      //       this.continueProceed();
      //     }
      //   });
      // } else {
      //   this.continueProceed();
      // }
    }
  }
  // tslint:disable-next-line:typedef
  refreshGroup(){
    this.setWasManualMergeAccessed(false);
    // tslint:disable-next-line:variable-name
    const group_nr: number = +this.selected_group;
    this.Group_Data(group_nr);
  }
  // tslint:disable-next-line:typedef
  ManualMerge(){
    console.log('send to popup', this.peopleToReview);
    let dialogRef: any;
    // tslint:disable-next-line:variable-name
    this._mergeservice.shared_was_manual_merge_accessed.subscribe( was_manual_merge_accessed => {
      this.was_manual_merge_accessed = was_manual_merge_accessed;
    });
    if (!this.was_manual_merge_accessed){
      switch (this.selectedDataType){
        case 'Company': {
          dialogRef = this.dialog.open(MergeOptionsComponent, {
            disableClose: true,
          });
          dialogRef.componentInstance.companiesToReview = this.companiesToReview;
          dialogRef.componentInstance.selectedDataType = this.selectedDataType;
          dialogRef.componentInstance.selected_group = this.selected_group;
          dialogRef.componentInstance.countries = this.countries;
          dialogRef.componentInstance.parent_page = 'Match';
          console.log('sent to popup grouid', this.selected_group);
          dialogRef.componentInstance.urlType = 1;
          dialogRef.afterClosed().subscribe(result => {
            document.getElementById('succeeded_snack').click();
            document.getElementById('refresh_group').click();
          });
          for (const i of this.companiesToReview){
            if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge'){
              this.mergedCompanies.push(i);
            }
          }
          break;
        }
        case 'Person': {
          dialogRef = this.dialog.open(MergeOptionsComponent, {
            disableClose: true,
          });
          dialogRef.componentInstance.peopleToReview = this.peopleToReview;
          dialogRef.componentInstance.selectedDataType = this.selectedDataType;
          dialogRef.componentInstance.selected_group = this.selected_group;
          dialogRef.componentInstance.countries = this.countries;
          dialogRef.componentInstance.parent_page = 'Match';
          console.log('sent to popup grouid', this.selected_group);
          dialogRef.componentInstance.urlType = 1;
          dialogRef.afterClosed().subscribe(result => {
            document.getElementById('succeeded_snack').click();
            document.getElementById('refresh_group').click();
          });
          for (const i of this.peopleToReview){
            if (i.actionType === 'AddToMainDatabase' || i.actionType === 'Merge'){
              this.mergedPeople.push(i);
            }
          }
          console.log('merged', JSON.stringify(this.mergedPeople));
          break;
        }
      }
    }
    else {
      this.snackbar_Error('You can only do one manual merge / group!');
    }
  }
  // tslint:disable-next-line:typedef
  construct_reviewedCompanies() {
    // reset
    this.reviewedCompanies = [];
    for (const i of this.companiesToReview) {
      const comp = new CompanyToReview();
      Object.assign(comp, i);
      switch (i.actionType) {
        case 'AddToMainDatabase': {
          comp.actionType = 'AddToMainDatabase';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'Merge': {
          comp.actionType = 'Merge';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'Change': {
          comp.actionType = 'ExcludeFromGroup';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'Delete': {
          comp.actionType = 'Delete';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'Edit': {
          comp.actionType = 'Edit';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'Kick': {
          comp.actionType = 'Kick';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'MigratePeople': {
          comp.actionType = 'MigratePeople';
          this.reviewedCompanies.push(comp);
          break;
        }
        case 'None': {
          break;
        }
      }
    }
    // this.reviewedCompanies.splice(0, 1);
    return JSON.stringify(this.reviewedCompanies);
  }

  // tslint:disable-next-line:typedef
  construct_reviewedPeople() {

    this.emptyReviewedPeople();
    for (const i of this.peopleToReview) {
      switch (i.actionType) {
        case 'AddToMainDatabase': {
          this.reviewedPeople.push({
            'source name': i['source name'],
            actionType: 'AddToMainDatabase',
            companyId: i.companyId,
            email: i.email,
            fileName: '',
            fileRowId: 0,
            firstName: i.firstName,
            groupId: i['group ID'],
            id: 0,
            importId: '',
            lastName: i.lastName,
            lastUpdated: i.lastUpdated,
            middleName: i.middleName,
            mobilePhone: i.mobilePhone,
            personId: i.personId,
            prefix: i.prefix,
            primaryAddress1: i.primaryAddress1,
            primaryAddress2: i.primaryAddress2,
            primaryCity: i.primaryCity,
            primaryCountry: i.primaryCountry,
            primaryState: i.primaryState,
            primaryZip: i.primaryZip,
            alternateAddressSource: i.alternateAddressSource,
            alternateAddressPreferred: i.alternateAddressPreferred,
            alternateAddressType: i.alternateAddressType,
            wordpressId: i.wordpressId,
            cexSubscriberCode: i.cexSubscriberCode,
            cexSubStatus: i.cexSubStatus,
            cbmSubStatus: i.cbmSubStatus,
            cbmDigitalAndPrint: i.cbmDigitalAndPrint,
            ceoDigitalAndPrint: i.ceoDigitalAndPrint,
            ceoDigitalOnly: i.ceoDigitalOnly,
            cbmDigitalOnly: i.cbmDigitalOnly,
            bpaQualDate: i.bpaQualDate,
            industry: i.industry,
            otherIndustry: i.otherIndustry,
            employees: i.employees,
            ownership: i.ownership,
            ownershipSubType: i.ownershipSubType,
            revenue: i.revenue,
            suffix: i.suffix,
            title: i.title,
            winPurePrimK: i.winPurePrimK,
            ticker: i.ticker,
            primaryCompany: i.primaryCompany,
            directPhone: i.directPhone,
            altEmail: i.altEmail,
            activeStatus: i.activeStatus,
            boardCompanyName: i.boardCompanyName,
            verifiedDate: i.verifiedDate,
            memberClicksId: i.memberClicksId,
            qualificationSource: i.qualificationSource,
            bpaJobTitle: i.bpaJobTitle,
            memberStatus: i.memberStatus,
            cen: i.cen,
            sen: i.sen,
            cebi: i.cebi,
            cfO100: i.cfO100,
            nextLevelLeaders: i.nextLevelLeaders,
            ceO100: i.ceO100
          });
          break;
        }
        case 'Merge':
        case 'Delete':
        case 'Kick':
        case 'Edit': {
          this.reviewedPeople.push({
            'source name': i['source name'],
            actionType: i.actionType,
            companyId: i.companyId,
            email: i.email,
            fileName: '',
            fileRowId: 0,
            firstName: i.firstName,
            groupId: i['group ID'],
            id: 0,
            importId: '',
            lastName: i.lastName,
            lastUpdated: i.lastUpdated,
            middleName: i.middleName,
            mobilePhone: i.mobilePhone,
            personId: i.personId,
            prefix: i.prefix,
            primaryAddress1: i.primaryAddress1,
            primaryAddress2: i.primaryAddress2,
            primaryCity: i.primaryCity,
            primaryCountry: i.primaryCountry,
            primaryState: i.primaryState,
            primaryZip: i.primaryZip,
            alternateAddressSource: i.alternateAddressSource,
            alternateAddressPreferred: i.alternateAddressPreferred,
            alternateAddressType: i.alternateAddressType,
            wordpressId: i.wordpressId,
            cexSubscriberCode: i.cexSubscriberCode,
            cexSubStatus: i.cexSubStatus,
            cbmSubStatus: i.cbmSubStatus,
            cbmDigitalAndPrint: i.cbmDigitalAndPrint,
            ceoDigitalAndPrint: i.ceoDigitalAndPrint,
            ceoDigitalOnly: i.ceoDigitalOnly,
            cbmDigitalOnly: i.cbmDigitalOnly,
            bpaQualDate: i.bpaQualDate,
            industry: i.industry,
            otherIndustry: i.otherIndustry,
            employees: i.employees,
            ownership: i.ownership,
            ownershipSubType: i.ownershipSubType,
            revenue: i.revenue,
            suffix: i.suffix,
            title: i.title,
            winPurePrimK: i.winPurePrimK,
            ticker: i.ticker,
            primaryCompany: i.primaryCompany,
            directPhone: i.directPhone,
            altEmail: i.altEmail,
            activeStatus: i.activeStatus,
            boardCompanyName: i.boardCompanyName,
            verifiedDate: i.verifiedDate,
            memberClicksId: i.memberClicksId,
            qualificationSource: i.qualificationSource,
            bpaJobTitle: i.bpaJobTitle,
            memberStatus: i.memberStatus,
            cen: i.cen,
            sen: i.sen,
            cebi: i.cebi,
            cfO100: i.cfO100,
            nextLevelLeaders: i.nextLevelLeaders,
            ceO100: i.ceO100
          });
          break;
        }
        case 'Change': {
          this.reviewedPeople.push({
            'source name': i['source name'],
            actionType: 'ExcludeFromGroup',
            companyId: i.companyId,
            email: i.email,
            fileName: '',
            fileRowId: 0,
            firstName: i.firstName,
            groupId: i['group ID'],
            id: 0,
            importId: '',
            lastName: i.lastName,
            lastUpdated: i.lastUpdated,
            middleName: i.middleName,
            mobilePhone: i.mobilePhone,
            personId: i.personId,
            prefix: i.prefix,
            primaryAddress1: i.primaryAddress1,
            primaryAddress2: i.primaryAddress2,
            primaryCity: i.primaryCity,
            primaryCountry: i.primaryCountry,
            primaryState: i.primaryState,
            primaryZip: i.primaryZip,
            alternateAddressSource: i.alternateAddressSource,
            alternateAddressPreferred: i.alternateAddressPreferred,
            alternateAddressType: i.alternateAddressType,
            wordpressId: i.wordpressId,
            cexSubscriberCode: i.cexSubscriberCode,
            cexSubStatus: i.cexSubStatus,
            cbmSubStatus: i.cbmSubStatus,
            cbmDigitalAndPrint: i.cbmDigitalAndPrint,
            ceoDigitalAndPrint: i.ceoDigitalAndPrint,
            ceoDigitalOnly: i.ceoDigitalOnly,
            cbmDigitalOnly: i.cbmDigitalOnly,
            bpaQualDate: i.bpaQualDate,
            industry: i.industry,
            otherIndustry: i.otherIndustry,
            employees: i.employees,
            ownership: i.ownership,
            ownershipSubType: i.ownershipSubType,
            revenue: i.revenue,
            suffix: i.suffix,
            title: i.title,
            winPurePrimK: i.winPurePrimK,
            ticker: i.ticker,
            primaryCompany: i.primaryCompany,
            directPhone: i.directPhone,
            altEmail: i.altEmail,
            activeStatus: i.activeStatus,
            boardCompanyName: i.boardCompanyName,
            verifiedDate: i.verifiedDate,
            memberClicksId: i.memberClicksId,
            qualificationSource: i.qualificationSource,
            bpaJobTitle: i.bpaJobTitle,
            memberStatus: i.memberStatus,
            cen: i.cen,
            sen: i.sen,
            cebi: i.cebi,
            cfO100: i.cfO100,
            nextLevelLeaders: i.nextLevelLeaders,
            ceO100: i.ceO100
          });
          break;
        }
        case 'None': {
          break;
        }
      }
    }
    this.reviewedPeople.splice(0, 1);
    return JSON.stringify(this.reviewedPeople);
  }

  // tslint:disable-next-line:typedef
  LinkTo(id: number) {
    // tslint:disable-next-line:max-line-length
    if (window.location.href === 'http://localhost:4200/#/matching' || window.location.href === 'https://amplify-dev.ceo360.pro/#/matching'){
      this.href = 'https://dev.ceo360.pro/#/';
    }
    if (window.location.href === 'https://amplify.ceo360.pro/#/matching'){
      this.href = 'https://ceo360.pro/#/';
    }
    if (this.selectedDataType === 'Company'){
      this.href += 'Company/' + id;
      console.log(this.href);
      const win = window.open(this.href, '_blank');
      win.focus();
    }
    else if (this.selectedDataType === 'Person'){
      this.href += 'Person/' + id;
      console.log(this.href);
      const win = window.open(this.href, '_blank');
      win.focus();
    }
  }

  // tslint:disable-next-line:typedef
  Input_group_Data(group: string){
    // tslint:disable-next-line:variable-name radix
    let group_nr = parseInt(group);
    if (group_nr < 0){
      group_nr = 1;
    }
    else if (group_nr > this.group_list_length){
      group_nr = this.group_list_length;
    }
    this.Group_Data(group_nr);
  }
  // tslint:disable-next-line:variable-name
  ColorSelectedGroup(group_id: number): void{
    // tslint:disable-next-line:variable-name
    const selected_datatype = this.selectedDataType.toLowerCase();
    if (!this.is_long_list){
      document.getElementById(selected_datatype + '-group-1').style.backgroundColor = '#222222';
      document.getElementById(selected_datatype + '-group-1').style.color = 'white';
    }
    else{
      for (const i of this.group_list){
        // tslint:disable-next-line:triple-equals
        if (i == group_id){
          document.getElementById(selected_datatype + '-group-' + i.toString()).style.backgroundColor = '#222222';
          document.getElementById(selected_datatype + '-group-' + i.toString()).style.color = 'white';
          // tslint:disable-next-line:only-arrow-functions typedef
          document.getElementById(selected_datatype + '-group-' + i.toString()).addEventListener('mouseover', function() {
            document.getElementById(selected_datatype + '-group-' + i.toString()).style.backgroundColor = '#222222';
            document.getElementById(selected_datatype + '-group-' + i.toString()).style.color = 'white';
          });
          // tslint:disable-next-line:only-arrow-functions typedef
          document.getElementById(selected_datatype + '-group-' + i.toString()).addEventListener('mouseout', function() {
            document.getElementById(selected_datatype + '-group-' + i.toString()).style.backgroundColor = '#222222';
            document.getElementById(selected_datatype + '-group-' + i.toString()).style.color = 'white';
          });
        }
        else{
          document.getElementById(selected_datatype + '-group-' + i.toString()).style.backgroundColor = 'white';
          document.getElementById(selected_datatype + '-group-' + i.toString()).style.color = 'black';
          // tslint:disable-next-line:only-arrow-functions typedef
          document.getElementById(selected_datatype + '-group-' + i.toString()).addEventListener('mouseover', function() {
            document.getElementById(selected_datatype + '-group-' + i.toString()).style.backgroundColor = '#222222';
            document.getElementById(selected_datatype + '-group-' + i.toString()).style.color = 'white';
          });
          // tslint:disable-next-line:only-arrow-functions typedef
          document.getElementById(selected_datatype + '-group-' + i.toString()).addEventListener('mouseout', function() {
            document.getElementById(selected_datatype + '-group-' + i.toString()).style.backgroundColor = 'white';
            document.getElementById(selected_datatype + '-group-' + i.toString()).style.color = 'black';
          });
        }
      }
    }
  }
// tslint:disable-next-line:typedef variable-name
  Group_Data(group_id: number) {
    this.resetAllData();
    this.setWasManualMergeAccessed(false);
    this.ColorSelectedGroup(group_id);
    this.is_totalScore_clicked = false;
    this.selected_group = group_id.toString();
    this.showOverlay = true;
    this.api.get_ReviewGroupData(this.selectedDataType, this.selected_group).subscribe(res => {
          if (this.is_company_search) {
            // @ts-ignore
            this.companiesToReview = res;
            this.companiesToReview.sort((a, b) => {
              if (a.companyName){
                if (a.companyName > b.companyName){
                  return 1;
                }
                if (a.companyName < b.companyName){
                  return -1;
                }
                return 0;
              }
            });
            this.total_searches = this.companiesToReview.length;
            this.is_search_ready = true;
            this.dataSource = new MatTableDataSource<CompanyToReview>(this.companiesToReview);
            this.dataSource.paginator = this.paginator;
            this.showOverlay = false;
            for (const i of this.companiesToReview) {
              i.actionType = 'None';
              if (!i.activeStatus){
               // i.activeStatus = undefined;
              }
            }
          }
          if (this.is_person_search) {
            // @ts-ignore
            this.peopleToReview = res;
            this.peopleToReview.sort((a, b) => {
              if (a.lastName){
                if (a.lastName > b.lastName){
                  return 1;
                }
                if (a.lastName < b.lastName){
                  return -1;
                }
                return 0;
              }
              // @ts-ignore
              document.getElementById('all-person-select-form').selectedIndex = '0';
            });
            this.total_searches = this.peopleToReview.length;
            this.is_search_ready = true;
            this.dataSource = new MatTableDataSource<PersonToReview>(this.peopleToReview);
            this.dataSource.paginator = this.paginator;
            this.showOverlay = false;
            for (const i of this.peopleToReview) {
              i.actionType = 'None';
              if (!i.activeStatus){
               // i.activeStatus = undefined;
              }
            }
          }
        },
        err => {
          console.log(err);
          this.showOverlay = false;
        });
  }
  resetSelectedTab(): void{
    document.getElementById('hidden-company').style.backgroundColor = '#005a87';
    document.getElementById('hidden-company').style.color = 'white';
    document.getElementById('hidden-person').style.backgroundColor = '#005a87';
    document.getElementById('hidden-person').style.color = 'white';
  }
  // tslint:disable-next-line:typedef
  selected_datatype(event: string) {
    this.resetAllData();
    this.setWasManualMergeAccessed(false);
    this.dataSource = [];
    this.is_totalScore_clicked = false;
    this.selected_group = '';
    this.selectedDataType = event; // .target.value;
    this.showOverlay = true;
    this.resetSelectedTab();
    if (this.selectedDataType === 'Company') {
      document.getElementById('hidden-company').style.backgroundColor = '#222222';
      document.getElementById('hidden-company').style.color = 'white';
      this.is_company_search = true;
      this.displayedColumns = [ 'Action', 'CompanyId', 'SourceName', 'CompanyName', 'Adress1', 'Adress2', 'City', 'State',
        'Zip', 'Country', 'Phone', 'Website', 'CIK ID', 'Ticker', 'Annual Revenue', 'Verified Date', 'Active Status', 'AddressSource'];
    } else if (this.selectedDataType === 'Person') {
      this.resetSelectedTab();
      document.getElementById('hidden-person').style.backgroundColor = '#222222';
      document.getElementById('hidden-person').style.color = 'white';
     // tslint:disable-next-line:max-line-length
      this.displayedColumns = [ 'Action', 'Person Id', 'SourceName', 'First Name', 'Middle Name', 'Last Name', 'Suffix', 'Title', 'Email', 'AltEmail', 'Direct Phone',
        'Mobile Phone', 'Primary Company', 'Board Company Associations', 'Ticker',  'Shipping Add1', 'Shipping Add2', 'Shipping City', 'Shipping State', 'Shipping Zipcode', 'Shipping Country', 'Alternate Address Source', 'Alternate Address Preferred', 'Alternate Address Type', 'Verified Date', 'Active Status'];
    }
    this.is_datatype_selected = true;
    this.is_data_available = false;
    console.log(this.selectedDataType);
    this.SearchType();
    this.selected();
  }

  // tslint:disable-next-line:typedef
  resetAllData() {
    this.bulkaction = 'None';
    this.are_actionButtons_visible = 0;
    this.merge_rule = 'UpdateEmptyField';
    this.companiesToReview = [];
    this.reviewedCompanies = [];
    this.peopleToReview = [{
      'group ID': 0,
      'source name': 'empty',
      'total score': 0,
      'group 1 score': 0,
      'group 1 FirstName score': 0,
      'group 1 LastName score': 0,
      personId: 0,
      prefix: '',
      firstName: '',
      middleName: '',
      lastName: '',
      companyId: 0,
      title: '',
      primaryAddress1: '',
      primaryAddress2: '',
      primaryCity: '',
      primaryState: '',
      primaryZip: '',
      primaryCountry: '',
      alternateAddressSource: '',
      alternateAddressPreferred: 0,
      alternateAddressType: '',
      wordpressId: 0,
      cexSubscriberCode: '',
      cexSubStatus: '',
      cbmSubStatus: '',
      ceoDigitalAndPrint: false,
      cbmDigitalAndPrint: false,
      ceoDigitalOnly: false,
      cbmDigitalOnly: false,
      bpaQualDate: '',
      ownership: '',
      ownershipSubType: '',
      revenue: '',
      industry: '',
      otherIndustry: '',
      employees: '',
      lastUpdated: '',
      email: '',
      actionType: 'None',
      winPurePrimK: 0,
      ticker: '',
      primaryCompany: '',
      suffix: '',
      directPhone: '',
      altEmail: '',
      activeStatus: false,
      mobilePhone: '',
      boardCompanyName: '',
      verifiedDate: '',
      importId: '',
      fileName: '' ,
      id: 0,
      fileRowId: 0,
      groupId: 0,
      memberClicksId: 0,
      qualificationSource: '',
      bpaJobTitle: '',
      memberStatus: '',
      cen: false,
      sen: false,
      ceO100: false,
      cfO100: false,
      nextLevelLeaders: false,
      cebi: false,
    }];
    this.emptyReviewedPeople();
  }

  // tslint:disable-next-line:typedef
  selected() {
    // tslint:disable-next-line:variable-name
    this.api.get_ReviewStatistics(this.selectedDataType).subscribe(field_result => {
          // @ts-ignore
          this.statistics = field_result;
          if (field_result) {
            this.is_data_available = true;
          }
          console.log(this.statistics);
          this.matchGroups = this.statistics.totalMatchGroups;
          this.notMatched = this.statistics.totalNotMatchedRecords;
          this.totalRecords = this.statistics.totalRecords;
          this.group_list_elements = this.statistics.listOfGroupsIds;
          this.showOverlay = false;
          this.GroupList(this.matchGroups);
        },
        err => {
          console.log(err);
          this.showOverlay = false;
        });
  }

  // tslint:disable-next-line:typedef variable-name
  GroupList(group_count: string) {
    this.group_list = this.group_list_elements;
    this.group_list_length = this.group_list.length;
  }

  // tslint:disable-next-line:typedef
  SearchType() {
    if (this.selectedDataType === 'Company') {
      this.is_company_search = true;
      this.is_person_search = false;
      this.group_list = [];
    }
    if (this.selectedDataType === 'Person') {
      this.is_company_search = false;
      this.is_person_search = true;
      this.group_list = [];
    }
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }

// tslint:disable-next-line:typedef
  unsetMergedConnection(){
    this._mergeservice.update_alreadyMerged(0);
  }

  // tslint:disable-next-line:typedef
  setWasManualMergeAccessed(state: boolean){
    this._mergeservice.update_manualMergeAccessed(state);
  }
  // tslint:disable-next-line:typedef
  showRunningJobs(){
    this.GetTimeStamp();
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
          this.are_jobsVisible = !(this.are_jobsVisible);
          if (this.are_jobsVisible){
            const img = document.getElementById('review-running-jobs');
            img.style.transform = 'rotate(180deg)';
          }
          else{
            const img = document.getElementById('review-running-jobs');
            img.style.transform = 'rotate(0deg)';
          }
        },
        err => {
          console.log(err);
        });
  }
  deleteJob(jobKey: any): void{
    const dialogRef = this.dialog.open(WarningComponent);
    dialogRef.componentInstance.action = 'DeleteJob';
    dialogRef.componentInstance.isBulckAnction = false;

    dialogRef.afterClosed().subscribe(result => {
      console.log(`popup result: ${result}`);
      const response = result;
      if (response === 'yes'){
        this.ProceedDeleteJob(jobKey);
      }
    });
  }
  ProceedDeleteJob(jobKey: any): void{
    const url = this.api.delete_Job(jobKey.toString());

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.token}`);
    xhr.setRequestHeader('Content-Type', 'application/json-patch+json');
    xhr.setRequestHeader('accept', '*/*');

    // tslint:disable-next-line:only-arrow-functions typedef
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        // tslint:disable-next-line:triple-equals
        if (xhr.status == 200){
          console.log('ok');
          document.getElementById('succeeded_snack').click();
          document.getElementById('refreshJobs').click();
        }
        else{
          document.getElementById('failed_snack').click();
        }
      }
    };
    xhr.send();
  }
  refreshJobs(): void{
    this.api.get_Jobs().subscribe(res => {
          // @ts-ignore
          this.jobs = res;
          for (const i of this.jobs){
            if (i.type === 'CegIntegration.Web.Jobs.MainDataSynchronizationJob'){
              this.jobs.splice(this.jobs.indexOf(i), 1);
            }
          }
        },
        err => {
          console.log(err);
        });
  }
  ApplyActionToAll(datatype: string, event: any): void{
    this.bulkaction = event;
    this.are_actionButtons_visible++;
    if (datatype === 'company'){
      for (const i of this.companiesToReview){
        i.actionType = event;
      }
    }
    else if (datatype === 'person'){
      for (const i of this.peopleToReview){
        i.actionType = event;
      }
    }
  }
  ngOnInit(): void {
    this.api.getAuthToken().subscribe(auth => {
      this.token = auth;
    });
    this.auth.user$.subscribe(
        (profile) => {
          (this.profileJson = JSON.parse(JSON.stringify(profile, null, 2)));
          localStorage.setItem('userRole', profile['https://schemas.quickstarts.com/roles'][0]);
          this.userRole = profile['https://schemas.quickstarts.com/roles'][0];
          console.log('user role', this.userRole);
          if (this.userRole === 'admin'){
            this.isAdmin = true;
            console.log('is admin');
          }
          else{
            this.isAdmin = false;
            console.log('is not admin');
          }
        });
    this.api.getSourceData_SimpleSearch().subscribe(res => {
          // @ts-ignore
          this.datatypes = res;
        },
        err => {
          console.log(err);
        });
    this.api.get_Countries().subscribe(res => {
          // @ts-ignore
          this.countries = res;
          const index = this.countries.findIndex(({countryName}) => countryName === 'United States');
          this.countries.unshift(this.us_country);
          this.countries.splice(index, 1);
        },
        err => {
          console.log(err);
        });
    this.api.get_States().subscribe(res => {
          // @ts-ignore
          this.states = res;
        },
        err => {
          console.log(err);
        });
  }

}


