// @ts-ignore
import {domain, clientId, audience, serverUrl} from '../app/auth_config.json';

export const environment = {
  production: false,
  auth: {
    domain,
    clientId,
    redirectUri: window.location.origin,
    audience,
  },
  dev: {
    serverUrl,
  },
  environment: 'Production'
};
